import React, {useState, useEffect} from "react";
import {DatePicker, Button } from 'antd';
import type { RadioChangeEvent } from 'antd';
import { Radio } from 'antd';
import dayjs from 'dayjs';
import type { SelectProps } from 'antd';
type ReportType = {
    totalRegistered: number,
    totalRegisteredInterns: number,
    totalRegisteredEmployees:number,
    totalRegisteredPass:number,
    totalRegisteredPassIntern:number
}
export const Report = () => {
    const [options, setOptions] = useState<{value:string, label:string, content: ReportType}[]>([]);
    const [optionsUniversity, setOptionsUniversity] = useState<{value:string, label:string, content: ReportType}[]>([]);
    const getAllUniversities = async () => {
        const token = localStorage.getItem('token');
        const rawResponse = await fetch(process.env.REACT_APP_API_URL+'api/University', {
                method: 'GET',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`,
                },
        }).then(response => response.json())
        .then((data)=>{
          console.log(data);
          setOptionsUniversity(data.map((item:any)=>{
                return {value: item.matruong, label: item.tentruong}
              }))
              console.log(optionsUniversity);
        })
        .catch(err => console.log(err));
      }
      const getAllSubjects = async () => {
        const token = localStorage.getItem('token');
        const rawResponse = await fetch(process.env.REACT_APP_API_URL+'api/Subject', {
                method: 'GET',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`,
                },
        }).then(response => response.json())
        .then((data)=>{
              setOptions(data.map((item:any)=>{
                return {value: item.id, label: item.tenmonhoc}
              }))
              console.log(options);
        })
        .catch(err => console.log(err));
      }
    const [value, setValue] = useState(1);
    const [startDate, setStartDate] = useState(new Date());
    const [endDate, setEndDate] = useState(new Date(new Date().getTime()+90*24*60*60*1000));
    const [myReport, setMyReport] = useState<ReportType>();
    const getReport = async () => {
        const token = localStorage.getItem('token');
        let myStartMonth = (startDate.getMonth()+1)<10?"0"+(startDate.getMonth()+1):startDate.getMonth()+1;
        let myStartDate = startDate.getDate()<10?"0"+startDate.getDate():startDate.getDate();
        let myEndMonth = (endDate.getMonth()+1)<10?"0"+(endDate.getMonth()+1):endDate.getMonth()+1;
        let myEndDate = endDate.getDate()<10?"0"+endDate.getDate():endDate.getDate();
        let mystartDate = startDate.getFullYear() + '-' + myStartMonth + '-' + myStartDate;
        let myendDate = endDate.getFullYear() + '-' + myEndMonth +'-'+ myEndDate;
            const rawResponse = await fetch(process.env.REACT_APP_API_URL+'api/Reporting/' + mystartDate + "/" + myendDate, {
                method: 'GET',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`,
                },
        }).then(response => response.json())
        .then((data)=>{
            setMyReport(data);
        })
        .catch(err => console.log(err));
    }   
    const getReportByOptions = async (option: number) => {
        const token = localStorage.getItem('token');
        let myStartMonth = (startDate.getMonth()+1)<10?"0"+(startDate.getMonth()+1):startDate.getMonth()+1;
        let myStartDate = startDate.getDate()<10?"0"+startDate.getDate():startDate.getDate();
        let myEndMonth = (endDate.getMonth()+1)<10?"0"+(endDate.getMonth()+1):endDate.getMonth()+1;
        let myEndDate = endDate.getDate()<10?"0"+endDate.getDate():endDate.getDate();
        let mystartDate = startDate.getFullYear() + '-' + myStartMonth + '-' + myStartDate;
        let myendDate = endDate.getFullYear() + '-' + myEndMonth +'-'+ myEndDate;
        if(option == 1)
        {
            const rawResponse = await fetch(process.env.REACT_APP_API_URL+'api/Reporting/university/' + mystartDate + "/" + myendDate, {
                method: 'GET',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`,
                },
        }).then(response => response.json())
        .then((data)=>{
            setOptionsUniversity(data.map((item:any)=>{
                return {value: item.university.matruong, label: item.university.tentruong,
                    content: item.register
                }
              }))
        })
        .catch(err => console.log(err));
        }
        else{
            const rawResponse = await fetch(process.env.REACT_APP_API_URL+'api/Reporting/specialize/' + mystartDate + "/" + myendDate, {
                method: 'GET',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`,
                },
        }).then(response => response.json())
        .then((data)=>{
            console.log(data);
            setOptions(data.map((item:any)=>{
                return {value: item.subject.id, label: item.subject.tenmonhoc,
                    content: item.register
                }
              }))
        })
        .catch(err => console.log(err));
        }
    }
    
    useEffect(()=>{
        getReport();
        getReportByOptions(value);
    },[])
    const onChange = (e: RadioChangeEvent) => {
        console.log('radio checked', e.target.value);
        setValue(e.target.value);
      };
    return(
        <>
        Từ ngày: &nbsp;
      <DatePicker defaultValue={dayjs(startDate)}
        onChange={(date, dateString)=>{
          setStartDate(new Date(dateString.toString()));
        }} />
        Đến ngày: &nbsp;
      <DatePicker defaultValue={dayjs(endDate)}
        onChange={(date, dateString)=>{
          setEndDate(new Date(dateString.toString()));
        }} />&nbsp;
    <Radio.Group onChange={onChange} value={value}>
      <Radio value={1}>Báo cáo theo trường</Radio>
      <Radio value={2}>Báo cáo the lĩnh vực chuyên môn</Radio>
    </Radio.Group>
        &nbsp;
        <Button type="primary" onClick={()=>{
            getReport();
            getReportByOptions(value);
        }}>Kết quả</Button>
        <hr />
        <table border={2} style={{marginTop:'10px'}}>
            <tr>
                <td>Chỉ số</td>
                <td>Kết quả</td>
            </tr>
            <tr>    
                <td>Tổng số người đăng ký</td>
                <td style={{textAlign:'right'}}>{myReport?.totalRegistered}</td>
            </tr>
            <tr>
                <td>Tổng số người đăng ký thực tập</td>
                <td style={{textAlign:'right'}}>{myReport?.totalRegisteredInterns}</td>
            </tr>
            
            <tr>
                <td>Tổng số người đăng ký chính thức</td>
                <td style={{textAlign:'right'}}>{myReport?.totalRegisteredEmployees}</td>
            </tr>
            <tr>
                <td>Tổng số người được nhận thực tập</td>
                <td style={{textAlign:'right'}}>{myReport?.totalRegisteredPassIntern}</td>
            </tr>
            <tr>
                <td>Tổng số người được nhận chính thức</td>
                <td style={{textAlign:'right'}}>{myReport?.totalRegisteredPass}</td>
            </tr>
        </table>
        {value==1?
        <table border={2} style={{marginTop:'10px', width:'100%'}}>
            <tr>
                <td>Trường / Cơ sở đào tạo</td>
                <td>Tổng số người đăng ký</td>
                <td>Tổng số người đăng ký thực tập</td>
                <td>Tổng số người đăng ký chính thức</td>
                <td>Tổng số người được nhận thực tập</td>
                <td>Tổng số người được nhận chính thức</td>
            </tr>
            {optionsUniversity?.map((item:any)=>{
                return(
                    <tr>
                        <td>{item.label}</td>
                        <td>{item.content.totalRegistered}</td>
                        <td>{item.content.totalRegisteredInterns}</td>
                        <td>{item.content.totalRegisteredEmployees}</td>
                        <td>{item.content.totalRegisteredPassIntern}</td>
                        <td>{item.content.totalRegisteredPass}</td>
                    </tr>
                )
            })}
        </table>
        :
        <table border={2} style={{marginTop:'10px', width:'100%'}}>
            <tr>
                <td>Lĩnh vực chuyên môn</td>
                <td>Tổng số người đăng ký</td>
                <td>Tổng số người đăng ký thực tập</td>
                <td>Tổng số người đăng ký chính thức</td>
                <td>Tổng số người được nhận thực tập</td>
                <td>Tổng số người được nhận chính thức</td>
            </tr>
            {options?.map((item:any)=>{
                return(
                    <tr>
                        <td>{item.label}</td>
                        <td>{item.content.totalRegistered}</td>
                        <td>{item.content.totalRegisteredInterns}</td>
                        <td>{item.content.totalRegisteredEmployees}</td>
                        <td>{item.content.totalRegisteredPassIntern}</td>
                        <td>{item.content.totalRegisteredPass}</td>
                    </tr>
                )
            })}
        </table>}
        </>
    )
}