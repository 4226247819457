import React, {useState, useEffect} from 'react';
import { Button } from 'antd';
import { useSelector } from 'react-redux';
import { RootState } from '../../redux/store';
import { ClockCircleOutlined } from '@ant-design/icons';
import { AnswerRequest, Subject } from '../../interface';
import { useAppDispatch } from '../../configs/hook';
import { getDapan } from '../thitracnghiem/tracnghiemSlice';
import './styles.css';
type TestHeaderProp = {
  subjectForTest: string,
  numberOfQuestions: number,
  course: Subject,
  hours: number,
  minutes:number,
  seconds: number,
  handleChangeQuestion: (dapan:any[]) => void
}
const TestHeader = (props:TestHeaderProp) => {
  const myAnswers = useSelector((state:RootState)=>state.answer);
  const [questionIndex, setQuestionIndex] = useState(1);
  const [numberOfResponse, setNumberOfResponse] = useState(0);
  const [dapan, setDapan] = useState<any[]>([]);
  const [isFinished, setIsFinished] = useState(false);
  const dispatch = useAppDispatch();
  const [testResult, setTestResult] = useState('');
  const myQuestions = useSelector((state:RootState)=>state.tracnghiem);
  const myDapan = useSelector((state:RootState)=>state.mydapan);
  const [hours, setHours] = useState(props.hours);
  const [minutes, setMinutes] = useState(props.minutes);
  const [seconds, setSeconds] = useState(props.seconds);
  const [testTime, setTestTime] = useState('');
  const handleClick = async (studentCode:string) => {
    const mySubmit: AnswerRequest = {
      malophocphan: props.course.malophocphan,
      masosinhvien: studentCode,
      madethi: myQuestions.madethi,
      tenmonhoc: myQuestions.tenmonhoc,
      socauhoi:myAnswers.length,
      answers: myAnswers
    }
    const token = localStorage.getItem('token');
    const rawResponse = await fetch(process.env.REACT_APP_API_URL+'api/Dethi', {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`,
      },
      body: JSON.stringify(mySubmit)
    });
    if(rawResponse.status==401){
      localStorage.clear();
      window.location.reload();
    }
    const content = await rawResponse.json();
    setTestResult(content.message);
    // Lấy đáp án đề thi
    await dispatch(getDapan(myQuestions));
    //*****/
    //printResultToPDF();
    setIsFinished(true);
  }
  useEffect(()=>{
    setDapan(myDapan);
    props.handleChangeQuestion(myDapan);
}, [myDapan]);
  useEffect(()=>{
      let countResponse = myAnswers.filter(x=>!x.shortAnswer && x.luachons.length>0).length + 
      myAnswers.filter(x=>x.shortAnswer && x.luachons[0].phatbieu !== '').length;
      setNumberOfResponse(countResponse);
  },[myAnswers])
  useEffect(()=>{
    if(isFinished==false){
      setTimeout(()=>{
        if(seconds > 0)
          setSeconds(seconds => seconds -1);
        else
        if(minutes > 0){
          setMinutes(minutues=>minutues - 1);
          setSeconds(59);
        }
        else
        if(hours > 0){
          setHours(hours=>hours - 1);
          setMinutes(60)
        }
        else
        {
          let userName = localStorage.getItem("userNameData");
          handleClick(userName!=null?userName:'')
        }
        setTestTime((hours<10?'0'+hours:hours)+':' +(minutes<10?'0'+minutes:minutes)+':' + (seconds<10?'0'+seconds:seconds))
      }, 1000);
  }},[seconds])
  return (
    <div className="exam-container">
      <div className="exam-header">
        Đề thi môn
      </div>
      <div className="exam-subheader">
        {props.subjectForTest}
      </div>
      <div className="exam-details">
        <div className="detail-item">
          <span>Tổng câu hỏi: </span><span style={{color:'#ff7e1a'}}>{props.numberOfQuestions}</span>
        </div>
        <div className="detail-item">
          <span>Hoàn thành: </span><span style={{color:'#ff7e1a'}}>{numberOfResponse}/{props.numberOfQuestions}</span>
        </div>
        <div className="detail-item" style={{color:'#ff7e1a'}}>
          <ClockCircleOutlined />
          <span>{testTime}</span>
        </div>
        {!isFinished?
        <Button type="primary" className="submit-button"
        onClick={()=>{
          let userName = localStorage.getItem("userNameData");
          handleClick(userName!=null?userName:'');
        }}
        >
          Nộp bài
        </Button>:null}
        {testResult}
      </div>
    </div>
  );
};

export default TestHeader;
