import React, {useState} from 'react';
import axios from 'axios';
import { Button, Select, Form, Input, message, SelectProps } from 'antd';
const ResetPassLayout = () => {
    const [options, setOptions] = useState<SelectProps['options']>(
        [{value: '1', label:'Sinh viên'},
        {value: '2', label:'Giảng viên'},
        {value: '3', label:'Quản trị'},
        {value: '4', label:'Khách'},
        {value: '5', label:'Doanh nghiệp'}
        ]
      );
const onFinish = (values: any) => {
  const formData = new FormData();  
  formData.append("Role", values.Role);
  formData.append("Email", values.email);
  formData.append("TelephoneNumber", values.telephoneNumber);
  const config = {     
    headers: { "Content-Type": "multipart/form-data"}
  }
  axios.post(process.env.REACT_APP_API_URL+'api/User/resetpass', formData, config)
.then((response)=>{
  if(response.data=="Reseted")
      message.info("Mật khẩu mới của bạn đã được gởi qua mail", 5);
  else
      message.info("Bạn đã nhập sai thông tin");
})
.catch(err => console.log(err));
};
  return(
    <>
    <div className='RootContainerRegister'>
      <div className='RegisterHeader'>
          <div className='TopLeftContainerRegister'>
                <img src='AltaGroup.svg' style={{width:'52px', height:'52px'}} />
                <img src='Plastic.svg' style={{width:'91px', height:'52px', marginLeft:'5px'}} />
                <img src='Media.svg' style={{width:'66px', height:'52px', marginLeft:'5px'}} />
                <img src='Software.svg' style={{width:'52px', height:'52px', marginLeft:'5px'}} />
                <img src='Unigon.svg' style={{width:'52px', height:'52px', marginLeft:'5px'}} />
          </div>
      </div>
      <div className='ContentArea' style={{paddingTop: '50px'}}>
      <Form style={{position:'absolute', top: '220px',
    left: '315px'}}
    layout={'vertical'}
      name="basic"
      initialValues={{ remember: true }}
      onFinish={onFinish}
      autoComplete="off"
    >
    <Form.Item name="Role"
      label='Vai trò:' style={{fontSize:'18px', width:'500px', fontWeight:700}}
      rules={[{required:true, message:'Phải chọn vai trò'}]}
      >
        <Select className='MyAntdDropdown'
        options={options}
        />
      </Form.Item>
      <Form.Item
        label="Thông tin email:"
        name="email"
        style={{fontWeight:600, fontFamily: 'Roboto',
    fontSize: '20px'}}
        rules={[{ required: true, message: 'Please input your email!' }]}
      >
        <Input className='LoginTopInside1Input' style={{width:'500px'}} />
      </Form.Item>
      <Form.Item
        label="Thông tin số điện thoại đăng ký:"
        name="telephoneNumber"
        style={{fontWeight:600, fontFamily: 'Roboto',
    fontSize: '20px'}}
        rules={[{ required: true, message: 'Please input your telephone number!' }]}
      >
        <Input className='LoginTopInside1Input' style={{width:'500px'}} />
      </Form.Item>
      <Form.Item 
        style={{color: 'red'}}
        wrapperCol={{ offset: 8, span: 6 }}     
        >
        </Form.Item>
      <Form.Item>
      <Button style={{ backgroundColor: 'transparent', border: 'none', paddingLeft:'0px', paddingTop:'0px' }} 
          htmlType="submit"><img src='Register.png' /></Button>
      </Form.Item>
    </Form>
      </div>
      <div className='FooterRegister'>
          @Copyright Alta Software - Training Team 04/2023
      </div>
    </div>
    </>
  );
}
export default ResetPassLayout;