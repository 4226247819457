import React, { useEffect, useState, ChangeEvent } from 'react';
import { Space, Table, Tag, Button, Modal, Form, Input, DatePicker, message } from 'antd';
import type { ColumnsType } from 'antd/es/table';
import { useAppDispatch } from '../../configs/hook';
import { Excel } from "antd-table-saveas-excel";
import FormItem from 'antd/es/form/FormItem';
import axios from 'axios';
import {getDateStringWithTime} from '../../helpers';
import { gettingData } from '../../helpers/gettingData';
const AltaEvent: React.FC = () => {
    const dispatch = useAppDispatch();
    const token = localStorage.getItem('token');
    const [deleteItem, setDeleteItem] = useState(0); 
    const columns = [
        {
            title:'Id',
            dataIndex:'id',
            key:'id',
            hidden:true
        },
        {
          title: 'Tên sự kiện',
          dataIndex: 'eventName',
          key: 'eventName',
        },
        {
          title: 'Bắt đầu',
          dataIndex: 'eventStart',
          key: 'eventStart'
        },
        {
          title: 'Kết thúc',
          dataIndex: 'eventEnd',
          key: 'eventEnd',
        },
        {
          title: 'Nơi diễn ra sự kiện',
          dataIndex: 'eventLocation',
          key: 'eventLocation'
        },
        {
          title:'Upload file',
          dataIndex:'uploadFile',
          key:'uploadFile',
          render: (key:any, record:any, index:number) => {
            return(
              <a onClick={()=>{
                setId(record.id); setIsUploadOpen(true);
              }}>Cập nhật lại file đính kèm</a>
            )
          }
        },
        {
          title: 'Hành động',
          dataIndex: 'delete',
          key: 'delete',
          render: (key:any, record:any, index:number) => {
            return(
            <Button type="primary" danger
            disabled = {localStorage.getItem('emailData') != 'test@alta.com.vn' ? 
            false : true } 
            onClick = {()=>{
              setIsConfirmModalOpen(true);
              setDeleteItem(record.id);
            }}
            >Xóa</Button>
            ) 
          }
        }
      ].filter(x=>!x.hidden);

      const columnsExcel = [
        {
            title:'Id',
            dataIndex:'id',
            key:'id'
        },
        {
          title: 'Tên sự kiện',
          dataIndex: 'eventName',
          key: 'eventName',
        },
        {
          title: 'Bắt đầu',
          dataIndex: 'eventStart',
          key: 'eventStart'
        },
        {
          title: 'Kết thúc',
          dataIndex: 'eventEnd',
          key: 'eventEnd',
        },
        {
          title: 'Nơi diễn ra sự kiện',
          dataIndex: 'eventLocation',
          key: 'eventLocation'
        }
      ];
      const columnsAttender = [
        { title: 'Stt', dataIndex: 'stt', key: 'stt'},
        { title: 'Họ và tên', dataIndex: 'attenderName', key: 'attenderName'},
        { title: 'Điện thoại', dataIndex: 'telephoneNumber', key: 'telephoneNumber'},
        { title: 'Email', dataIndex: 'emailAddress', key:'emailAddress'},
        { title: 'Cơ sở đào tạo', dataIndex: 'university', key:'university'}
      ]
    const [fileList, setFileList] = useState<File>();
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isUploadOpen, setIsUploadOpen] = useState(false);
    const [isConfirmModalOpen, setIsConfirmModalOpen] = useState(false);
    const [id, setId] = useState(0);
    const handleConfirmOk = (id: number) => {
        setIsConfirmModalOpen(false);
        fetch(process.env.REACT_APP_API_URL+'api/Events/' + id, {
            method: 'DELETE',
            headers: {
                'Authorization': `Bearer ${token}`,
            },
    }).then(response => {
        getAllEvents();
    })
    .catch(err => console.log(err));
    }
    const handleConfirmCancel = () => {
      setIsConfirmModalOpen(false);
    }
    const handleOk = () => {
        setIsModalOpen(false);
      };
      const handleCancel = () => {
        setIsModalOpen(false);
      };
      const showModal = () => {
        setIsModalOpen(true);
      };

    const [data, setData] = useState<any[]>([]);
    const getAllEvents = async () => {
      const token = localStorage.getItem('token');
      const tokenRefresh = localStorage.getItem('tokenRefresh');
      const mainUrl = process.env.REACT_APP_API_URL+'api/Events/1/10';
      const refreshUrl = process.env.REACT_APP_API_URL+'api/Token/refresh';
      const outputData = await gettingData(mainUrl, refreshUrl, token, tokenRefresh, 'GET', {})
      .then((data:any)=>{
        setData(data.map((item:any)=>{
          let eventStart = getDateStringWithTime(new Date(item.eventStart));
          let eventEnd = getDateStringWithTime(new Date(item.eventEnd));
          return(
              {...item, eventStart: eventStart, eventEnd: eventEnd}
          )
        }))
      });
    }
    useEffect(()=>{
        getAllEvents();
    },[])
    
    const expandedRowRender = (record:any) => {
      let subData = data.filter(x=>x.id == record.id)[0].attenders;
      const columns: ColumnsType<any> = [
        { title: 'Stt', dataIndex: 'stt', key: 'stt',
          render: (key:any, record:any, index:number) => {
              return(
                <p>{index+1}</p>
              )
          }
        },
        { title: 'Họ và tên', dataIndex: 'attenderName', key: 'attenderName'},
        { title: 'Điện thoại', dataIndex: 'telephoneNumber', key: 'telephoneNumber'},
        { title: 'Email', dataIndex: 'emailAddress', key:'emailAddress'},
        { title: 'Cơ sở đào tạo', dataIndex: 'university', key:'university'}        
      ];
      return (
        <div>
        <a href="#" onClick={()=> {
          const excel = new Excel();
          excel.addSheet("Attender").addColumns(columnsAttender).addDataSource(subData, {
        str2Percent: true
      })
      .saveAs("Attender.xlsx");
          return false;
        }}>Xuất Excel</a>
      <Table columns={columns} dataSource={subData} />
      </div>
      )
    }
    const handleUpload = ()=>{
        if(fileList){
          const formData = new FormData();
          formData.append("file", fileList);
          const token = localStorage.getItem('token');
      const tokenRefresh = localStorage.getItem('tokenRefresh');
      const mainUrl = process.env.REACT_APP_API_URL+'api/Events/upload/' + id;
      const refreshUrl = process.env.REACT_APP_API_URL+'api/Token/refresh';
          // const result = gettingDataWithAxios(mainUrl, refreshUrl, token, tokenRefresh, 'POST', formData)
          // .then(data=>{
          //   message.info("Uploaded");
          // })
      }
        setIsUploadOpen(false);
    }
    const onFinish = async (values: any) => {
      const token = localStorage.getItem('token');
      const tokenRefresh = localStorage.getItem('tokenRefresh');
      const mainUrl = process.env.REACT_APP_API_URL+'api/Events/';
      const refreshUrl = process.env.REACT_APP_API_URL+'api/Token/refresh';
      const inputData = {eventName: values.EventName, eventStart: values.EventStart.$d.toISOString(),
        eventEnd:values.EventEnd.$d.toISOString(), eventLocation:values.EventLocation, UserCreated: localStorage.getItem('emailData'),
        eventDescription: values.EventDescription};
      const outputData = gettingData(mainUrl, refreshUrl, token, tokenRefresh, 'POST', inputData);
      getAllEvents();       
    };
    return(
    <>
    <Button onClick={showModal}
    disabled = {localStorage.getItem('emailData') != 'test@alta.com.vn' ? 
    false : true } 
    >Thêm sự kiện</Button>&nbsp;&nbsp;
    <a href="#" onClick={()=> {
          const excel = new Excel();
          excel.addSheet("Event").addColumns(columnsExcel).addDataSource(data, {
        str2Percent: true
      })
      .saveAs("Event.xlsx");
          return false;
        }}>Xuất Excel</a>
     <Table columns={columns} dataSource={data} expandable={{ expandedRowRender, defaultExpandedRowKeys: ['0'] }} />
    <Modal title="Thêm sự kiện" open={isModalOpen} onOk={handleOk} onCancel={handleCancel}>
        <Form labelCol={{ span: 4 }}
        wrapperCol={{ span: 20 }}
        layout="horizontal"
        onFinish={onFinish} >
            <Form.Item label="Tên sự kiện:" 
        name="EventName"
        rules={[{ required: true, message: 'Không được để trống!' }]}>
          <Input />
        </Form.Item>
        <Form.Item label="Ngày bắt đầu:" 
        name="EventStart"
        rules={[{ required: true, message: 'Không được để trống!' }]}>
          <DatePicker showTime={{format: 'HH:mm'}} format='YYYY-MM-DD HH:mm' />
        </Form.Item>
        <Form.Item label="Ngày kết thúc:" 
        name="EventEnd"
        rules={[{ required: true, message: 'Không được để trống!' }]}>
          <DatePicker showTime={{format: 'HH:mm'}} format='YYYY-MM-DD HH:mm' />
        </Form.Item>
        <Form.Item label="Nơi diễn ra sự kiện:" 
        name="EventLocation"
        rules={[{ required: true, message: 'Không được để trống!' }]}>
          <Input />
        </Form.Item>
        <Form.Item label="Mô tả sự kiện:" 
        name="EventDescription"
        rules={[{ required: true, message: 'Không được để trống!' }]}>
          <Input multiple/>
        </Form.Item>
        <Form.Item label="" wrapperCol={{offset:4}}>
          <Button type="primary" htmlType="submit">Lưu dữ liệu</Button>
        </Form.Item>
        </Form>
    </Modal>
    <Modal title="Xóa dữ liệu" open={isConfirmModalOpen} onOk={()=>handleConfirmOk(deleteItem)} onCancel={handleConfirmCancel}>
        <h3 style={{color:'#FF0000'}}>Bạn có chắc chắn xóa trường {deleteItem} này khỏi hệ thống?</h3>
    </Modal>
    <Modal open={isUploadOpen} onOk={handleUpload} 
    onCancel={()=>setIsUploadOpen(false)}>
    <Form>
      <FormItem name="fileList"
        label='Chọn file để đăng:'
        rules={[{required:true, message:'Bạn phải đăng file'}]}
        >
        <Input type="file" onChange={(e:ChangeEvent<HTMLInputElement>)=>{
          const temp = e.target.files;
          if(!temp) 
            return;
          else
            setFileList(temp[0])}} />
      </FormItem>
    </Form>
    </Modal>
    </>
    )
}

export default AltaEvent;