import { OrderedListOutlined, UserOutlined, ControlOutlined, FunctionOutlined } from '@ant-design/icons';
import { MenuProps, Row, Col } from 'antd';
import { Layout, Menu, Form } from 'antd';
import React, {useState, useEffect} from 'react';
import Giaovien from '../giaovien';
import Student from '../student';
import {getSubjects} from '../../layouts/registers/subjectSlice';
import { PersonalInfo } from '../personalInfo';
import TestResults from '../testResults';
import EnableTest from '../config';
import { useAppDispatch } from '../../configs/hook';
import {getTestResult} from '../testResults/testResultSlice';
import {getStudent} from '../student/studentSlice';
import Teachers from '../teacher';
import {getTeachers} from '../teacher/teacherSlice';
import {Report} from '../report/generalReport';
import { ReportByUni } from '../report/reportByUni';
import {NoReportList} from '../report/noReport';
import University from '../university';
import Subject from '../subject';
import InternsRegistered from '../internsRegistered';
import EnableRegister from '../enableRegister';
import {getMailPattern} from '../internsRegistered/internSlice';
import { isLogout, getToken } from '../../library';
import {TimeLine} from '../timeline';
import { getTimeline } from '../timeline/timelineSlice';
import dayjs from 'dayjs'
import { TimeLineInterviews } from '../timeline/interview';
import Company from '../company';
import { getProvinces } from './provinceSlice';
import { getAllUniversities } from '../../layouts/registers/universitySlice';
import FbUser from '../fbUsers';
import AltaEvent from '../altaEvent';
const { Header, Content, Sider } = Layout;
  
type SizeType = Parameters<typeof Form>[0]['size'];

const Admin = () => {
  const userRole = localStorage.getItem('userRole');
  async function refreshToken(){
    const token = localStorage.getItem('token');
    if(token!=null){
      if(isLogout(token)){
        const myData = {
          username: localStorage.getItem('emailData'),
          password: localStorage.getItem('password'),
          role:localStorage.getItem('role')
        }
        getToken(myData);
      } 
    }
  }
  const dispatch = useAppDispatch();
  useEffect(()=>{
    dispatch(getSubjects());
    dispatch(getProvinces());
    dispatch(getTimeline(dayjs(new Date())));
  },[])
const [collapsed, setCollapsed] = useState(true);
const [itemIndex, setItemIndex] = useState(0);
const items1: MenuProps['items'] = ['Mở đề thi', 'Lớp học phần', 
'Xem điểm', 'Thay đổi thông tin','Đăng xuất'].map((key, index) => ({
  key,
  label: key,
  onClick: ()=>{
      if(key=='Đăng xuất')
        {
          fetch(process.env.REACT_APP_API_URL+'api/Token/logout/' + localStorage.getItem('emailData'), {
            method: 'DELETE',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            }
            }).then(response=>{
              localStorage.clear();
              window.location.reload();
            })
        }
        setItemIndex(index);
  }
}));
useEffect(()=>{
  dispatch(getTestResult("admin"));
  dispatch(getStudent("admin"));
  dispatch(getTeachers());
  dispatch(getMailPattern());
  dispatch(getAllUniversities());
},[]);
const item2List = [{
  icon:UserOutlined,
  text: 'Báo cáo'
},
{
  icon:OrderedListOutlined,
  text: 'Danh mục'
},
{
  icon: ControlOutlined,
  text: 'Thiết lập'
},
{
  icon: FunctionOutlined,
  text: 'Chức năng'
}
]
const items2: MenuProps['items'] = item2List.map(
    (icon, index) => {
      const key = String(index + 1);
      let myArr = ['Kết quả thực tập', 'Danh sách không báo cáo đúng tiến độ', 'Thống kê chung']
      let myArr1 = ['Ban quản trị', 'Giáo viên', 'Trường đại học, cao đẳng', 'Lĩnh vực chuyên môn', 'Đăng ký thực tập']
      let myArr2 = ['Cấu hình thư cám ơn và mở đăng ký', 'Tài khoản đăng nhập từ facebook']
      let myArr3 = ['Lịch công tác cá nhân', 'Lịch phỏng vấn', 'Các doanh nghiệp đăng ký', 'Sự kiện']
      switch(index) 
      {
        case 0:
      return {
        key: `sub${key}`,
        icon: React.createElement(item2List[index].icon),
        label: item2List[index].text,
        children: myArr.map((item, j) => {
          const subKey = j+9;
          return {
            key: subKey,
            label: item,
            onClick: ()=>{
                setItemIndex(subKey);
          }
          };
        }),
      }
      case 1:
      return {
        key: `sub${key}`,
        icon: React.createElement(item2List[index].icon),
        label: item2List[index].text,
        children: myArr1.map((item, j) => {
          const subKey = j+4;
          return {
            key: subKey,
            label: item,
            onClick: ()=>{
                setItemIndex(subKey);
          }
          };
        }),
      }
      case 2:
        return {
          key: `sub${key}`,
          icon: React.createElement(item2List[index].icon),
          label: item2List[index].text,
          children: myArr2.map((item, j) => {
            const subKey = j+12;
            return {
              key: subKey,
              label: item,
              onClick: ()=>{
                  setItemIndex(subKey);
            }
            };
          }),
        }
        default:
          return {
            key: `sub${key}`,
            icon: React.createElement(item2List[index].icon),
            label: item2List[index].text,
            children: myArr3.map((item, j) => {
              const subKey = j+14;
              return {
                key: subKey,
                label: item,
                onClick: ()=>{
                    setItemIndex(subKey);
              }
              };
            }),
          }
    }
  }
  );
return(
  <>
  <Layout>
    <Header className="header">
      <Row justify='space-between'>
      <Col style={{width:'70%'}}>
      <div className="logo" />
      <Menu theme="dark" mode="horizontal"
      defaultSelectedKeys={['2']} 
      items={items1}
      />
      </Col>
      <Col style={{width:'30%'}}>
      <div style={{display:'inline', float:'right', position:'relative', marginRight:'0px', color:'white', fontWeight:'bold'}}>
          {localStorage.getItem('userFullName')}
        </div>
        {localStorage.getItem('avatar')!=''?
        <img src={localStorage.getItem('avatar')??''} 
        style={{width:'40px', height:'40px', marginRight:'10px', float:'right', borderRadius:'100%', border:'2px solid white', marginTop:'10px'}} />:""}        
      </Col>
      </Row>
    </Header>
    <Layout>
    <Sider width={300} className="site-layout-background" 
    collapsible collapsed={collapsed} onCollapse={(value) => setCollapsed(value)}
    >
        <Menu
          mode="inline"
          defaultSelectedKeys={['1']}
          defaultOpenKeys={['sub1']}
          style={{ height: '100%', borderRight: 0 }}
          items={items2}
        />
      </Sider>
      <Layout style={{ padding: '0 24px 24px' }}>
        <Content      
          className="site-layout-background"
          style={{
            padding: 20,
            margin: 0,
            minHeight: 280,
            overflowX:'scroll'
          }}
        >
          {itemIndex == 1 ? userRole!='SupAdmin' ? "Bạn không có quyền truy cập" :<Student taxCode='admin@alta.com.vn' />
          : itemIndex==2 ? userRole!='SupAdmin' ? "Bạn không có quyền truy cập" : <TestResults /> :
          itemIndex==0 ? userRole!='SupAdmin' ? "Bạn không có quyền truy cập" :<EnableTest /> : 
          itemIndex == 3 ? <PersonalInfo /> :      
          itemIndex == 5 ? userRole!='SupAdmin' ? "Bạn không có quyền truy cập" :<Teachers /> :
          itemIndex == 6 ? userRole!='SupAdmin' ? "Bạn không có quyền truy cập" :<University /> :
          itemIndex == 7 ? userRole!='SupAdmin' ? "Bạn không có quyền truy cập" :<Subject /> :
          itemIndex == 11 ? <Report /> :
          itemIndex == 8 ? <InternsRegistered /> :
          itemIndex == 9 ? <ReportByUni /> :
          itemIndex == 10 ? <NoReportList /> :
          itemIndex == 12 ? userRole!='SupAdmin' ? "Bạn không có quyền truy cập" :<EnableRegister />:
          itemIndex == 13 ? userRole!='SupAdmin' ? "Bạn không có quyền truy cập" :<FbUser /> :
          itemIndex == 14 ? userRole!='SupAdmin' ? "Bạn không có quyền truy cập" :<TimeLine /> :
          itemIndex == 15 ? userRole!='SupAdmin' ? "Bạn không có quyền truy cập" :<TimeLineInterviews /> :
          itemIndex == 16 ? userRole!='SupAdmin' ? "Bạn không có quyền truy cập" :<Company /> :
          itemIndex == 17 ? userRole!='SupAdmin' ? "Bạn không có quyền truy cập" :<AltaEvent />
          :userRole!='SupAdmin' ? "Bạn không có quyền truy cập" :<Giaovien />
          }  
        </Content>
      </Layout>
    </Layout>
  </Layout>
  </>
)
};
export default Admin;