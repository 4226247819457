import React from 'react';
import ChangePass from '../../components/changePass';
const ChangePassLayout = () => {
  return(
    <>
    <div className='RootContainerRegister'>
      <div className='RegisterHeader'>
          <div className='TopLeftContainerRegister'>
                <img src='AltaGroup.svg' style={{width:'52px', height:'52px'}} />
                <img src='Plastic.svg' style={{width:'91px', height:'52px', marginLeft:'5px'}} />
                <img src='Media.svg' style={{width:'66px', height:'52px', marginLeft:'5px'}} />
                <img src='Software.svg' style={{width:'52px', height:'52px', marginLeft:'5px'}} />
                <img src='Unigon.svg' style={{width:'52px', height:'52px', marginLeft:'5px'}} />
          </div>
      </div>
      <div className='ContentArea' style={{paddingTop: '50px'}}>
            <ChangePass />
      </div>
      <div className='FooterRegister'>
          @Copyright Alta Software - Training Team 04/2023
      </div>
    </div>
    </>
  );
}
export default ChangePassLayout;