import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import { Dispatch } from 'redux';
import { useAppDispatch } from '../../configs/hook';
import getState from 'redux-thunk';
import {Admin} from '../../interface';
import axios from 'axios';
import { stat } from 'fs';
import { message } from 'antd';


export const adminSlice = createSlice({
    name:'admins',
    initialState:[] as Admin[],
    reducers: ({
        getAdmins: (state, action: PayloadAction<Admin[]>) => {
            state = [];
            return action.payload;
        },
        addAdmin:(state, action:PayloadAction<Admin>) =>{
            return [...state, action.payload]
        },
        updateAdmin: (state, action:PayloadAction<Admin>)=>{
            let index = state.findIndex(c=>c.manhansu==action.payload.manhansu);
            let previous = state.slice(0,index);
            let next = state.slice(index+1);
            return [...previous, action.payload, ...next];    
        },
        deleteAdmin: (state, action:PayloadAction<string>) => {
            return state.filter(c=>c.manhansu!=action.payload);
        }
    })
})
export function getAdmins(){
    return async function getAdminsThunk(dispatch: Dispatch, getState:any){
        const token = localStorage.getItem('token');
        const rawResponse = await fetch(process.env.REACT_APP_API_URL+'api/Admin/', {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`,
            }
    }).then(response => {
        if(response.status==401){
            message.info("Phiên đăng nhập đã hết hạn!");
            localStorage.clear();
            window.location.reload();
        }
        return response.json()
    })
    .then((data)=>{
        console.log(data);
        let newdata: Admin[] = [];
        data.map((item:any)=>{
            newdata.push({manhansu: item.manhansu,
            hovaten:item.hovaten,
            email:item.email,
            dienthoai:item.dienthoai,
            imageDataUrl:item.imageDataUrl,
            isEditableDienthoai: false,
            isEditableEmail:false
            });
        })
            dispatch(
                adminSlice.actions.getAdmins(newdata)
              )
    })
    .catch(err => console.log(err));
    }
}
export function updateAdmin(myadmin: Admin){
    return async function updateAdminThunk(dispatch: Dispatch, getState:any){
        const token = localStorage.getItem('token');
      const formData = new FormData();
          formData.append("Manhansu", myadmin.manhansu);
          formData.append("Hovaten", myadmin.hovaten);
          formData.append("Email", myadmin.email);
          formData.append("Password", "abc123!");
          formData.append("Dienthoai", myadmin.dienthoai);
          const config = {     
            headers: { 'content-type': 'multipart/form-data', 'Authorization': `Bearer ${token}` }
          }
          axios.put(process.env.REACT_APP_API_URL+'api/Admin/'+ myadmin.manhansu,formData, config)
            .then(response => {
                if(response.status==401){
                    message.info("Phiên đăng nhập đã hết hạn!");
                    localStorage.clear();
                    window.location.reload();
                }
            })
            .catch(error => {
                console.log(error);
            });
        dispatch(adminSlice.actions.updateAdmin(myadmin));
    }
}
export function deleteAdmin(manhansu: string){
    return async function deleteAdminThunk(dispatch: Dispatch, getState:any){
        const token = localStorage.getItem('token');
        const rawResponse = await fetch(process.env.REACT_APP_API_URL+'api/Admin/'+ manhansu, {
          method: 'DELETE',
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`, // notice the Bearer before your token
          },
        });
        const msg = await rawResponse.json();
        console.log(msg);
        dispatch(adminSlice.actions.deleteAdmin(manhansu));
    }
}


