import React, {useEffect, useState} from 'react';
import {Button} from 'antd';
import './style.css';
import { display } from 'html2canvas/dist/types/css/property-descriptors/display';
import ReactHtmlParser from 'react-html-parser';
import { printResultToPDF } from '../../helpers';
import Item from 'antd/es/list/Item';
const CvTemplateScience = (props:any) => {
    useEffect(()=>{
        console.log(props);
    },[])
    return(
        <div className='ParentContainer'>
        <div className='containerPro' id='myCV'>
            <div className='GeneralInfoContainer'>
            <div className='GeneralInfo'>
                <h2 style={{border:'none'}}>I- PERSONAL PARTICULARS:</h2>
                <p>Full Name: {props.data.internName}</p>
                <p>Date of Birth: {props.data.dateOfBirth}</p>
                <p>Phone Number: {props.data.telephoneNum}</p>
                <p>Address: {props.data.internAddress}</p>
                <p>Email: {props.data.internMailReplace??props.data.internMail}</p>
            </div>
            <div style={{marginRight:'40px', marginTop:'10px'}}>
                <img src={props.data.internImageUrl} style={{width:'300px', objectFit:'contain', border:'3px solid blue'}} />
            </div>
            </div>
            <div className='Profile'>
                <h2 style={{border:'none'}}>II- PROFILE:</h2>
                <p style={{margin:'20px 40px'}}>{props.data.introduction}</p>
            </div>
            <div className='Education'>
                <h2 style={{border:'none'}}>III- EDUCATION / PROFESTIONAL QUALIFICATIONS / CERTIFICATIONS:</h2>
                <table style={{margin:'20px 40px'}}>
                    <tr>
                        <td>Period</td>
                        <td>University (Name or Country)</td>
                    </tr>
                    {props.data.internHistories.filter((x:any)=>x.eventType==2).map((item:any)=>{
                        return(
                        <tr>
                            <td>{item.eventDate} - {item.endDate}</td>
                            <td>{item.eventName}</td>    
                        </tr>
                        )
                    })}
                </table>
            </div>
            <div className='Employment'>
                <h2 style={{border:'none'}}>IV- EMPLOYMENT HISTORY:</h2>
                {props.data.internHistories.filter((x:any)=>x.eventType==1).map((item:any)=>{
                        return(
                        <div>
                            <h3 style={{margin:'20px 32px'}}>Period {item.eventDate} - {item.endDate}</h3>
                            <p style={{margin:'20px 40px'}}><span>Appointment / Organization:</span>
                            {item.eventName}
                            </p>
                            <div className='contentWithHtml' style={{paddingTop:'0px'}}>
                                {ReactHtmlParser(item.eventDetail)}
                            </div>
                        </div>
                        )
                    })}
            </div>
            <div className='Employment'>
            {props.data.internHistories.filter((x:any)=>x.eventType==3).length!=0?
                <h2 style={{border:'none'}}>V- REFERENCE PROJECTS:</h2>:null}
                {props.data.internHistories.filter((x:any)=>x.eventType==3).map((item:any, index:number)=>{
                        return(
                        <div>
                            <h3 style={{margin:'20px 32px'}}>PROJECT {index+1}</h3>
                            <p style={{margin:'20px 40px'}}><span>Name:</span>
                            {item.eventName}
                            </p>
                            <p>Period {item.eventDate} - {item.endDate}</p>
                            <div className='contentWithHtml' style={{paddingTop:'0px'}}>
                                {ReactHtmlParser(item.eventDetail)}
                            </div>
                        </div>
                        )
                    })}
            </div>
        </div>
        <div className='options'>
            <Button onClick={()=>{
                printResultToPDF('myCV','a4');
            }}
            style={{marginTop:'5px'}}
            ><img src='pdfButton.jpg' style={{width:'80px', height:'80px'}} /></Button>
        </div>
        </div>
    )
}
export default CvTemplateScience;