import React, { useState } from 'react';
import ReactHtmlParser from 'react-html-parser';
import { useSelector } from 'react-redux';
import { RootState } from '../../redux/store';
import { answerSlice } from '../thitracnghiem/tracnghiemSlice';
import { useAppDispatch } from '../../configs/hook';
import './styles.css';
type SingleResponseProps = {
  dapan:any,
  index: number,
  phatbieu: string,
  imageDataUrl: string,
  luachons: {
    luachonId: number,
    cauhoiId: number,
    phatbieu: string
  }[]
}
const SingleResponse = (props:SingleResponseProps) => {
  const myAnswers = useSelector((state:RootState)=>state.answer);
  const [text, setText] = useState('');
  const dispatch = useAppDispatch();
  return (
  <div className='whole-question'>
    <div className="question-container">
      <div className="question-header">
        Câu {props.index}:
      </div>
      <div className="question-content">
      {ReactHtmlParser(props.phatbieu)}
      </div>
      <div className="answer-list">
        {props.luachons.length > 1?
        props.luachons.map((item:any, index:number)=>{
            return(
              <label className="answer-item" key={item.luachonId} style={props.dapan.length>0?
                props.dapan.filter((x:any)=>x.luachonId==item.luachonId)[0]?.dapan == true?
                {color:'blue'} : {color:'red'} : {}}>
                  <input type="radio" name={item.cauhoiId}
                  onChange={(e:any)=>{
                    let rowIndex = myAnswers.findIndex(x=>x.cauhoiId==item.cauhoiId);
                    let answer = {...myAnswers[rowIndex], luachons:[] as any[]}
                    answer.luachons.push({luachonId: item.luachonId,
                      cauhoiId: item.cauhoiId, phatbieu: item.phatbieu});
                    dispatch(answerSlice.actions.changeAnswer(answer));
                  }}
                  />
                  {index+1}. {item.phatbieu}.
              </label>
            )
        }):
        <label className="answer-item" key={props.luachons[0].luachonId}>
            <input type="text" name="answer"
            value={text}
            onChange={(e)=>{
              setText(e.currentTarget.value)
              let cauhoiIndex = myAnswers.findIndex(x=>x.cauhoiId==props.luachons[0].cauhoiId);
              let answer = {...myAnswers[cauhoiIndex], luachons:[{
                ...myAnswers[cauhoiIndex].luachons[0], phatbieu: e.currentTarget.value
              }]}
              if(cauhoiIndex>-1){
                dispatch(answerSlice.actions.changeAnswer(answer));
              }
            }}
            />
            <span style={{color:'blue'}}>{props.dapan.length>0?props.dapan.filter((x:any)=>x.cauhoiId==props.luachons[0].cauhoiId)[0]?.phatbieu:null}</span>
        </label>}
      </div>
    </div>
    {props.imageDataUrl!=""&&props.imageDataUrl!=null?
          <img src={props.imageDataUrl} className='imgQuestion' />
          : null}
  </div>
  );
};

export default SingleResponse;
