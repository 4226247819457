import {message} from 'antd';
import { rejects } from 'assert';
import { resolve } from 'path';
// import axios from 'axios';
// export const gettingDataWithAxios = (mainUrl:string, refreshUrl:string, accessToken: string|null, 
//   refreshToken:string|null, mainMethod: string, inputData: any) => {
//       return new Promise((resolve, reject) => {
//         axios.post(mainUrl, inputData, {     
//           headers: { "Content-Type": "multipart/form-data", 'Authorization': `Bearer ${accessToken}` }
//         })
//   }).then((response:any) => {
//       if(response.status==401){
//         fetch(refreshUrl, {
//           method: 'POST',
//           headers: {
//               'Accept': 'application/json',
//               'Content-Type': 'application/json'
//           },
//           body: JSON.stringify(refreshToken)
//           }).then(response=>{
//             if(response.status==401){
//               message.info("Phiên đăng nhập đã hết hạn!");
//               localStorage.clear();
//               window.location.reload();
//             }
//             else{
//               let data:any = response.json();
//               localStorage.setItem('token', data.access_Token);
//               localStorage.setItem('tokenRefresh', data.refresh_Token);
//               fetch(mainUrl, {
//                 method: mainMethod,
//                 headers: {
//                     'Accept': 'application/json',
//                     'Content-Type': 'application/json',
//                     'Authorization': `Bearer ${data.access_Token}`,
//                 },
//                 body: JSON.stringify(inputData)
//               }).then(response=>response.json())
//               .then(data=>{
//                 resolve(data);
//               });
//             }
//           })
//       }
//       else{
//           let data:any = response.json();
//           resolve(data);
//       }
//   });
// }
export const gettingData = (mainUrl:string, refreshUrl:string, accessToken: string|null, 
  refreshToken:string|null, mainMethod: string, inputData: any) => {
    if(mainMethod!='GET' && mainMethod!='DELETE')
    return new Promise((resolve, reject) => {
      fetch(mainUrl, {
        method: mainMethod,
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${accessToken}`,
        },
        body: JSON.stringify(inputData)
}).then(response => {
    if(response.status==401){
    fetch(refreshUrl, {
      method: 'POST',
      headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json'
      },
      body: JSON.stringify(refreshToken)
      }).then(response=>{
        if(response.status==401){
          message.info("Phiên đăng nhập đã hết hạn!");
          localStorage.clear();
          window.location.reload();
        }
        else{
          let data:any = response.json();
          localStorage.setItem('token', data.access_Token);
          localStorage.setItem('tokenRefresh', data.refresh_Token);
          fetch(mainUrl, {
            method: mainMethod,
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${data.access_Token}`,
            },
            body: JSON.stringify(inputData)
          }).then(response=>response.json())
          .then(data=>{
            resolve(data);
          });
        }
      })       
    }
    else{
        let data:any = response.json();
        resolve(data);
    }
})
    })
    else
    return new Promise((resolve, reject) => {
      fetch(mainUrl, {
        method: mainMethod,
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${accessToken}`,
        }
}).then(response => {
    if(response.status==401){
    fetch(refreshUrl, {
      method: 'POST',
      headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json'
      },
      body: JSON.stringify(refreshToken)
      }).then(response=>{
        if(response.status==401){
          message.info("Phiên đăng nhập đã hết hạn!");
          localStorage.clear();
          window.location.reload();
        }
        else{
          let data:any = response.json();
          localStorage.setItem('token', data.access_Token);
          localStorage.setItem('tokenRefresh', data.refresh_Token);
          fetch(mainUrl, {
            method: mainMethod,
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${data.access_Token}`,
            }
          }).then(response=>response.json())
          .then(data=>{
            resolve(data);
          });
        }
      })       
    }
    else{
        let data:any = response.json();
        resolve(data);
    }
})
    })
};