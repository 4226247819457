import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import { Dispatch } from 'redux';
import {message} from 'antd';
import { useAppDispatch } from '../../configs/hook';
import {TestResults, UpdateTest, StudentResultTest} from '../../interface';
import getState from 'redux-thunk';
import { stat } from 'fs';

export const testResultSlice = createSlice({
    name: 'testResults',
    initialState: [] as TestResults[],
    reducers: {
        getTestResult: (state:TestResults[], action:PayloadAction<TestResults[]>) => {
          return action.payload;
        },
        enableTest: (state: TestResults[], action:PayloadAction<UpdateTest>) => {
            
            let index = state.findIndex(c=>c.malophocphan==action.payload.courseCode);
            let previousState = state.slice(0,index);
            let nextState = state.slice(index+1);
            if(index>-1){
                if(action.payload.option=="modegiuaky")
                {
                    let a = state[index].studentResultTests.findIndex(c=>c.masinhvien==action.payload.studentCode);
                    if(a>-1){
                        let previousStudents = state[index].studentResultTests.slice(0,a);
                        let nextStudents = state[index].studentResultTests.slice(a+1);
                        let newItem = {...state[index], studentResultTests: [...previousStudents, {...state[index].studentResultTests[a], modegiuaky:!state[index].studentResultTests[a].modegiuaky, modecuoiky:state[index].studentResultTests[a].modegiuaky?false:state[index].studentResultTests[a].modecuoiky},
                        ...nextStudents]}
                        return [...previousState, newItem, ...nextState]
                    }
                }
                else
                {
                    let a = state[index].studentResultTests.findIndex(c=>c.masinhvien==action.payload.studentCode);
                    if(a>-1){
                        let previousStudents = state[index].studentResultTests.slice(0,a);
                        let nextStudents = state[index].studentResultTests.slice(a+1);
                        let newItem = {...state[index], studentResultTests: [...previousStudents, {...state[index].studentResultTests[a], modecuoiky:!state[index].studentResultTests[a].modecuoiky, modegiuaky:state[index].studentResultTests[a].modecuoiky?false:state[index].studentResultTests[a].modegiuaky},
                        ...nextStudents]}
                        return [...previousState, newItem, ...nextState]
                    }
                }
            }
            return state;                
        },
        enableEditable: (state:TestResults[], action:PayloadAction<StudentResultTest>) => {
            let index = state.findIndex(c=>c.malophocphan==action.payload.malophocphan);
            let previousState = state.slice(0,index);
            let nextState = state.slice(index+1);
            if(index>-1){
                let a = state[index].studentResultTests.findIndex(c=>c.masinhvien==action.payload.masinhvien);
                if(a>-1){
                    let previousStudents = state[index].studentResultTests.slice(0,a);
                    let nextStudents = state[index].studentResultTests.slice(a+1);
                    let newItem = {...state[index], studentResultTests: [...previousStudents, {...state[index].studentResultTests[a], isDiemchuyencanEditable:action.payload.isDiemchuyencanEditable, 
                        isDiemgiuakyEditable:action.payload.isDiemgiuakyEditable, isDiemcuoikyEditable: action.payload.isDiemcuoikyEditable,
                        diemchuyencan: action.payload.diemchuyencan, diemgiuaky: action.payload.diemgiuaky, diemcuoiky: action.payload.diemcuoiky
                    },
                    ...nextStudents]}
                    return [...previousState, newItem, ...nextState]
                }
            }
        }
    }
})
export function getTestResult(lecturerCode: string){
    return async function getTestResultThunk(dispatch: Dispatch, getState: any){
        const token = localStorage.getItem('token');
        const rawResponse = await fetch(process.env.REACT_APP_API_URL+'api/TestResult/'+lecturerCode, {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`,
            },
    }).then(response => {
        if(response.status==401){
            message.info("Phiên đăng nhập đã hết hạn!");
            localStorage.clear();
            window.location.reload();
        }
        return response.json()
    })
    .then((data:TestResults[])=>{
        let newdata: TestResults[] = [];
        data.map((item:TestResults)=>{
            let studentList : StudentResultTest[] = [];
            item.studentResultTests.map((row:StudentResultTest, index)=>{
                let student = {...row, key: index, isDiemchuyencanEditable: false,
                    isDiemgiuakyEditable: false, isDiemcuoikyEditable: false}
                studentList.push(student);
            })
            let myTestResults = {...item, studentResultTests: studentList }
            newdata.push(myTestResults)
        })
        console.log(newdata);
            dispatch(
                testResultSlice.actions.getTestResult(newdata)
            )
    })
    .catch(err => console.log(err));
    }
}
export function updateTestResult(myUpdate: UpdateTest){
    return async function updateTestResultThunk(dispatch: Dispatch, getState: any){
        const token = localStorage.getItem('token');
        const rawResponse = await fetch(process.env.REACT_APP_API_URL+'api/TestResult/Enable', {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`,
            },
        body: JSON.stringify(myUpdate)
    }).then(response => response.json())
    .then((data)=>{
        console.log(getState());
    })
    .catch(err => console.log(err));
    }
}

export function disableTestResult(myUpdate: UpdateTest){
    return async function updateTestResultThunk(dispatch: Dispatch, getState: any){
        const token = localStorage.getItem('token');
        const rawResponse = await fetch(process.env.REACT_APP_API_URL+'api/TestResult/Disable', {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`,
            },
        body: JSON.stringify(myUpdate)
    }).then(response => response.json())
    .then((data)=>{
        dispatch(testResultSlice.actions.enableTest(myUpdate))
        console.log(getState());
    })
    .catch(err => console.log(err));
    }
}