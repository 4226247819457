import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import { Dispatch } from 'redux';
import { useAppDispatch } from '../../configs/hook';
import {Subject} from '../../interface';
import getState from 'redux-thunk';
import { stat } from 'fs';
type LoginData = {
  data: Subject[];
}
export const loginSlice = createSlice({
    name: 'login',
    initialState: [] as Subject[],
    reducers: {
        getLoginInfo: (state:Subject[], action:PayloadAction<LoginData>) => {
          console.log(action.payload);
          return action.payload.data;
        },
        logout: (state:Subject[], action:PayloadAction<Subject>)=>{
          return [];
        }
    }
})
export function getLoginInfo(details: LoginData){
  console.log(details);
    return async function getLoginInfoThunk(dispatch: Dispatch, getState: any){
      dispatch(loginSlice.actions.getLoginInfo(details));
      console.log(getState());
    }
}