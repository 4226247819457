import React from 'react';
export const dataTranslate = [
    {
        text:'Xem JD yêu cầu tuyển dụng',
        vie: 'Xem JD yêu cầu tuyển dụng',
        en:'View Job Description',
        fre:'Inscrivez-vous en ligne'
    },
    {
        text: 'Cá nhân đăng ký',
        vie: 'Cá nhân đăng ký',
        en:'Personal Register',
        fre:'Conditions De Recrutement'
    },
    {
        text: 'Doanh nghiệp đăng ký',
        vie: 'Doanh nghiệp đăng ký',
        en:'Enterprise Register',
        fre:'Inscrivez-vous Pour Les Entreprises'
    },
    {
        text: 'Chọn nơi làm việc',
        vie: 'Chọn nơi làm việc',
        en:'Select a province',
        fre:'Liste des utilisateurs'
    },
    {
        text: "Nhập vị trí muốn ứng tuyển",
        vie: "Nhập vị trí muốn ứng tuyển",
        en:'Enter job title to search',
        fre:'Conditions de recrutement'
    },
    {
        text: 'Chọn công ty',
        vie: 'Chọn công ty',
        en:'Select company',
        fre:'Profil introduit'
    },
    {
        text: 'Đăng xuất',
        vie: 'Đăng xuất',
        en:'Log out',
        fre:'Se déconnecter'
    },
    {
        text: 'Chọn nơi làm việc',
        vie: 'Chọn nơi làm việc',
        en:'Select working place',
        fre:'Poste'
    },
    {
        text: 'Cập nhật thành công',
        vie: 'Cập nhật thành công',
        en:'Updated successfully',
        fre:'Mis à jour avec succés'
    },
    {
        text: 'TÌM',
        vie: 'TÌM',
        en:'SEARCH',
        fre:'Abréviation'
    },
    {
        text: 'CÔNG VIỆC MƠ ƯỚC',
        vie: 'CÔNG VIỆC MƠ ƯỚC',
        en:'YOUR EXPECTED JOB',
        fre:'Action'
    },
    {
        text: 'TẠI NGÔI NHÀ MỚI',
        vie: 'TẠI NGÔI NHÀ MỚI',
        en:'AT A NEW COMPANY',
        fre:'Ajouté avec succès'
    },
    {
        text: "Đã có lỗi trong quá trình đăng ký",
        vie: "Đã có lỗi trong quá trình đăng ký",
        en:'There are some errors when registering',
        fre:'Ajouter un poste'
    },
    {
        text: "Bạn đăng ký rồi, vui lòng đăng nhập để cập nhật profile",
        vie: "Bạn đăng ký rồi, vui lòng đăng nhập để cập nhật profile",
        en:'You have already registered, Please log in to update your profile',
        fre:'Nom du poste:'
    },
    {
        text: "Bạn chưa có tài khoản trong hệ thống, vui lòng đăng ký",
        vie: "Bạn chưa có tài khoản trong hệ thống, vui lòng đăng ký",
        en:'You have not register yet, Please register here',
        fre:'Ce champ ne doit pas être vide'
    },
    {
        text: 'Thông báo',
        vie: 'Thông báo',
        en:'Notification',
        fre:'Enregistrer des données'
    },
    {
        text: 'ĐIỀN THÔNG TIN ĐĂNG KÝ',
        vie: 'ĐIỀN THÔNG TIN ĐĂNG KÝ',
        en:'FILL IN YOUR REGISTER INFORMATION',
        fre:'Choisir le dossier'
    },
    {
        text: "Nhập email để kiểm tra bạn đã đăng ký chưa",
        vie: "Nhập email để kiểm tra bạn đã đăng ký chưa",
        en:'Enter you email to check if you have already registered',
        fre:'Exigence'
    },
    {
        text: "Địa chỉ email",
        vie: "Địa chỉ email",
        en:'Email address',
        fre:'Exigence'
    },
    {
        text: "Chọn công ty ứng tuyển:",
        vie: "Chọn công ty ứng tuyển:",
        en:'Select company:',
        fre:'Suppression de données'
    },
    {
        text: 'Thông tin này không được bỏ trống!',
        vie: 'Thông tin này không được bỏ trống!',
        en:'This field must be not empty',
        fre:'Le-mail de contact ne doit pas être nul'
    },
    {
        text: "Hình thức thực hiện",
        vie: "Hình thức thực hiện",
        en:'FullTime / PartTime',
        fre:'E-mail de contact:'
    },
    {
        text: "Chọn hình thức thực hiện",
        vie: "Chọn hình thức thực hiện",
        en:'Select FullTime or PartTime',
        fre:'E-mail de contact:'
    },
    {
        text: 'Bán thời gian',
        vie: 'Bán thời gian',
        en:'Part time',
        fre:'Nom de l entreprise:'
    },
    {
        text: 'Toàn thời gian',
        vie: 'Toàn thời gian',
        en:'Full time',
        fre:'Le nom de l entreprise ne doit pas être nul'
    },
    {
        text: 'Điện thoại',
        vie: 'Điện thoại',
        en:'Telephone number',
        fre:'Le nom de l entreprise ne doit pas être nul'
    },
    {
        text: 'Nhập số điện thoại',
        vie: 'Nhập số điện thoại',
        en:'Enter your telephone number',
        fre:'Le nom de l entreprise ne doit pas être nul'
    },
    {
        text: 'Cấp bậc',
        vie: 'Cấp bậc',
        en:'Employee or internship',
        fre:'Le nom de l entreprise ne doit pas être nul'
    },
    {
        text: 'Chọn cấp bậc',
        vie: 'Chọn cấp bậc',
        en:'Select employee or internship',
        fre:'Le nom de l entreprise ne doit pas être nul'
    },
    {
        text: 'Chính thức',
        vie: 'Chính thức',
        en:'Employee',
        fre:'Le nom de l entreprise ne doit pas être nul'
    },
    {
        text: 'Họ và tên',
        vie: 'Họ và tên',
        en:'Your fullname',
        fre:'Le nom de l entreprise ne doit pas être nul'
    },
    {
        text: 'Thực tập',
        vie: 'Thực tập',
        en:'Internship',
        fre:'Le nom de l entreprise ne doit pas être nul'
    },
    {
        text: "Chọn vị trí ứng tuyển",
        vie: "Chọn vị trí ứng tuyển",
        en:'Select job position',
        fre:'Le nom de l entreprise ne doit pas être nul'
    },
    {
        text: 'Chuyên ngành',
        vie: 'Chuyên ngành',
        en:'Major',
        fre:'Le nom de l entreprise ne doit pas être nul'
    },
    {
        text: 'Nhập chuyên ngành',
        vie: 'Nhập chuyên ngành',
        en:'Enter your major',
        fre:'Le nom de l entreprise ne doit pas être nul'
    },
    {
        text: 'Cơ sở đào tạo',
        vie: 'Cơ sở đào tạo',
        en:'Your university or colleage',
        fre:'Le nom de l entreprise ne doit pas être nul'
    },
    {
        text: 'Điện thoại công ty',
        vie: 'Điện thoại công ty',
        en:'Company phone number',
        fre:'Téléphone de l entreprise:'
    },
    {
        text: 'Tên công ty',
        vie: 'Tên công ty',
        en:'Company name:',
        fre:'Adresse de la société:'
    },
    {
        text: 'Địa chỉ công ty',
        vie: 'Địa chỉ công ty',
        en:'Company address:',
        fre:'Adresse de la société:'
    },
    {
        text: 'Người quản lý',
        vie: 'Người quản lý',
        en:'Manager:',
        fre:'Directeur:'
    },
    {
        text: 'Điện thoại người quản lý',
        vie: 'Điện thoại người quản lý',
        en:'Manager telephone number:',
        fre:'Numéro de téléphone du responsable:'
    },
    {
        text: 'Chọn file',
        vie: 'Chọn file',
        en:'Choose file:',
        fre:'Choose file:'
    },
    {
        text: 'Gửi',
        vie: 'Gửi',
        en:'Send',
        fre:'Choose file:'
    },
    {
        text: 'Tất cả',
        vie: 'Tất cả',
        en:'All',
        fre:'Toute'
    },
    {
        text: 'Đã chuyển',
        vie: 'Đã chuyển',
        en:'Provided',
        fre:'Fournie'
    },
    {
        text: 'Đã trả',
        vie: 'Đã trả',
        en:'Returned',
        fre:'Revenu'
    },
    {
        text: 'Đã tuyển dụng',
        vie: 'Đã tuyển dụng',
        en:'Passed',
        fre:'Passé'
    },
    {
        text: 'Tên ứng viên',
        vie: 'Tên ứng viên',
        en:'Candidate Name',
        fre:'Nom du candidat'
    },
    {
        text: 'Trường đại học',
        vie: 'Trường đại học',
        en:'University',
        fre:'Université'
    },
    {
        text: 'Chuyên ngành',
        vie: 'Chuyên ngành',
        en:'Major',
        fre:'Majeure'
    },
    {
        text: 'Vị trí',
        vie: 'Vị trí',
        en:'Position',
        fre:'Position'
    },
    {
        text: 'Ngày sinh',
        vie: 'Ngày sinh',
        en:'Date of Birth',
        fre:'Date de naissance'
    },
    {
        text: 'Tải CV',
        vie: 'Tải CV',
        en:'Download CV',
        fre:'Télécharger CV'
    },
    {
        text: 'Vị trí công việc:',
        vie: 'Vị trí công việc:',
        en:'Job position',
        fre:'Poste'
    },
    {
        text: 'Trạng thái:',
        vie: 'Trạng thái:',
        en:'Status:',
        fre:'Statut:'
    },
    {
        text: 'Người quản lý:',
        vie: 'Người quản lý:',
        en:'Manager:',
        fre:'Directeur:'
    }
]