import React from 'react';
import useVisibility from './useVisibility';
import axios from 'axios';
import {Avatar } from 'antd';
import './chat.css';
interface MessageProps {
groupId: number;
messageDetail: {
  id: number;
  content: string;
  email: string;
  sender: string;
  avatar: string;
  time: string;
  sent: boolean;
  url: string;
  fileName: string;
  status: string;
  groupId?: number;
},
connnection: any
}
const initialMsg = [
    { id: 0, email: '', sender: '', avatar: '', content: '', time: '', sent: false,
      url: '', fileName: '', status:'', groupId:0
     },
  ];
  
const Message = (props:MessageProps) => {
  const handleVisible = async () => {
    if (props.connnection) {
        try {
            props.connnection.invoke("ChangeSeenStatus", props.messageDetail.id, localStorage.getItem('emailData'), props.groupId);
        } catch (err:any) {
            console.error("Send message failed: ", err.toString());
        }
    }
  };
  const { elementRef } = useVisibility(handleVisible);
  return (
    <div ref={elementRef}
     className={`chat-message ${props.messageDetail.sent ? 'sent' : 'received'}`}>
            <div className='userInfo'>
            {props.messageDetail.avatar && <Avatar src={props.messageDetail.avatar} />}&nbsp;{props.messageDetail.sender}
            </div>
            <div className="message-content" ref={elementRef}>
              {props.messageDetail.url==''?
              <div>
              <p>{props.messageDetail.content}</p>
              <p>{props.messageDetail.status}</p>
              {props.messageDetail.time && <span className="message-time">{props.messageDetail.time}</span>}
              </div>
              :<div>
                  {['jpg', 'png', 'bmp', 'jpge'].includes(props.messageDetail.fileName.substring(props.messageDetail.fileName.indexOf('.')+1))?
              <img src={props.messageDetail.url} style={{width:'300px', objectFit: 'contain'}} />:<div>
              <a onClick={() => {
                fetch(process.env.REACT_APP_API_URL_FOR_CHAT+ 'api/Message/' + props.groupId + '/' + props.messageDetail.fileName, {
                  method: 'GET',
          })
          .then(response => response.blob())
      .then(blob => {
          var url = window.URL.createObjectURL(blob);
          var a = document.createElement('a');
          a.href = url;
          a.download = props.messageDetail.fileName;
          document.body.appendChild(a); // we need to append the element to the dom -> otherwise it will not work in firefox
          a.click();    
          a.remove();  //afterwards we remove the element again
      })
    .catch(err => console.log(err));         
              }}>{props.messageDetail.content}</a>
              <p>{props.messageDetail.status}</p>
              </div>
              }
              </div>
              }
            </div>
          </div>
  );
};

export default Message;
