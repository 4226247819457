import React from 'react';
import { Form, Input, Button, Row, Col, message } from 'antd';
import { validateEmail, validatePhoneNumber } from '../../helpers/validator';
import axios from 'axios';
import './RegistrationForm.css'; // Import CSS file for custom styles
import { translate } from '../../helpers/translate';
type EnterpriseRegisterProp = {
  language: string
}
const EnterpriseRegister = (props: EnterpriseRegisterProp) => {
  const [form] = Form.useForm();

  const onFinish = (values: any) => {
    console.log('Received values:', values);
    const formData = new FormData();  
        formData.append("TaxCode", values.TaxCode);
        formData.append("CompanyName", values.CompanyName);
        formData.append("ContactEmail", values.TaxCode);
        formData.append("Telephone", values.Telephone);
        formData.append("Manager", values.Manager);
        formData.append("ManagerTelephone", values.ManagerTelephone);
        const config = {     
          headers: { "Content-Type": "multipart/form-data"}
        }
        axios.post(process.env.REACT_APP_API_URL+'api/Company', formData, config)
          .then(response => {
                console.log(response);
                message.success("Register successfully, please check your email to enable your account",10);
          })
          .catch(error => {
              message.info("There are some errors during registering");
              console.log(error);
          });
  };
  return (
    <Form
      form={form}
      layout="vertical"
      onFinish={onFinish}
      style={{ maxWidth: '800px', margin: '0 auto', padding: '20px', backgroundColor: '#f0f2f5', borderRadius: '8px' }}
    >
      <h2 className='RegisterHeader' style={{ textAlign: 'left', color: '#ff4d4f' }}>{translate(props.language,'ĐIỀN THÔNG TIN ĐĂNG KÝ')}</h2>
      <Row gutter={24}>
        <Col xs={24} sm={24} md={12}>
          <Form.Item
            name="TaxCode"
            label="Email"
            rules={[{ required: true, message: translate(props.language,'Thông tin này không được bỏ trống!') }, {validator:validateEmail}]}
          >
            <Input placeholder="Enter company email" />
          </Form.Item>
        </Col>
        <Col xs={24} sm={24} md={12}>
          <Form.Item
            name="companyAddress"
            label={translate(props.language,"Địa chỉ công ty")}
            rules={[{ required: true, message: translate(props.language,'Thông tin này không được bỏ trống!') }]}
          >
            <Input placeholder={translate(props.language,"Địa chỉ công ty")} />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={24}>
        <Col xs={24} sm={24} md={12}>
          <Form.Item
            name="CompanyName"
            label={translate(props.language,"Tên công ty")}
            rules={[{ required: true, message: translate(props.language,'Thông tin này không được bỏ trống!') }]}
          >
            <Input placeholder={translate(props.language,"Tên công ty")} />
          </Form.Item>
        </Col>
        <Col xs={24} sm={24} md={12}>
          <Form.Item
            name="Manager"
            label={translate(props.language,"Người quản lý")}
            rules={[{ required: true, message: translate(props.language,'Thông tin này không được bỏ trống!') }]}
          >
            <Input placeholder={translate(props.language,"Nhập tên người quản lý")} />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={24}>
        <Col xs={24} sm={24} md={12}>
          <Form.Item
            name="Telephone"
            label={translate(props.language,"Điện thoại công ty")}
            rules={[{ required: true, message: translate(props.language,'Thông tin này không được bỏ trống!') }, {validator:validatePhoneNumber}]}
          >
            <Input placeholder={translate(props.language,"Điện thoại công ty")} />
          </Form.Item>
        </Col>
        <Col xs={24} sm={24} md={12}>
          <Form.Item
            name="ManagerTelephone"
            label={translate(props.language,"Điện thoại người quản lý")}
            rules={[{ required: true, message: translate(props.language,'Thông tin này không được bỏ trống!') }, {validator:validatePhoneNumber}]}
          >
            <Input placeholder={translate(props.language,"Điện thoại người quản lý")} />
          </Form.Item>
        </Col>
      </Row>
      <Form.Item>
        <Button type="primary" htmlType="submit" style={{ backgroundColor: '#ff4d4f', borderColor: '#ff4d4f' }}>
        {translate(props.language,'Gửi')}
        </Button>
      </Form.Item>
    </Form>
  );
};

export default EnterpriseRegister;
