import React, {useState} from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '../../redux/store';
import {Subject } from '../../interface';
import ExaminerInfo from '../../components/examinerInfo';
import TestHeader from '../../components/testHeader';
import SingleResponse from '../../components/singleResponse';
import StudentLayoutHeader from '../../components/Header/studentLayoutHeader';
import { overflow } from 'html2canvas/dist/types/css/property-descriptors/overflow';
import MultipleResponse from '../../components/multipleResponse';
import { printResultToPDF } from '../../helpers';
import InternReport from '../../components/internReport';
const StudentLayout = ()=>{
  const [testingStatus, setTestingStatus] = useState(1);
  const myQuestions = useSelector((state:RootState)=>state.tracnghiem);
  const myDetails = useSelector((state:RootState)=>state.login);
  const [subjectId, setSubjectId] = useState<Subject>(myDetails[0]);
  const [componentIndex, setComponentIndex] = useState(1);
  const [questionId, setQuestionId] = useState(1);
  const [hours, setHours] = useState(0);
  const [minutes, setMinutes] = useState(0);
  const [seconds, setSeconds] = useState(0);
  const [receiveDapan, setReceiveDapan] = useState<any[]>([]);
  const handleReceiveComponentIndex = (componentIndex:number) => {
    setComponentIndex(componentIndex);
  }
  const handleReceiveTestingStatus = (testingStatus:number, course:Subject, hours:number, minutes:number, seconds:number) => {
    setTestingStatus(testingStatus)
    setSubjectId(course);
    setHours(hours);
    setMinutes(minutes);
    setSeconds(seconds);
  }
  const handleReceiveQuestionId = (questionId: number) => {
    setQuestionId(questionId);
  }
  const handleReceiveChangeQuestion = (dapan:any[]) => {
    setReceiveDapan(dapan);
    //printResultToPDF("test", "A3");
  }
    return(
    <div style={{backgroundColor: '#f5f7fa',}}>
      <StudentLayoutHeader handleSelectItem={handleReceiveComponentIndex} />
      {componentIndex==1?
      testingStatus==1?<ExaminerInfo handleCreateTest={handleReceiveTestingStatus} />
      :<div id="test">
        <TestHeader handleChangeQuestion={handleReceiveChangeQuestion} 
        subjectForTest={myQuestions.tenmonhoc}
        numberOfQuestions={myQuestions.cauhois.length}
        course={subjectId}
        hours={hours}
        minutes={minutes}
        seconds={seconds}
        />
        {myQuestions.cauhois.map((item:any, index:number)=>{
          if(item.nhieuluachon)
            return(
              <MultipleResponse index={index+1} phatbieu={item.phatbieu} dapan={receiveDapan}
        luachons={item.luachons}
        imageDataUrl={item.imageDataUrl} />
          )
          else
            return(
              <SingleResponse index={index+1} phatbieu={item.phatbieu} dapan={receiveDapan}
        luachons={item.luachons}
            imageDataUrl={item.imageDataUrl} />
          )
        })}
      </div>
      :<InternReport />}  
    </div>
    )
}
export default StudentLayout;