import React, {useEffect, useState} from 'react';
import {Routes, Route} from 'react-router-dom';
import {ConnectionConfig} from './configs';
import PublicTest from './layouts/tracnghiem';
import DisplayEvents from './components/displayEvent';
import LoginLayout from './layouts/login';
import ResetPassLayout from './layouts/resetPass';
import DeleteAccount from './layouts/deleteaccount';
import SearchJobs from './layouts/searchjobs';
import ChatLayout from './layouts/chat';
function App() { 
  useEffect(()=>{
    const res = fetch("/api/api.json")
                    .then(response=>response.json())
                    .then(data=>{
                        //console.log(data.server);
                        localStorage.setItem("serverName",data.server);
                    }).then(()=>{
                      fetch(process.env.REACT_APP_API_URL+'api/Register/registeredconfig', {
                        method: 'GET',
                        headers: {
                            'Accept': 'application/json',
                            'Content-Type': 'application/json'
                        },
                }).then(response => response.json())
                .then(data=>{
                  localStorage.setItem("enableRegister",data.message);
                })                  
              })
  },[])
  return (
    <div>
      <Routes>
        <Route path='/' element={<LoginLayout />} />
        <Route path='/resetpass' element={<ResetPassLayout />} />
        <Route path='/deleteaccount' element={<DeleteAccount />} />
        <Route path='/config' element={<ConnectionConfig />} />
        <Route path='/register' 
        element={<SearchJobs />} />
        <Route path='/event' 
        element={<DisplayEvents />} />
        <Route path='/tracnghiem' element={<PublicTest />} />
        <Route path='/nvcmischat' element={<ChatLayout />} />
      </Routes>
    </div>
  );
}
export default App;
