import React, {useState, useEffect} from 'react';
import {Button, message} from 'antd';
import { AnswerRequest, Subject } from '../../interface';
import './styles.css';
import '../examinerInfo/styles.css';
import ExaminerInfo from '../examinerInfo';
import MultipleResponse from '../multipleResponse';
import SingleResponse from '../singleResponse';
const OneQuestion = () => {
    const [seconds, setSeconds] = useState(4);
    const [finishedLoading, setFinishedLoading] = useState(false)
    const [myText, setMyText] = useState<string>('');
    const [testDemo, setTestDemo] = useState<any[]>([]);
    const [dapan, setDapan] = useState<any[]>([]);
    const handleReceiveQuestion = async (data:number, course:Subject, hours:number, minutes:number, seconds: number)=>{
      setDapan([]);
      setFinishedLoading(false);
      setSeconds(4);
          await fetch(process.env.REACT_APP_API_URL+"api/Dethi/public/0/1/"
          + seconds,{
            method: 'GET',
              headers: {
                'Accept': 'application/json',
                'Content-type': 'application/json',
              }
          })
            .then(response => response.json())
            .then((data)=>{
              console.log(data);
              setTestDemo(data.cauhois);       
            })
            .catch(err => console.log(err));
    }    
    useEffect(()=>{
      setTimeout(()=>{
        if(seconds>0)
          setSeconds(seconds=>seconds-1);
        else{
          setFinishedLoading(true);
        }                        
      }, 1000);
    }, [seconds, finishedLoading]);
    return(
        <>
        <ExaminerInfo handleCreateTest={handleReceiveQuestion} />
        
        {testDemo.length > 0 ?
        <>
        {!finishedLoading?
        <div className='containerExamInfo'>
          <div className={seconds==4?'loadingdiv3':seconds==3?'loadingdiv2':seconds==2?'loadingdiv1':seconds==1?'loadingdiv0':'loadingdiv'}>
          {seconds}</div>
        </div>
        : <div style={{backgroundColor: '#f0f2f5', display:'flex', flexDirection:'column'}}>{testDemo[0].nhieuluachon ?
        <MultipleResponse index={1} phatbieu={testDemo[0].phatbieu} dapan={dapan}
        luachons={testDemo[0].luachons}
        imageDataUrl={testDemo[0].imageDataUrl}/> 
        
        : <SingleResponse index={1} phatbieu={testDemo[0].phatbieu} dapan={dapan}
        luachons={testDemo[0].luachons}
            imageDataUrl={testDemo[0].imageDataUrl}/>
        }
        <Button type='primary' className='myCustomButton'
        onClick={async ()=>{
            await fetch(process.env.REACT_APP_API_URL+"api/Dethi/dapan/"
            + testDemo[0].cauhoiId,{
             method: 'GET',
               headers: {
                 'Accept': 'application/json',
                 'Content-type': 'application/json',
               }
           })
             .then(response => {
              if(response.status==401){
                message.info("Phiên đăng nhập đã hết hạn!");
                localStorage.clear();
                window.location.reload();
            }
            return response.json()
             })
             .then((data)=>{
               console.log(data);
               setDapan(data);      
             })
             .catch(err => console.log(err));
            }
        }
        >
          Đáp án</Button>
        </div>}
        </>:
        <div></div>}
        </>
    );
}
export default OneQuestion;