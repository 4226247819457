import React, {useState, useEffect} from 'react';
import { Form, Input, Button, DatePicker, Select, Upload, Row, Col, message, Modal } from 'antd';
import { validateEmail, validatePhoneNumber } from '../../helpers/validator';
import axios from 'axios';
import './RegistrationForm.css'; // Import CSS file for custom styles
import type { SelectProps } from 'antd';
import { translate } from '../../helpers/translate';
const { Option } = Select;
const {Search} = Input;
type PersonalRegisterProps = {
  language: string
}
const PersonalRegister = (props:PersonalRegisterProps) => {
  const [form] = Form.useForm();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [textNotify, setTextNotify] = useState('');
  const [fileList, setFileList] = useState<any[]>([]);
  const [options, setOptions] = useState<any[]>([]);
  const [optionsUniversity, setOptionsUniversity] = useState<SelectProps['options']>([]);
  const handleChange = (info:any) => {
    let newFileList = [...info.fileList];
    newFileList = newFileList.slice(-1); // Keep only the last file
    setFileList(newFileList);
  };

  const beforeUpload = (file:any) => {
    // You can do some validations here if needed
    return false; // Prevent automatic upload
  };

  const getSubjectDataForEnterpise = () => {
        fetch(process.env.REACT_APP_API_URL+'api/Subject/enterprise/admin@alta.com.vn',{
            method: 'GET',
            headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            }
  }).then(response => response.json())
  .then((data)=>{
      setOptions(data.map((item:any)=>{
        return {value: item.id, label:item.tenmonhoc, abbreation: item.abbreation}
      }));
  })
  }
  const onFinish = (values: any) => {
        const formData = new FormData();
        if (fileList.length > 0) {
            formData.append('CVFile', fileList[0].originFileObj);
        }
          formData.append("InternSpecialized", values.InternSpecialized);
          formData.append("InternMail", values.InternMail);
          formData.append("InternName", values.InternName);
          formData.append("Major", values.Major);
          formData.append("TelephoneNum", values.TelephoneNum);
          formData.append("University", values.University);
          formData.append("Internable", values.Internable);
          formData.append("FullTime", values.FullTime);
          formData.append("DateOfBirth", values.DateOfBirth);
          formData.append('TaxCode', values.TaxCode)        
          const config = {     
            headers: { "Content-Type": "multipart/form-data" }
          }
          axios.post(process.env.REACT_APP_API_URL+'api/Register', formData, config)
            .then(response => {
                  message.success(response.data.message);
            })
            .catch(error => {
                message.info(translate(props.language,"Đã có lỗi trong quá trình đăng ký"));
            });       
        };
  const [compayList, setCompanyList] = useState<any[]>([]);
  useEffect(()=>{
    getSubjectDataForEnterpise();
      const rawResponse2 = fetch(process.env.REACT_APP_API_URL+'api/Company', {
        method: 'GET',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
        },
  }).then(response => response.json())
  .then((data)=>{
      setCompanyList(data);
  })
  .catch(err => console.log(err));
  fetch(process.env.REACT_APP_API_URL+'api/University', {
    method: 'GET',
    headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
    },
}).then(response => response.json())
.then((data)=>{
    setOptionsUniversity(data.map((item:any)=>{
        return {value: item.matruong, label:item.tentruong}
  }));
})
.catch(err => console.log(err));
    },[]);

    const [loading, setLoading] = useState(false);
    const [searchValue, setSearchValue] = useState('');
    const handleChangeSearchText = (e:any) => {
      setSearchValue(e.target.value);
      console.log('Current search value:', e.target.value);
    };
    const handleSearch = async () => {
      setLoading(true);     
      try {
        // Replace this with your actual API call
        fetch(process.env.REACT_APP_API_URL+'api/Register/getintern/' + searchValue, {
          method: 'GET',
          headers: {
              'Accept': 'application/json',
              'Content-Type': 'application/json',
          },
      }).then(response => response.json())
      .then((data)=>{
          if(data.id != null){
            setTextNotify(translate(props.language,"Bạn đăng ký rồi, vui lòng đăng nhập để cập nhật profile"));
          }
          else{
            setTextNotify(translate(props.language,"Bạn chưa có tài khoản trong hệ thống, vui lòng đăng ký"));
          }
      })
      .then(()=>showModal());
      } catch (error) {
        console.error('Error during API call:', error);
      } finally {
        setLoading(false);
      }
    };
    const showModal = () => {
      setIsModalOpen(true);
    };
  
    const handleOk = () => {
      setIsModalOpen(false);
    };
  
    const handleCancel = () => {
      setIsModalOpen(false);
    };

    const handleOnChangePosition = (value: any) => {
      const data = options?.find((it: any) => it?.value === value).abbreation;
      fetch(process.env.REACT_APP_API_URL+'api/Company/searchjob/' + data,{
        method: 'GET',
        headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        }
}).then(response => response.json())
.then((data)=>{
  setCompanyList(data.map((item:any)=>{
    return {value: item.taxCode, label:item.companyName}
  }));
})
}
  return (
    <>
    <Search placeholder={translate(props.language,"Nhập email để kiểm tra bạn đã đăng ký chưa")} enterButton="Search" 
    style={{ maxWidth: '600px', margin: '0 0 0 158px', padding: '20px', backgroundColor: '#f0f2f5', borderRadius: '8px' }}
    size="large" loading={loading}
    value={searchValue}
      onChange={handleChangeSearchText}
    onSearch={handleSearch} />
    {compayList!=null&&optionsUniversity!=null?
    <Form
      form={form}
      layout="vertical"
      onFinish={onFinish}
      style={{ maxWidth: '1200px', margin: '0 auto', padding: '20px', backgroundColor: '#f0f2f5', borderRadius: '8px' }}
    >
      <h2 className='RegisterHeader' style={{ textAlign: 'left', color: '#ff4d4f' }}>{translate(props.language,'ĐIỀN THÔNG TIN ĐĂNG KÝ')}</h2>
      <Row gutter={24}>
        <Col xs={24} sm={24} md={12}>
          <Form.Item
            name="CVFile"
            label={translate(props.language,"Chọn file")}
            valuePropName="fileList"
            getValueFromEvent={(e) => (Array.isArray(e) ? e : e && e.fileList)}
            rules={[{ required: true, message: translate(props.language,'Thông tin này không được bỏ trống!') }]}
          >
            <Upload name="logo" action="/upload.do" listType="picture"
            beforeUpload={beforeUpload}
            onChange={handleChange}
            fileList={fileList}
            >
                <Button className='ButtonUpload'>
                    <img src="images/selectFile.png" />
                </Button>
            </Upload>
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={24}>
      <Col xs={24} sm={24} md={12}>
      <Form.Item
            name="InternSpecialized"
            label={translate(props.language,"Chọn vị trí ứng tuyển")}
            rules={[{ required: true, message: translate(props.language,'Thông tin này không được bỏ trống!') }]}
          >
            <Select onChange={(value: any) => handleOnChangePosition(value)} placeholder={translate(props.language,"Chọn vị trí ứng tuyển")}>
                {options?.map((item:any)=>{
                    return(
                        <Option value={item.value}>{item.label}</Option>
                    )
                })}
            </Select>
          </Form.Item>
        </Col>
        <Col xs={24} sm={24} md={12}>
          <Form.Item
            name="TaxCode"
            label={translate(props.language,"Chọn công ty ứng tuyển:")}
            rules={[{ required: false }]}
          >
            <Select placeholder={translate(props.language,"Chọn công ty ứng tuyển:")} 
            >
            {compayList?.map((item:any)=>{
                return(
                    <Option value={item.value}>{item.label}</Option>
                )
            })}
            </Select>
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={24}>
      <Col xs={24} sm={24} md={12}>
          <Form.Item
            name="InternName"
            label={translate(props.language,"Họ và tên")}
            rules={[{ required: true, message: translate(props.language,'Thông tin này không được bỏ trống!') }]}
          >
            <Input placeholder={translate(props.language,"Họ và tên")} />
          </Form.Item>
        </Col>
        <Col xs={24} sm={24} md={12}>
          <Form.Item
            name="DateOfBirth"
            label={translate(props.language,"Ngày sinh")}
            rules={[{ required: true, message: translate(props.language,'Thông tin này không được bỏ trống!') }]}
          >
            <DatePicker style={{ width: '100%' }} />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={24}>
      <Col xs={24} sm={24} md={12}>
          <Form.Item
            name="University"
            label={translate(props.language,"Cơ sở đào tạo")}
            rules={[{ required: true, message: translate(props.language,'Thông tin này không được bỏ trống!') }]}
          >
            <Select showSearch={true}
        optionFilterProp="children"
        filterOption={(input, option:any) => {
          return (
            option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
          );        
        }}
        options={optionsUniversity} />
          </Form.Item>
        </Col>
        <Col xs={24} sm={24} md={12}>
          <Form.Item
            name="Major"
            label={translate(props.language,"Chuyên ngành")}
            rules={[{ required: true, message: translate(props.language,'Thông tin này không được bỏ trống!') }]}
          >
            <Input placeholder={translate(props.language,"Nhập chuyên ngành")} />
          </Form.Item>
        </Col>     
      </Row>
      <Row gutter={24}>
      <Col xs={24} sm={24} md={12}>
          <Form.Item
            name="Internable"
            label={translate(props.language,"Cấp bậc")}
            rules={[{ required: true, message: translate(props.language,'Thông tin này không được bỏ trống!') }]}
          >
            <Select placeholder={translate(props.language,"Chọn cấp bậc")}>
              <Option value="0">{translate(props.language,'Nhân viên')}</Option>
              <Option value="1">{translate(props.language,'Thực tập')}</Option>
            </Select>
          </Form.Item>
        </Col>
        <Col xs={24} sm={24} md={12}>
          <Form.Item
            name="InternMail"
            label={translate(props.language,"Địa chỉ email")}
            rules={[{ required: true, message: translate(props.language,'Thông tin này không được bỏ trống!') },{validator:validateEmail}]}
          >
            <Input placeholder={translate(props.language,"Địa chỉ email")} />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={24}>
        <Col xs={24} sm={24} md={12}>
          <Form.Item
            name="TelephoneNum"
            label={translate(props.language,"Điện thoại")}
            rules={[{ required: true, message: translate(props.language,'Thông tin này không được bỏ trống!') }, {validator:validatePhoneNumber}]}
          >
            <Input placeholder={translate(props.language,"Nhập số điện thoại")} />
          </Form.Item>
        </Col>
        <Col xs={24} sm={24} md={12}>
          <Form.Item
            name="FullTime"
            label={translate(props.language,"Hình thức thực hiện")}
            rules={[{ required: true, message: translate(props.language,'Thông tin này không được bỏ trống!') }]}
          >
            <Select placeholder={translate(props.language,"Chọn hình thức thực hiện")}>
              <Option value="0">{translate(props.language,'Bán thời gian')}</Option>
              <Option value="1">{translate(props.language,'Toàn thời gian')}</Option>
            </Select>
          </Form.Item>
        </Col>
      </Row>
      <Form.Item>
        <Button type="primary" htmlType="submit" style={{ backgroundColor: '#ff4d4f', borderColor: '#ff4d4f' }}>
          {translate(props.language,'Gửi')}
        </Button>
      </Form.Item>
    </Form>:"Loading...."}
    <Modal title={translate(props.language,"Thông báo")} open={isModalOpen} onOk={handleOk} onCancel={handleCancel}>
        <p style={{fontSize:18}}>{textNotify}</p>        
    </Modal>
    </>
  );
};

export default PersonalRegister;
