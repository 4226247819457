import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import { Dispatch } from 'redux';
import { useAppDispatch } from '../../configs/hook';
import getState from 'redux-thunk';
import {Admin} from '../../interface';
import axios from 'axios';
import { stat } from 'fs';

export const provinceSlice = createSlice({
    name:'provinces',
    initialState:[] as any[],
    reducers: ({
        getProvinces: (state, action: PayloadAction<Admin[]>) => {
            state = [];
            return action.payload;
        },
    })
})
export function getProvinces(){
    return async function getProvincesThunk(dispatch: Dispatch, getState:any){
        const token = localStorage.getItem('token');
        let myData:any[] = [];
        await fetch('/api/provinces.json')
                  .then(response=>response.json())
                  .then(data=>{
                      data.map((row:any)=>{
                        myData.push({value: row.AreaCode, label: row.Provinces})
                      })})
        dispatch(provinceSlice.actions.getProvinces(myData));
}}

