import React, { useEffect, useState } from 'react';
import { Space, Table, Tag, Button, Modal, Form, Input, message } from 'antd';
import type { ColumnsType } from 'antd/es/table';
import { Excel } from "antd-table-saveas-excel";
import { UniversityType } from '../../interface';
const {Search} = Input;
const University: React.FC = () => {
  const [searchValue, setSearchValue] = useState('');
    const token = localStorage.getItem('token');
    const [deleteItem, setDeleteItem] = useState(''); 
    const columns: ColumnsType<UniversityType> = [
        {
          title: 'Mã trường',
          dataIndex: 'matruong',
          key: 'matruong',
        },
        {
          title: 'Tên trường',
          dataIndex: 'tentruong',
          key: 'tentruong'
        },
        {
          title: 'Địa chỉ',
          dataIndex: 'diachi',
          key: 'diachi',
          render: (key, record:UniversityType, text) => {
            return(
            <>
            { localStorage.getItem('emailData') != 'test@alta.com.vn' ?
            !record.isEditablediachi?
             <a onClick={()=>{
                let index = data.findIndex(c=>c.matruong==record.matruong);
                  let prefix = data.slice(0, index);
                  let mySlice = {...data[index], isEditablediachi: true}
                  let suffix = data.slice(index+1);
                  setData([...prefix, mySlice, ...suffix])
             }}>{record.diachi}</a>
             :<Input type="text" value={record.diachi}
             onChange={(e)=>{
              let index = data.findIndex(c=>c.matruong==record.matruong);
              let prefix = data.slice(0, index);
              let mySlice = {...data[index], diachi: e.currentTarget.value}
              let suffix = data.slice(index+1);
              setData([...prefix, mySlice, ...suffix])
             }}
             onKeyPress={(e)=>{
               if(e.key == "Enter"){
                let index = data.findIndex(c=>c.matruong==record.matruong);
                //let myRecord = data.find(c=>c.matruong==record.matruong);
                fetch(process.env.REACT_APP_API_URL+'api/University/'+record.matruong, {
                    method: 'PUT',
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${token}`,
                    },
                    body: JSON.stringify({matruong: data[index].matruong, tentruong: data[index].tentruong,
                        diachi:data[index].diachi, thongtin:data[index].thongtin
                    })
            }).then(response => {
                console.log(response);
                message.info("Cập nhật thành công");
            })
            .catch(err => console.log(err));
                setData(data.map(item=>{
                  if(item.matruong==record.matruong){
                    return {...item, isEditablediachi: false}
                  }
                  else
                    return item
                }))
               }
             }} />
            :<p>{record.diachi}</p>
            }
             </>)
          }
        },
        {
          title: 'Thông tin',
          dataIndex: 'thongtin',
          key: 'thongtin',
          render: (key, record:UniversityType, text) => {
            return(
            <>
            {localStorage.getItem('emailData') != 'test@alta.com.vn' ?
            !record.isEditablethongtin?
             <a onClick={()=>{
                let index = data.findIndex(c=>c.matruong==record.matruong);
                  let prefix = data.slice(0, index);
                  let mySlice = {...data[index], isEditablethongtin: true}
                  let suffix = data.slice(index+1);
                  setData([...prefix, mySlice, ...suffix])
             }}>{record.thongtin}</a>
             :<Input type="text" value={record.thongtin}
             onChange={(e)=>{
              let index = data.findIndex(c=>c.matruong==record.matruong);
              let prefix = data.slice(0, index);
              let mySlice = {...data[index], thongtin: e.currentTarget.value}
              let suffix = data.slice(index+1);
              setData([...prefix, mySlice, ...suffix])
             }}
             onKeyPress={(e)=>{
               if(e.key == "Enter"){
                let index = data.findIndex(c=>c.matruong==record.matruong);
                //let myRecord = data.find(c=>c.matruong==record.matruong);
                fetch(process.env.REACT_APP_API_URL+'api/University/'+record.matruong, {
                    method: 'PUT',
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${token}`,
                    },
                    body: JSON.stringify({matruong: data[index].matruong, tentruong: data[index].tentruong,
                        diachi:data[index].diachi, thongtin:data[index].thongtin
                    })
            }).then(response => {
                console.log(response);
                message.info("Cập nhật thành công");
            })
            .catch(err => console.log(err));
                setData(data.map(item=>{
                  if(item.matruong==record.matruong){
                    return {...item, isEditablethongtin: false}
                  }
                  else
                    return item
                }))
               }
             }} />
            :<p>{record.thongtin}</p>
            }
             </>)
          }
        },
        {
          title: 'Hành động',
          dataIndex: 'delete',
          key: 'delete',
          render: (key, record:UniversityType, text) => {
            return(
            <Button type="primary" danger
            disabled = {localStorage.getItem('emailData') != 'test@alta.com.vn' ? 
            false : true } 
            onClick = {()=>{
              setIsConfirmModalOpen(true);
              setDeleteItem(record.matruong);
            }}
            >Xóa</Button>
            ) 
          }
        }
      ];

      const columnsExcel = [
        {
          title: 'Mã trường',
          dataIndex: 'matruong',
          key: 'matruong',
        },
        {
          title: 'Tên trường',
          dataIndex: 'tentruong',
          key: 'tentruong'
        },
        {
          title: 'Địa chỉ',
          dataIndex: 'diachi',
          key: 'diachi'
        },
        {
          title: 'Thông tin',
          dataIndex: 'thongtin',
          key: 'thongtin'
        }
      ];

    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isConfirmModalOpen, setIsConfirmModalOpen] = useState(false);
    const [matruong, setMatruong] = useState('');
    const [tentruong, setTentruong] = useState('');
    const [diachi, setDiachi] = useState('');
    const [thongtin, setThongtin] = useState('');
    const handleConfirmOk = (item: string) => {
        fetch(process.env.REACT_APP_API_URL+'api/University/' + item, {
            method: 'DELETE',
            headers: {
                'Authorization': `Bearer ${token}`,
            },
    }).then(response => {
        setData(data.filter(c=>c.matruong!=item));
    })
    .catch(err => console.log(err));
    }
    const handleConfirmCancel = () => {
      setIsConfirmModalOpen(false);
    }
    const handleOk = () => {
        setIsModalOpen(false);
      };
      const handleCancel = () => {
        setIsModalOpen(false);
      };
      const showModal = () => {
        setIsModalOpen(true);
      };
    const [dataSearch, setDataSearch] = useState<UniversityType[]>([]);
    const [data, setData] = useState<UniversityType[]>([]);
    const getAllUniveristy = () => {
        fetch(process.env.REACT_APP_API_URL+'api/University/', {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`,
            },
    }).then(response => response.json())
    .then((data)=>{      
        setData(data);
        setDataSearch(data);            
    })
    .catch(err => console.log(err));
    }
    useEffect(()=>{
        getAllUniveristy();
    },[]) 
    const onFinish = async (values: any) => {
      const token = localStorage.getItem('token');
        fetch(process.env.REACT_APP_API_URL+'api/University/', {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`,
            },
            body: JSON.stringify({matruong: matruong, tentruong: tentruong,
                diachi:diachi, thongtin:thongtin
            })
    }).then(response => {
        message.info("Thêm mới thành công");
        getAllUniveristy();
    })
    .catch(err => console.log(err));
      };
    return(
    <>
    <Button onClick={showModal}
    disabled = {localStorage.getItem('emailData') != 'test@alta.com.vn' ? 
    false : true } 
    >Thêm trường</Button>&nbsp;&nbsp;
    <a href="#" onClick={()=> {
          const excel = new Excel();
          excel.addSheet("Universities").addColumns(columnsExcel).addDataSource(data, {
        str2Percent: true
      })
      .saveAs("Universities.xlsx");
          return false;
        }}>Xuất Excel</a>
    <Search placeholder={"Nhập tên cơ sở đào tạo hoặc địa chỉ để tìm kiếm"} enterButton="Search" 
    style={{ maxWidth: '600px', margin: '0 0 10px 10px', padding: '0px', backgroundColor: '#f0f2f5', borderRadius: '8px' }}
    size="large"
    value={searchValue}
      onChange={(e)=>{
          setSearchValue(e.currentTarget.value);
          setDataSearch(data.filter(x=>x.tentruong.toLowerCase().includes(e.currentTarget.value.toLowerCase())
          || x.diachi.toLowerCase().includes(e.currentTarget.value.toLowerCase())));
      }} />
     <Table columns={columns} dataSource={dataSearch} />
    <Modal title="Thêm thành viên" open={isModalOpen} onOk={handleOk} onCancel={handleCancel}>
        <Form labelCol={{ span: 4 }}
        wrapperCol={{ span: 20 }}
        layout="horizontal"
        onFinish={onFinish} >
            <Form.Item label="Mã trường:" 
        name="matruong"
        rules={[{ required: true, message: 'Không được để trống!' }]}>
          <Input value={matruong}
          onChange={(e)=>{
              setMatruong(e.currentTarget.value);
          }
        } />
        </Form.Item>
        <Form.Item label="Tên trường:" 
        name="tentruong"
        rules={[{ required: true, message: 'Không được để trống!' }]}>
          <Input value={tentruong}
          onChange={(e)=>{
              setTentruong(e.currentTarget.value);
          }
        } />
        </Form.Item>
        <Form.Item label="Diachi:" 
        name="diachi"
        rules={[{ required: true, message: 'Không được để trống!' }]}>
          <Input value={diachi}
          onChange={(e)=>{
              setDiachi(e.currentTarget.value);
          }
        } />
        </Form.Item>
        <Form.Item label="Thông tin khác:" 
        name="thongtin"
        rules={[{ required: true, message: 'Không được để trống!' }]}>
          <Input value={thongtin}
          onChange={(e)=>{
              setThongtin(e.currentTarget.value);
          }
        } />
        </Form.Item>
        <Form.Item label="" wrapperCol={{offset:4}}>
          <Button type="primary" htmlType="submit">Lưu dữ liệu</Button>
        </Form.Item>
        </Form>
    </Modal>
    <Modal title="Xóa dữ liệu" open={isConfirmModalOpen} onOk={()=>handleConfirmOk(deleteItem)} onCancel={handleConfirmCancel}>
        <h3 style={{color:'#FF0000'}}>Bạn có chắc chắn xóa trường {deleteItem} này khỏi hệ thống?</h3>
    </Modal>
    </>
    )
}

export default University;