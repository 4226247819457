import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import { Dispatch } from 'redux';
import {InternType} from '../../interface';
import axios from 'axios';

export const internSlice = createSlice({
    name:'intern',
    initialState:{} as InternType,
    reducers: ({
        getIntern: (state, action: PayloadAction<InternType>) => {
            return action.payload;
        }
    })
})
export function getIntern(){
    return async function getInternThunk(dispatch: Dispatch, getState:any){
        const token = localStorage.getItem('token');
    const rawResponse = await fetch(process.env.REACT_APP_API_URL+'api/Register/getintern/'
    + localStorage.getItem('emailData'), {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`,
            },
    }).then(response => response.json())
    .then((data)=>{
        //console.log(data);
        let newdata:InternType = {
            key: data.id,
      id: data.id,
      internName: data.internName,
      internMail:data.internMail,
      dateOfBirth:data.dateOfBirth,
      university:data.university,
      fileUrl:data.fileUrl,
      internSpecialized:data.internSpecialized,
      telephoneNum: data.telephoneNum,
      internable: data.internable==1?'Thực tập':'Chính thức',
      fullTime: data.fullTime==1?'Toàn thời gian':'Bán thời gian',
      major: data.major,
      citizenIdentification: '',
      registeredDate:data.registeredDate,
      citizenIdentificationDate: '',
      isEditableInternSpecialized:false,
      internStatus: data.internStatus,
      yearOfExperiences: data.yearOfExperiences,
      foreignLanguage: data.foreignLanguage,
      entranceTest:data.entranceTest
        };
            dispatch(internSlice.actions.getIntern(newdata))
        console.log(getState());       
    })
    .catch(err => console.log(err));
    }
}
