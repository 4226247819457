import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import { Dispatch } from 'redux';
import {message} from 'antd';
import { useAppDispatch } from '../../configs/hook';
import getState from 'redux-thunk';
import {Question} from '../../interface';
import { stat } from 'fs';


export const questionSlice = createSlice({
    name:'questions',
    initialState:[] as Question[],
    reducers: ({
        getQuestions: (state, action: PayloadAction<Question[]>) => {
            state = [];
            return action.payload;
        },
        addQuestion: (state, action:PayloadAction<Question>) =>{
            return [...state, action.payload]
        }
    })
})
export const questionCountSlice = createSlice({
    name:'questionCount',
    initialState: 0,
    reducers:{
        countQuestions: (state, action: PayloadAction<number>) => {
            return action.payload;
        }
    }
})
export function getQuestionsByType(id: number, rowsPerPage:number, pageNumber: number){
    return async function getQuestionsByTypeThunk(dispatch: Dispatch, getState:any){
        const token = localStorage.getItem('token');
        const rawResponse = await fetch(process.env.REACT_APP_API_URL+'api/Cauhoi/'
        +id+'/'+rowsPerPage+'/'+pageNumber + '/' + localStorage.getItem('emailData'), {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`,
            },
      //body: JSON.stringify(answerRequest)
    }).then(response => {if(response.status==401){
        message.info("Phiên đăng nhập đã hết hạn!");
        localStorage.clear();
        window.location.reload();
    }
    return response.json()})
    .then((data)=>{
        console.log(data);
            dispatch(
                questionSlice.actions.getQuestions(data)
              )
        //console.log(data);       
    })
    .catch(err => console.log(err));
    }
}
export function addQuestion(question:Question){
    return async function addQuestionThunk(dispatch:Dispatch, getState:any) {
        dispatch(questionSlice.actions.addQuestion(question));
    }
}
export function countQuestion(x:number){
    return async function countQuestionThunk(dispatch: Dispatch, getState:any){
        const token = localStorage.getItem('token');
        const rawResponse = await fetch(process.env.REACT_APP_API_URL+'api/Cauhoi/'+x+'/count', {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`,
            },
      //body: JSON.stringify(answerRequest)
    }).then(response => response.json())
    .then((data)=>{
            dispatch(
                questionCountSlice.actions.countQuestions(data.results))
        //console.log(data);       
    })
    .catch(err => console.log(err));
    }
}
