import React, {useState, useEffect} from "react";
import { Modal, Form, Input, DatePicker, Button, notification } from "antd";
import {getDateStringWithTime} from '../../helpers';
import './style.css';
const DisplayEvents = () => {
    const [width, setWidth] = useState<number>(window.innerWidth);
    const [api, contextHolder] = notification.useNotification();
    const [data, setData] = useState<any[]>([]);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [eventId, setEventId] = useState(0);
    const getAllEvents = () => {
        fetch(process.env.REACT_APP_API_URL+'api/Events/1/10', {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            },
    }).then(response => response.json())
    .then((data)=>{
        console.log(data);      
        setData(data);            
    })
    .catch(err => console.log(err));
    }
    function handleWindowSizeChange() {
      setWidth(window.innerWidth);
  }
  useEffect(() => {
      window.addEventListener('resize', handleWindowSizeChange);
      return () => {
          window.removeEventListener('resize', handleWindowSizeChange);
      }
  }, []);
    const onFinish = async (values: any) => {
        const token = localStorage.getItem('token');
          fetch(process.env.REACT_APP_API_URL+'api/RegisterEvent/', {
              method: 'POST',
              headers: {
                  'Accept': 'application/json',
                  'Content-Type': 'application/json',
              },
              body: JSON.stringify({attenderName: values.AttenderName, telephoneNumber: values.TelephoneNumber,
                dayOfBirth:values.DayOfBirth, university:values.University, eventId: eventId, emailAddress:values.EmailAddress
              })
      }).then(response => response.json())
      .then(data => {
        if(data == "Added" )
          api.info(
            {
              key:1,
              message: "Đăng ký thành công",
              description: "Bạn vui lòng kiểm tra mail để xem thông tin chi tiết",
              placement:'top'
            }
          )
        else
        api.info(
          {
            key:1,
            message: "Đăng ký thất bại",
            description: "Lỗi phát sinh có thể do email hoặc số điện thoại đã được sử dụng",
            placement:'top'
          }
        )
        setIsModalOpen(false);
        getAllEvents();
        }
      )
      .catch(err => console.log(err));
        };

    useEffect(()=>{
        getAllEvents();
    },[])
    const handleOk = () => {
        setIsModalOpen(false);
      };
      const handleCancel = () => {
        setIsModalOpen(false);
      };
      const showModal = () => {
        setIsModalOpen(true);
      };
    const isMobile = width <= 768;
    return(
        <>
        {contextHolder}
        {data.length>0?
        <div>
        <div className='item'>
          <div>
          <p style={{fontSize:'26px', fontWeight:'bold',flexBasis:'100%'
        }}>{data[0].eventName}</p>
          <p style={{fontSize:'16px', fontWeight:'bold',flexBasis:'100%'
        }}>Thời gian: {getDateStringWithTime(new Date(data[0].eventStart))}</p>
        <p style={{fontSize:'16px', fontWeight:'bold',flexBasis:'100%'
        }}>Địa điểm: {data[0].eventLocation}</p>
          <Button type='primary'
          onClick={()=>{setEventId(data[0].id);
            setIsModalOpen(true);
        }}
          >Đăng ký</Button>
          <Button type="primary" onClick={()=>{
                  fetch(process.env.REACT_APP_API_URL+'api/Events/files/' + data[0].id + '.pdf', {
                      method: 'GET',
                      headers: {
                      },
                })
              .then(response => response.blob())
          .then(blob => {
              var url = window.URL.createObjectURL(blob);
              var a = document.createElement('a');
              a.href = url;
              a.download = data[0].id + '.pdf';
              document.body.appendChild(a); // we need to append the element to the dom -> otherwise it will not work in firefox
              a.click();    
              a.remove();
          })
                }}>Chi tiết</Button>
              </div>
          </div>
        </div>
        :''}
    <Modal title="Đăng ký tham gia" open={isModalOpen} onOk={handleOk} onCancel={handleCancel}>
        <Form labelCol={{ span: 12 }}
        wrapperCol={{ span: 20 }}
        layout="vertical"
        onFinish={onFinish} >
            <Form.Item label="Họ và tên:" 
        name="AttenderName"
        rules={[{ required: true, message: 'Không được để trống!' }]}>
          <Input />
        </Form.Item>
        <Form.Item label="Số điện thoại:" 
        name="TelephoneNumber"
        rules={[{ required: true, message: 'Không được để trống!' }]}>
          <Input />
        </Form.Item>
        <Form.Item label="Email:" 
        name="EmailAddress"
        rules={[{ required: true, message: 'Không được để trống!' }]}>
          <Input />
        </Form.Item>
        <Form.Item label="Ngày sinh:" 
        name="DayOfBirth"
        rules={[{ required: true, message: 'Không được để trống!' }]}>
          <DatePicker />
        </Form.Item>
        <Form.Item label="Trường đang theo học:" 
        name="University"
        rules={[{ required: true, message: 'Không được để trống!' }]}>
          <Input />
        </Form.Item>
        <Form.Item label="">
          <Button type="primary" htmlType="submit">Lưu dữ liệu</Button>
        </Form.Item>
        </Form>
    </Modal>
        </>
    )
}
export default DisplayEvents;