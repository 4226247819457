import React,{useState} from 'react';
import OneQuestion from '../../components/questions/singleResponse';
import StudentLayoutHeader from '../../components/Header/studentLayoutHeader';
import Footer from '../../components/Footer';
const PublicTest = ()=>{
  const [receiveItem, setReceiveItem] = useState(1);
  const handleReceiveItem = (item:number)=>{
    setReceiveItem(item);
  }
    return(
    <div>
      <StudentLayoutHeader handleSelectItem={handleReceiveItem} />
      <OneQuestion />
      <Footer />
    </div>
    )
}
export default PublicTest;