import React, {useEffect, useState} from 'react';
import Header from '../../components/Header';
import SearchFilters from '../../components/SearchSection';
import JobList from '../../components/JobListing';
import Pagination from '../../components/Pagination';
import Footer from '../../components/Footer';
import JobSearchHeader from '../../components/JobSearchHeader';
import Banner from '../../components/Banner';
import PersonalRegister from '../../components/Registers/personalRegister';
import EnterpriseRegister from '../../components/Registers/enterpriseRegister';
const SearchJobs: React.FC = () => {
    const [componentIndex, setComponentIndex] = useState(1);
    const [selectedLanguage, setSelectedLanguage] = useState('Vie');
    const [numberOfPage, setNumberOfPage] = useState(0);
    const [searchText, setSearchText] = useState('___');
    const [taxCode, setTaxCode] = useState('0')
    const [location, setLocation] = useState("0");
    const [pageNumber, setPageNumber] = useState(1);
    const [subjects, setSubjects] = useState<any[]>([]);
  const getAllSubjects = async () => {
    const rawResponse = await fetch(process.env.REACT_APP_API_URL+'api/Subject/'
    + pageNumber + '/6/' + searchText + '/' + taxCode + '/' + location, {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            },
    }).then(response => response.json())
    .then((data)=>{
          console.log(data);
          setSubjects(data.map((item:any)=>{
                return {title: item.monhoc.title, company: item.companyName, requirements: '',
                    email: item.contactEmail, phone: item.telephone, logo:item.logoImage, id:item.monhoc.id
                }
          }));
    })
    .catch(err => console.log(err));
    const rawResponse1 = await fetch(process.env.REACT_APP_API_URL+'api/Subject/count/' 
    + searchText + '/' + taxCode + '/' + location, {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            },
    }).then(response => response.json())
    .then((data)=>{
          setNumberOfPage(data);
    })
    .catch(err => console.log(err));
  }
  const handleReceiveSearchText = (searchTextData:string) => {
        setSearchText(searchTextData);
  }
  const handleReceiveTaxCode = (taxCode:string) => {
    setTaxCode(taxCode);
    }
    const handleReceiveLocation = (location:string) => {
        setLocation(location);
    }
    const handleReceivePageNumber = (pageNumber: number) => {
        setPageNumber(pageNumber);
    }
    const handleReceiveComponentIndex = (componentIndex:number) => {
        setComponentIndex(componentIndex);
    }
    const receiveLanguage = (lange:string)=>{
        setSelectedLanguage(lange);
    }
useEffect(()=>{
    getAllSubjects();
},[])
useEffect(()=>{
    getAllSubjects();
},[searchText, pageNumber, taxCode, location]);
    return (
        <div>
            <Header handleSelectItem={handleReceiveComponentIndex} 
            handleSelectingLanguage={receiveLanguage}
            />
            <div style={{backgroundColor: '#f0f2f5'}}>
            {componentIndex==1?<div>
            <SearchFilters handleSearchText={handleReceiveSearchText} 
                handleCompanyChange={handleReceiveTaxCode}
                handleProvinceChange={handleReceiveLocation}
                language={selectedLanguage}
            />
            <JobSearchHeader lange={selectedLanguage} />
            <JobList jobs={subjects} />
            <Pagination currentPage={pageNumber} totalPages={numberOfPage} onPageChange={handleReceivePageNumber} />
            </div>:componentIndex==2?
            <div>
                <PersonalRegister language={selectedLanguage} />
            </div>:
            <div>
                <EnterpriseRegister language={selectedLanguage} />
            </div>
            }
            </div>
            <Footer />
        </div>
    );
}
export default SearchJobs;
