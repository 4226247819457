import React, {useEffect, useState} from 'react';
import {Button} from 'antd';
import './style.css';
import { display } from 'html2canvas/dist/types/css/property-descriptors/display';
import ReactHtmlParser from 'react-html-parser';
import { printResultToPDF } from '../../helpers';
const CvTemplate = (props:any) => {
    const [options, setOptions] = useState([
        {id: 1, optionStyle: {backgroundColor: 'darkblue', color:'white'}, selected: true},
        {id: 2, optionStyle: {backgroundColor: 'pink', color:'blue'}, selected: false},
        {id: 3, optionStyle: {backgroundColor: 'darkgreen', color:'white'}, selected: false},
        {id: 4, optionStyle: {backgroundColor: 'purple', color:'white'}, selected: false}
    ])
    useEffect(()=>{
        console.log(props);
    },[])
    return(
        <div className='ParentContainer'>
        <div className='container' id='myCV'>
            <div className='leftContainer'>
                <div className='backgroundHeader' style={options.filter(x=>x.selected==true)[0].optionStyle}>
                    {props.data.internName}
                </div>
                <div className='avatar'>
                    <img src={props.data.internImageUrl} style={{width:'60%', objectFit:'contain', borderRadius:'100%'}} />
                </div>
                <div className='personInfo'>
                    <h3>Thông tin cá nhân:</h3>
                    <div className='details'>
                    <img src='fullName.png' className='iconCV' />
                    <div className='itemContent'>Họ và tên: {props.data.internName}</div>
                    </div>
                    <div className='details'>
                    <img src='address.png' className='iconCV' />
                    <div className='itemContent'>Địa chỉ: {props.data.internAddress}</div>
                    </div>
                    <div className='details'>
                    <img src='telephone.png' className='iconCV' />
                    <div className='itemContent'>Điện thoại: {props.data.telephoneNum}</div>
                    </div>
                    <div className='details'>
                    <img src='email.png' className='iconCV' />
                    <div className='itemContent'>Email: {props.data.internMailReplace}</div>
                    </div>
                </div>
                <div className='personMajor'>
                    <h3>Thông tin chung về chuyên môn:</h3>
                    <div className='details'>
                    <img src='university.png' className='iconCV' />
                    <div className='itemContent'>Trường đào tạo: {props.data.university}</div>
                    </div>
                    <div className='details'>
                    <img src='university.png' className='iconCV' />
                    <div className='itemContent'>Chuyên ngành: {props.data.major}</div>
                    </div>
                    <div className='details'>
                    <img src='language.png' className='iconCV' />
                    <div className='itemContent'>Ngoại ngữ: {props.data.foreignLanguage}</div>
                    </div>
                </div>
            </div>
            <div className='rightContainer'>
                <h3 style={{marginLeft:'20px', paddingLeft:'0px'}}>Lời giới thiệu:</h3>
                <p style={{marginLeft:'20px', fontSize:'18px', textAlign:'justify'}}>{props.data.introduction}
                </p>
                <hr style={{border:'2px solid black', width:'100%'}} />
                <h3 style={{marginLeft:'20px', marginTop:'15px', paddingLeft:'0px'}}>Kinh nghiệm làm việc:</h3>
                    {props.data.internHistories.filter((x:any)=>x.eventType==1).map((item:any)=>{
                        return(
                            <div>
                            <div className='eventDetail' style={{fontSize:'18px', fontWeight:600}}>
                        <div className='eventName'>{item.eventName}</div>
                        <div className='periodTime'>{item.eventDate} - {item.endDate}</div>
                    </div>
                        <div className='contentWithHtml'>
                            {ReactHtmlParser(item.eventDetail)}
                        </div>
                        </div>
                        )
                    })}
                <hr style={{border:'2px solid black', width:'100%'}} />
                <h3 style={{marginLeft:'20px', marginTop:'15px', paddingLeft:'0px'}}>Quá trình đào tạo:</h3>
                {props.data.internHistories.filter((x:any)=>x.eventType==2).map((item:any)=>{
                        return(
                            <div>
                            <div className='eventDetail' style={{fontSize:'18px', fontWeight:600}}>
                        <div className='eventName'>{item.eventName}</div>
                        <div className='periodTime'>{item.eventDate} - {item.endDate}</div>
                    </div>
                        <div className='contentWithHtml'>
                            {ReactHtmlParser(item.eventDetail)}
                        </div>
                        </div>
                        )
                    })}
                {props.data.internHistories.filter((x:any)=>x.eventType==3).length!=0?
                <div>
                <hr style={{border:'2px solid black', width:'100%'}} /> 
                <h3 style={{marginLeft:'20px', marginTop:'15px', paddingLeft:'0px'}}>Các dự án đã tham gia:</h3></div>:null}
                {props.data.internHistories.filter((x:any)=>x.eventType==3).map((item:any)=>{
                        return(
                            <div>
                            <div className='eventDetail' style={{fontSize:'18px', fontWeight:600}}>
                        <div className='eventName'>{item.eventName}</div>
                        <div className='periodTime'>{item.eventDate} - {item.endDate}</div>
                    </div>
                        <div className='contentWithHtml'>
                            {ReactHtmlParser(item.eventDetail)}
                        </div>
                        </div>
                        )
                    })}
            </div>
        </div>
        <div className='options'>
            {options.map((item)=>{
                let myStyle = item.selected ? {...item.optionStyle, border:'5px solid red'}: item.optionStyle;
                return(
                    <div key={item.id} className='option-1' style={myStyle}
                        onClick={()=>{
                            let index = options.findIndex(x=>x.id == item.id);
                            console.log(index);
                            if(index != -1)
                            {
                                options[index].selected = true;
                            let previous = options.slice(0, index);
                            let newPrevious = previous.map(row=>{
                                return {...row, selected: false}
                            })
                            let afters = options.slice(index+1);
                            let newAfters = afters.map(row=>{
                                return {...row, selected: false}
                            })
                            setOptions([...newPrevious, options[index], ...newAfters])
                            }
                        }}
                    >
                    </div>
                )
            }
            )}
            <Button onClick={()=>{
                printResultToPDF('myCV','a4');
            }}
            style={{marginTop:'5px'}}
            ><img src='pdfButton.jpg' style={{width:'80px', height:'80px'}} /></Button>
        </div>
        </div>
    )
}
export default CvTemplate;