import React from 'react';
import { Button, Form, Input, message } from 'antd';
import { validateEmail } from '../../helpers/validator';

const DeleteAccount = () => {
    const onFinish = (values: any) => {
        
      };
      const onFinishFailed = (errorInfo: any) => {
        console.log('Failed:', errorInfo);
      };
  return(
    <>
    <div className='RootContainerRegister'>
      <div className='RegisterHeader'>
          <div className='TopLeftContainerRegister'>
                <img src='AltaGroup.svg' style={{width:'52px', height:'52px'}} />
                <img src='Plastic.svg' style={{width:'91px', height:'52px', marginLeft:'5px'}} />
                <img src='Media.svg' style={{width:'66px', height:'52px', marginLeft:'5px'}} />
                <img src='Software.svg' style={{width:'52px', height:'52px', marginLeft:'5px'}} />
                <img src='Unigon.svg' style={{width:'52px', height:'52px', marginLeft:'5px'}} />
          </div>
      </div>
      <div className='ContentArea' style={{paddingTop: '50px'}}>
    <h3 style={{margin:'0px 0px 30px 526px'}}>Yêu cầu xóa tài khoản - Khai báo thông tin</h3>
      <Form
      name="basic"
      labelCol={{ span: 8 }}
      wrapperCol={{ span: 8 }}
      initialValues={{ remember: true }}
      onFinish={onFinish}
      onFinishFailed={onFinishFailed}
      autoComplete="off"
    >
      <Form.Item
        label="Facebook Id:"
        name="id"
        style={{fontWeight:600, fontFamily: 'Roboto',
    fontSize: '20px'}}
        rules={[{ required: true, message: 'Please input your facebook Id!' }]}
      >
        <Input className='LoginTopInside1Input' style={{width:'400px'}} />
      </Form.Item>
      <Form.Item
        label="Địa chỉ email:"
        name="EmailTo"
        style={{fontWeight:600, fontFamily: 'Roboto',
    fontSize: '20px'}}
        rules={[{ required: true, message: 'Please input your email address!'},
        {validator:validateEmail}
      ]}
      >
        <Input className='LoginTopInside1Input' style={{width:'500px'}} />
      </Form.Item>
      <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
      <Button style={{ backgroundColor: 'transparent', border: 'none', paddingLeft:'0px', paddingTop:'0px' }} 
          htmlType="submit"><img src='Register.png' /></Button>
      </Form.Item>
    </Form>
    <p style={{margin:'0px 0px 30px 526px', fontWeight:'bold'}}>User guide of deleting your account in the recruitment system</p>
    <p style={{margin:'0px 0px 30px 526px'}}><span style={{fontWeight:'bold'}}>Step 1:&nbsp;&nbsp;</span>
    Declare your right information about your facebook id and your email as above
    </p>
    <p style={{margin:'0px 0px 30px 526px'}}><span style={{fontWeight:'bold'}}>Step 2:&nbsp;&nbsp;</span>
    Check your email and click the link we sent you in the email to finish deleting your account on our system
    </p>
      </div>
      <div className='FooterRegister'>
          @Copyright Alta Software - Training Team 04/2023
      </div>
    </div>
    </>
  );
}
export default DeleteAccount;