import { Form, Button } from 'antd';
import React, {useState} from 'react';
import fs from 'fs';
export const ConnectionConfig = () => {
    const [username, setUsername] = useState("");
    const [password, setPassword] = useState("");
    const [editable, setEditabel] = useState(false);
    const [apiserver, setApiserver] = useState("");
    return(
        <div>
        {!editable?
        <Form
        onFinish={(values)=>{
            if(values.username == 'system' && values.password == 'system@alta'){
                setEditabel(true);
            }
        }}  
        >
        <table>
            <tr>
                <td>Tên đăng nhập:</td>
                <td><input type="text" name="username"
                onChange={(e)=>setUsername(e.currentTarget.value)}
                value={username} /></td>
            </tr>
            <tr>
                <td>Mật khẩu:</td>
                <td><input type="password" name="password"
                onChange={(e)=>setPassword(e.currentTarget.value)}
                value={password} /></td>
            </tr>
            <tr>
                <td></td>
                <td><Button type="primary" 
                onClick={()=>{
                    if(username == 'system' && password == 'system@alta'){
                        setEditabel(true);
                    } 
                }}
                htmlType="submit">
                        Đăng nhập
                </Button></td>
            </tr>
        </table>
        </Form>
        : 
        <Form>
        <table>
            <tr>
                <td>Máy chủ api:</td>
                <td><input type="text" name="apiserver"
                onChange={(e)=>setApiserver(e.currentTarget.value)}
                value={apiserver} /></td>
            </tr>
            <tr>
                <td></td>
                <td><Button type="primary"
                onClick={async ()=>{
                    const res = fetch("/api/api.json")
                    .then(response=>response.json())
                    .then(data=>{
                        console.log(data.server);
                    })     
                    //setEditabel(false);
            }}
                >
                        Lưu thông tin
                </Button></td>
            </tr>
        </table>
        </Form>
        }
        </div>
    )
}