import React, {useState, useEffect} from 'react';
import { Form, Input, Button, Checkbox, Select, message } from 'antd';
import './styles.css';
import { accountService } from '../../_services';
import { useAppDispatch } from '../../configs/hook';
import {getLoginInfo} from '../../components/login/loginSlice';
import CustomDropdown from '../customDropdown';
import { HubConnection, HubConnectionState } from '@microsoft/signalr';
const signalR = require('@microsoft/signalr');
const { Option } = Select;
type LoginProps = {
  message?: string,
  reRenderApp: ()=>void
}
const LoginContent = (props:LoginProps) => {
  const [connection, setConnection] = useState<any>(null);
  const dispatch = useAppDispatch();
  const onFinish = async (values: any) => {
    localStorage.setItem("password", values.password);
    localStorage.setItem("role", values.role);
    const rawResponse = await fetch(process.env.REACT_APP_API_URL+'api/token', {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        "email": values.username,
        "password": values.password,
        "role": values.role
      })
    });
    const data = await rawResponse.json();
    console.log(data)
    if(data!="Invalid credentials"){
        localStorage.setItem("emailData", values.username);
        localStorage.setItem("token", data.token);
        localStorage.setItem('tokenRefresh', data.tokenRefresh);
        localStorage.setItem("userNameData", data.userCode);
        localStorage.setItem("userFullName", data.userFullName);
        localStorage.setItem("passwordStatus", data.passwordStatus);
        localStorage.setItem("avatar", data.avatar??'');
        localStorage.setItem("taxCode", data.taxCode);
        localStorage.setItem("userRole", data.userRole)
        await dispatch(getLoginInfo({data: data.details}));
        localStorage.setItem("roleData", values.role);
        localStorage.setItem("malophocphan", data.details!=null?data.details[0].malophocphan:'Test');
        if (connection.state == HubConnectionState.Connected) {
          await connection.invoke("UserConnected", values.username);
        }
        //window.location.reload();
        props.reRenderApp();
    }
    else
    {
        message.info('Nhập sai thông tin hoặc tài khoản chưa được kích hoat', 10);
    }
  };
  const options = [
    { label: 'Sinh viên', value: '1' },
    { label: 'Giảng viên', value: '2' },
    { label: 'Quản trị', value: '3' },
    { label: 'Khách', value: '4' },
    { label: 'Doanh nghiệp', value: '5' }
];
  const onFinishFailed = (errorInfo: any) => {
    console.log('Failed:', errorInfo);
  };
  useEffect(() => {
    const newConnection = new signalR.HubConnectionBuilder()
      .withUrl("https://localhost:7251/userActivityHub", {
        skipNegotiation: true, // Ensure this if you only want to use WebSockets
        transport: signalR.HttpTransportType.WebSockets
      })
      .withAutomaticReconnect()
      .configureLogging(signalR.LogLevel.Information)
      .build();
    setConnection(newConnection);
  }, []);

  useEffect(() => {
    if (connection) {
      connection.start()
        .then(() => {
          console.log('Connected to SignalR hub');
        })
        .catch((err:any) => {
          console.error('Error connecting to SignalR hub:', err);
        });
    }
  }, [connection]);

  return (
    <div className="login-container">
      <div className="login-form">
        <h2>Đăng nhập</h2>
        <Form
          name="basic"
          initialValues={{ role: '1' }}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          layout="vertical"
        >
          <Form.Item
            label='Vai trò'
            name="role"
            rules={[{ required: true, message: 'Vui lòng chọn vai trò!' }]}
          >
            <CustomDropdown options={options} />
          </Form.Item>
          <Form.Item
            label='Email'
            name="username"
            rules={[{ required: true, message: 'Vui lòng nhập email!' }]}
          >
            <Input style={{border:'none'}} placeholder='Tên đăng nhập' />
          </Form.Item>

          <Form.Item
            label='Mật khẩu'
            name="password"
            rules={[{ required: true, message: 'Vui lòng nhập mật khẩu!'}]}
          >
            <Input.Password style={{border:'none'}} placeholder='Nhập mật khẩu' />
          </Form.Item>
          <a href="/resetpass">Quên mật khẩu - Reset mật khẩu</a>&nbsp;&nbsp;&nbsp;
          <a onClick={accountService.login}>Đăng nhập với Facebook</a><br /><br />
          <Form.Item name="remember" valuePropName="checked" noStyle>
            <Checkbox>Ghi nhớ mật khẩu</Checkbox>
          </Form.Item>
          <Form.Item>
            <Button type="primary" htmlType="submit" style={{ width: '100%', marginTop:'20px' }}>
              Đăng nhập
            </Button>
          </Form.Item>
        </Form>
      </div>
      <div className="illustration">
        <img src="images/loginWelcome.png" alt="Illustration" style={{ width: '100%' }} />
      </div>
    </div>
  );
};

export default LoginContent;
