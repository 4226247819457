// JobCard.tsx
import React from 'react';
import './jobList.css';

interface JobCardProps {
  title: string;
  company: string;
  requirements: string;
  email: string;
  phone: string;
  logo: string;
  id: number;
}

const JobCard: React.FC<JobCardProps> = ({ title, company, requirements, email, phone, logo, id }) => {
  return (
    <div className="job-card">
        <div className="job-card-header">
            <div className='job-card-header-left'>
                <img src={logo} className='companyLogo' alt="Company Logo" width="40" height="40" />
                <div style={{marginLeft:'10px'}}>
                    <div className="job-card-title">{title}</div>
                    <div className="job-card-company">{company}</div>
                </div>
            </div>
            <img src="images/download.png" className='companyLogo'
            onClick={()=>{
                        fetch(process.env.REACT_APP_API_URL+'api/Subject/files/' + id, {
                          method: 'GET',
                          headers: {
                          },
                  })
                  .then(response => response.blob())
                  .then(blob => {
                      var url = window.URL.createObjectURL(blob);
                        const a = document.createElement('a');
                        a.href = url;
                        a.target = '_blank';
                        a.style.display = 'none';
                        document.body.appendChild(a);
                        a.click();
                        document.body.removeChild(a);
                        setTimeout(() => {
                          window.URL.revokeObjectURL(url);
                      }, 2000);
                  }); 
                    }} />
        </div>
      <div className="job-card-requirements">{requirements}</div>
      <hr style={{backgroundColor:'black', margin:'0px 0px 10px 0px'}} />
      <div className="job-card-footer">
        <div className="job-card-contact">
          <img src="images/Mail.png" alt="Email" className='companyLogo' width="16" height="16" />
          <a href={`mailto:${email}`}>{email}</a> <span style={{marginLeft: '10px'}}>|</span>
          <img src="images/phone.png" alt="Phone" className='companyLogo' width="16" height="16" style={{ marginLeft: '16px' }} />
          <span>{phone}</span>
        </div>
      </div>
    </div>
  );
};

export default JobCard;
