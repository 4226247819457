import React, { useState, ChangeEvent } from 'react';
import { Question } from '../../interface';
import { PlusOutlined } from '@ant-design/icons';
import FormItem from 'antd/es/form/FormItem';
import axios from 'axios';
import {
  Form,
  Input,
  Button,
  Upload,
  message
} from 'antd';

const { TextArea } = Input;

const UpdateQuestion = (props: Question) => {
    const [fileList, setFileList] = useState<File>();
    const [myQuestion, setMyQuestion] = useState({
        QuestionsId: props.QuestionsId,
        Phatbieu: props.Phatbieu,
        Hinhanh:props.Hinhanh,
        Nhieuluachon: props.Nhieuluachon,
        Loaicauhoi: props.Loaicauhoi,
        ImageDataUrl: props.ImageDataUrl,
        Choices: props.Choices
    })
    const onFinish = async (values: any) => {
        const token = localStorage.getItem('token');
        const formData = new FormData();
          if(fileList)
          {
            formData.append("MyFile", fileList);
            const config = {     
              headers: { 'content-type': 'multipart/form-data', 'Authorization': `Bearer ${token}` }
            }
            axios.put(process.env.REACT_APP_API_URL+'api/Cauhoi/'+ props.QuestionsId, formData, config)
              .then(response => {
                    message.info("Cập nhật thành công");
                    if(props.UpdateEvent != undefined)
                        props.UpdateEvent(true);
              })
              .catch(error => {
                  console.log(error);
              });       
          };
        }
  return (
    <>
      <Form onFinish={onFinish}
        labelCol={{ span: 4 }}
        wrapperCol={{ span: 14 }}
        layout="horizontal"
      >
        <Form.Item label="Input">
          <Input value={props.QuestionsId} disabled />
        </Form.Item>
        <Form.Item label="Phát biểu">
          <TextArea rows={4} value={props.Phatbieu} disabled />
        </Form.Item>
        <FormItem name="fileList">
        <Input type="file" onChange={(e:ChangeEvent<HTMLInputElement>)=>{
          const temp = e.target.files;
          if(!temp) 
            return;
          else
            setFileList(temp[0])}} />
        </FormItem>
        <Form.Item label="">
          <Button type="primary" htmlType="submit">Lưu dữ liệu</Button>
        </Form.Item>
      </Form>
    </>
  );
};

export default UpdateQuestion;