import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import { Dispatch } from 'redux';
import {message} from 'antd';
import { useAppDispatch } from '../../configs/hook';
import {Course} from '../../interface';
import getState from 'redux-thunk';
import { stat } from 'fs';

type AssignMent = {
    lecturerId: string,
    courseId: string
}
export const studentSlice = createSlice({
    name: 'student',
    initialState: [] as Course[],
    reducers: {
        getStudent: (state:Course[], action:PayloadAction<Course[]>) => {
          return action.payload;
        },
        addCourse: (state:Course[], action: PayloadAction<Course>) => {
            return [action.payload, ...state] 
        },
        updateCourse:(state:Course[], action: PayloadAction<any>)=>{
            let index = state.findIndex(c=>c.malophocphan == action.payload.malophocphan);
            let current = {...state[index], tenlophocphan: action.payload.tenlophocphan, thoiluong: action.payload.thoiluong,
                startDate: action.payload.startDate,
                endDate: action.payload.endDate
            }
            let previous = state.slice(0,index); 
            let next = state.slice(index+1);
            return [...previous, current, ...next]
        },
        assignCourse:(state:Course[], action: PayloadAction<AssignMent>) => {
            let index = state.findIndex(c=>c.malophocphan == action.payload.courseId);
            state[index].magiangvien = action.payload.lecturerId;
            return state;
        }       
    }
})
export function getStudent(lecturerCode: string){
    return async function getStudentThunk(dispatch: Dispatch, getState: any){
        const token = localStorage.getItem('token');
        const rawResponse = await fetch(process.env.REACT_APP_API_URL+'api/Course/'+lecturerCode, {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`,
            },
    }).then(response => {
        if(response.status==401){
            message.info("Phiên đăng nhập đã hết hạn!");
            localStorage.clear();
            window.location.reload();
        }
        return response.json()
    })
    .then((data)=>{
            dispatch(
                studentSlice.actions.getStudent(data)
              )
        console.log(data);        
    })
    .catch(err => console.log(err));
    }
}
export function importCourse(courseCode: string){
    return async function importCourseThunk(dispatch: Dispatch, getState: any){
        const token = localStorage.getItem('token');
        const rawResponse = await fetch(process.env.REACT_APP_API_URL+'api/Course/students/'+courseCode, {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`,
            },
    }).then(response => response.json())
    .then((data)=>{
            dispatch(
                studentSlice.actions.addCourse(data)
            )
        console.log(data);       
    })
    .catch(err => console.log(err));
    }
}

export function updateCourse(myUpdate: any){
    return async function updateCourseThunk(dispatch: Dispatch, getState: any){
        const token = localStorage.getItem('token');
        const rawResponse = await fetch(process.env.REACT_APP_API_URL+'api/Course/'+myUpdate.malophocphan, {
            method: 'PUT',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`,
            },
        body: JSON.stringify(myUpdate)
    }).then(response => response.json())
    .then((data)=>{
        dispatch(studentSlice.actions.updateCourse(myUpdate))
        console.log(getState());
    })
    .catch(err => console.log(err));
    }
}
export function assignCourse(myAssignment: AssignMent){
    return async function assignCourseThunk(dispatch:Dispatch, getState:any) {
        const token = localStorage.getItem('token');
        const rawResponse = await fetch(process.env.REACT_APP_API_URL+'api/Lecturer/Assign', {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`,
            },
        body: JSON.stringify(myAssignment)
    }).then(response => response.json())
    .then((data)=>{
        dispatch(studentSlice.actions.assignCourse(myAssignment))
        console.log(getState());
    })
    .catch(err => console.log(err));
    }
}