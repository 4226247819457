import React from 'react';
import './styles.css';

const LoginHeader = () => {
  return (
    <div className="header-container">
      <div className="logos">
        <img src="images/logo.png" />
      </div>
      <div className="text-container">
        HỆ THỐNG TUYỂN DỤNG<br />
        VÀ QUẢN LÝ SINH VIÊN THỰC TẬP
      </div>
    </div>
  );
};
export default LoginHeader;
