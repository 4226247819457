import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import { Dispatch } from 'redux';
import {UniversityType} from '../../interface';
import axios from 'axios';

export const universitySlice = createSlice({
    name:'university',
    initialState:[] as UniversityType[],
    reducers: ({
        getUniversities: (state, action: PayloadAction<UniversityType[]>) => {
            return action.payload;
        }
    })
})
export function getAllUniversities(){
    return async function getUniversityThunk(dispatch: Dispatch, getState:any){
        const token = localStorage.getItem('token');
    const rawResponse = await fetch(process.env.REACT_APP_API_URL+'api/University', {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`,
            },
    }).then(response => response.json())
    .then((data)=>{
            dispatch(universitySlice.actions.getUniversities(data))
    })
    .catch(err => console.log(err));
    }
}
