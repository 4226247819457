import React from 'react';
import dayjs from 'dayjs'
import type { Dayjs } from 'dayjs';
// @ts-ignore
import html2pdf from 'html2pdf.js';
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
export const getCurrentWeek = (values?:Dayjs):Date[] => {
    let curDate = values != undefined ? values : dayjs();
  let n=curDate.toDate().getDay();
  let myDateOfWeek:Date[] = [];
  switch(n){
    case 0:
      myDateOfWeek.push(
        curDate.add(1,'day').toDate()
      );
      myDateOfWeek.push(
        curDate.add(2,'day').toDate()
      );
      myDateOfWeek.push(
        curDate.add(3,'day').toDate()
      );
      myDateOfWeek.push(
        curDate.add(4,'day').toDate()
      );
      myDateOfWeek.push(
        curDate.add(5,'day').toDate()
      );
      myDateOfWeek.push(
        curDate.add(6,'day').toDate()
      )
      break;
    case 1:
      myDateOfWeek.push(
        curDate.add(0,'day').toDate()
      );
      myDateOfWeek.push(
        curDate.add(1,'day').toDate()
      );
      myDateOfWeek.push(
        curDate.add(2,'day').toDate()
      );
      myDateOfWeek.push(
        curDate.add(3,'day').toDate()
      );
      myDateOfWeek.push(
        curDate.add(4,'day').toDate()
      );
      myDateOfWeek.push(
        curDate.add(5,'day').toDate()
      );
      break;
    case 2:
      myDateOfWeek.push(
        curDate.add(-1,'day').toDate()
      );
      myDateOfWeek.push(
        curDate.add(0,'day').toDate()
      );
      myDateOfWeek.push(
        curDate.add(1,'day').toDate()
      );
      myDateOfWeek.push(
        curDate.add(2,'day').toDate()
      );
      myDateOfWeek.push(
        curDate.add(3,'day').toDate()
      );
      myDateOfWeek.push(
        curDate.add(4,'day').toDate()
      );
      break;
    case 3:
      myDateOfWeek.push(
        curDate.add(-2,'day').toDate()
      );
      myDateOfWeek.push(
        curDate.add(-1,'day').toDate()
      );
      myDateOfWeek.push(
        curDate.add(0,'day').toDate()
      );
      myDateOfWeek.push(
        curDate.add(1,'day').toDate()
      );
      myDateOfWeek.push(
        curDate.add(2,'day').toDate()
      );
      myDateOfWeek.push(
        curDate.add(3,'day').toDate()
      );
      break;
    case 4:
      myDateOfWeek.push(
        curDate.add(-3,'day').toDate()
      );
      myDateOfWeek.push(
        curDate.add(-2,'day').toDate()
      );
      myDateOfWeek.push(
        curDate.add(-1,'day').toDate()
      );
      myDateOfWeek.push(
        curDate.add(0,'day').toDate()
      );
      myDateOfWeek.push(
        curDate.add(1,'day').toDate()
      );
      myDateOfWeek.push(
        curDate.add(2,'day').toDate()
      );      
      break;
    case 5:
      myDateOfWeek.push(
        curDate.add(-4,'day').toDate()
      );
      myDateOfWeek.push(
        curDate.add(-3,'day').toDate()
      );
      myDateOfWeek.push(
        curDate.add(-2,'day').toDate()
      );
      myDateOfWeek.push(
        curDate.add(-1,'day').toDate()
      );
      myDateOfWeek.push(
        curDate.add(0,'day').toDate()
      );
      myDateOfWeek.push(
        curDate.add(1,'day').toDate()
      );     
      break;
    default:
      myDateOfWeek.push(
        curDate.add(-5,'day').toDate()
      );
      myDateOfWeek.push(
        curDate.add(-4,'day').toDate()
      );
      myDateOfWeek.push(
        curDate.add(-3,'day').toDate()
      );
      myDateOfWeek.push(
        curDate.add(-2,'day').toDate()
      );
      myDateOfWeek.push(
        curDate.add(-1,'day').toDate()
      );
      myDateOfWeek.push(
        curDate.add(0,'day').toDate()
      );  
      break;
  }
  return myDateOfWeek;
}
export const getDateString = (value:Date):string =>{
  let myOutput = value.getFullYear() + '-'
  + (value.getMonth()+1<10?'0'+ (value.getMonth()+1)
  :value.getMonth()+1)+'-'
  + (value.getDate()<10?'0'+value.getDate()
  :value.getDate());
  return myOutput;
}
export const getDateStringWithTime = (value:Date):string =>{
  let myOutput = value.getFullYear() + '-'
  + (value.getMonth()+1<10?'0'+ (value.getMonth()+1)
  :value.getMonth()+1)+'-'
  + (value.getDate()<10?'0'+value.getDate()
  :value.getDate()) + ' ' + value.getHours() + ':' 
  + (value.getMinutes()<10?'0'+value.getMinutes():value.getMinutes())
  return myOutput;
}
export function printResultToPDF(componentId:string, paperSize:string){
  const input = document.getElementById(componentId);   
  if(input!=null){
    var doc = new jsPDF('p', 'mm', paperSize);
    var pageHeight = doc.internal.pageSize.height - 40;
    var pageWidth = doc.internal.pageSize.width;
    html2canvas(input, {
      scale: 2,
      scrollX: 0,
      scrollY: 0,
      width: input.scrollWidth,  // Ensure entire width is captured
      height: input.scrollHeight // Ensure entire height is captured
    }).then(function(canvas) {
        var imgData = canvas.toDataURL('image/png');
        var imgWidth = pageWidth; // A4 width in mm
        var imgHeight = canvas.height * imgWidth / canvas.width;
        var heightLeft = imgHeight;
        var position = 0;
        var pageCanvas = document.createElement('canvas');
        var pageCtx = pageCanvas.getContext('2d');
        
        pageCanvas.width = canvas.width;
        pageCanvas.height = (canvas.width * pageHeight / imgWidth);

        while (heightLeft > 0) {
            pageCtx?.clearRect(0, 0, pageCanvas.width, pageCanvas.height);
            pageCtx?.drawImage(canvas, 0, position, canvas.width, pageCanvas.height, 0, 0, pageCanvas.width, pageCanvas.height);
            var pageImgData = pageCanvas.toDataURL('image/png');
            doc.addImage(pageImgData, 'PNG', 10, 20, imgWidth, pageHeight);
            heightLeft -= pageHeight;
            position += pageCanvas.height;
            if (heightLeft > 0) {
                doc.addPage();
            }
        }
        doc.save(localStorage.getItem('userFullName') + '.pdf');
    });
  }
 }
