import React, { ChangeEvent, useEffect, useState } from 'react';
import { Collapse, Table, Input, Checkbox,
   Modal, Button, message } from 'antd';
import { useAppDispatch } from '../../configs/hook';
import type { PaginationProps } from 'antd';
import { UploadOutlined } from '@ant-design/icons';
import { Pagination } from 'antd';
import { useSelector } from 'react-redux';
import { RootState } from '../../redux/store';
import {getTestResult} from './testResultSlice';
import { Excel } from "antd-table-saveas-excel";
import { ColumnsType } from 'antd/es/table';
import { Diemdanhs, StudentResultTest } from '../../interface';
import {testResultSlice} from './testResultSlice';
import './styles.css';

const { Panel } = Collapse;

const TestResults: React.FC = () => {
  const [courseName, setCourseName] = useState("");
  const myTestResults = useSelector((state:RootState)=>state.testResults);
  const dispatch = useAppDispatch();
  const [emailData, setEmailData] = useState(localStorage.getItem('emailData'));
  const [valueEdit, setValueEdit] = useState<number>(0);
  const [pageSize, setPageSize] = useState(10); // State to manage page size
  const [currentPage, setCurrentPage] = useState(1); // State to manage current page
  const [malophocphan, setMalophocphan] = useState('');
  const [masosinhvien, setMasosinhvien] = useState('');
  const [ngayhoc, setNgayhoc] = useState('2000-01-01');
  const [tomtat, setTomtat] = useState('');
  const [trangthai, setTrangthai] = useState(0);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isModalDeleteOpen, setIsModalDeleteOpen] = useState(false);
  const handlePageChange = (page:number, pageSize:number) => {
    setCurrentPage(page);
  };

  const handlePageSizeChange = (current:number, size:number) => {
    setPageSize(size);
  };
  const handleOk = () => {
    const mydata = {
      Masinhvien: masosinhvien,
      Ngayhoc: ngayhoc,
      Cahoc: 1,
      Malophocphan: malophocphan,
      Trangthai: trangthai,
      Tomtat: tomtat
    }
    const token = localStorage.getItem('token');
      fetch(process.env.REACT_APP_API_URL + "api/Diemdanh", {
        method: 'PUT', // or 'PUT' depending on your requirement
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
        },
        body: JSON.stringify(mydata)
      })
      .then(response => {
        if(response.status==401){
          message.info("Phiên đăng nhập đã hết hạn!");
          localStorage.clear();
          window.location.reload();
      }
      return response.json()
      })
      .then(data => {
        message.info("Cập nhật thành công");
        dispatch(getTestResult('admin'))
      })
      .catch(error => {
        console.error('Error:', error);
      });
    setIsModalOpen(false);
  };

  const handleOkDelete = () => {
    const mydata = {
      Masinhvien: masosinhvien,
      Ngayhoc: ngayhoc,
      Cahoc: 1,
      Malophocphan: malophocphan,
      Trangthai: trangthai,
      Tomtat: tomtat
    }
    const token = localStorage.getItem('token');
      fetch(process.env.REACT_APP_API_URL + "api/Diemdanh", {
        method: 'DELETE', // or 'PUT' depending on your requirement
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
        },
        body: JSON.stringify(mydata)
      })
      .then(response => {
        if(response.status==401){
          message.info("Phiên đăng nhập đã hết hạn!");
          localStorage.clear();
          window.location.reload();
      }
      return response.json()
      })
      .then(data => {
        message.info("Xóa thành công");
        dispatch(getTestResult('admin'))
      })
      .catch(error => {
        console.error('Error:', error);
      });
    setIsModalDeleteOpen(false);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const handleDeleteCancel = () => {
    setIsModalDeleteOpen(false);
  };
  const columns: ColumnsType<StudentResultTest> = [
    {
      title: 'Mã số sinh viên',
      dataIndex: 'masinhvien',
      key: 'masinhvien',
    },
    {
      title: 'Họ và tên',
      dataIndex: 'hovaten',
      key: 'hovaten',
    },
    {
      title: 'Ngày sinh',
      dataIndex: 'ngaysinh',
      key: 'ngaysinh',
    },
    {
      title: 'Email',
      dataIndex: 'email',
      key: 'email',
    },
    {
      title: 'Điểm chuyên cần',
      dataIndex: 'diemchuyencan',
      key: 'diemchuyencan',
      render:(ke:any, record:StudentResultTest, index:number)=>{
        if(!record.isDiemchuyencanEditable)
        return(
          <a onClick={()=>{
            setValueEdit(record.diemchuyencan==null?0:record.diemchuyencan);
            dispatch(testResultSlice.actions.enableEditable({...record, isDiemchuyencanEditable: true}))
          }}>{record.diemchuyencan==null?"Chưa có điểm":record.diemchuyencan}</a>
        )
        else
        {
          //setValueEdit(record.diemchuyencan==null?0:record.diemchuyencan);
          return(
            <input type="number" style={{width:'60px'}}
            value={valueEdit} onChange={(e)=>setValueEdit(Number(e.currentTarget.value))}
            onKeyUp={(e)=>{
              if(e.key=='Enter'){
                const token = localStorage.getItem('token');
                fetch(process.env.REACT_APP_API_URL+'api/TestResult/'
                +record.masinhvien + '/' + record.malophocphan, {
            method: 'PUT',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`,
            },
            body: JSON.stringify({diemchuyencan: valueEdit, 
            diemgiuaky:record.diemgiuaky, diemcuoiky: record.diemcuoiky})
    }).then(response => {
      if(response.status==401){
        message.info("Phiên đăng nhập đã hết hạn!");
        localStorage.clear();
        window.location.reload();
    }
    return response.json()
    })
    .then((data)=>{
      dispatch(testResultSlice.actions.enableEditable({...record, isDiemchuyencanEditable: false,
      diemchuyencan: valueEdit
      }))
    })
    .catch(err => console.log(err));
              }
            }}
            />
          )
        }
        
      }
    },
    {
      title: 'Điểm giữa kỳ',
      dataIndex: 'diemgiuaky',
      key: 'diemgiuaky',
      render:(key:any, record:StudentResultTest, index:number)=>{
        if(!record.isDiemgiuakyEditable)
        return(
          <a onClick={()=>{
            setValueEdit(record.diemgiuaky==null?0:record.diemgiuaky);
            dispatch(testResultSlice.actions.enableEditable({...record, isDiemgiuakyEditable: true}))
          }}>{record.diemgiuaky==null?"Chưa có điểm":record.diemgiuaky}</a>
        )
        else
        {
          return(
            <Input type="number" style={{width:'60px'}}
            value={valueEdit} onChange={(e)=>setValueEdit(Number(e.currentTarget.value))}
            onKeyUp={(e)=>{
              if(e.key=='Enter'){
                const token = localStorage.getItem('token');
                const rawResponse = fetch(process.env.REACT_APP_API_URL+'api/TestResult/'
                +record.masinhvien + '/' + record.malophocphan, {
            method: 'PUT',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`,
            },
            body: JSON.stringify({diemchuyencan: record.diemchuyencan, 
            diemgiuaky:valueEdit, diemcuoiky: record.diemcuoiky})
    }).then(response => {
      if(response.status==401){
        message.info("Phiên đăng nhập đã hết hạn!");
        localStorage.clear();
        window.location.reload();
    }
    return response.json()
    })
    .then((data)=>{
      dispatch(testResultSlice.actions.enableEditable({...record, isDiemgiuakyEditable: false,
      diemgiuaky: valueEdit
      }))
    })
    .catch(err => console.log(err));
              }
            }}
            />
          )
        }
      }
    },
    {
      title: 'Điểm cuối kỳ',
      dataIndex: 'diemcuoiky',
      key: 'diemcuoiky',
      render:(key:any, record:StudentResultTest, index:number)=>{
        if(!record.isDiemcuoikyEditable)
        return(
          <a onClick={()=>{
            setValueEdit(record.diemcuoiky==null?0:record.diemcuoiky);
            dispatch(testResultSlice.actions.enableEditable({...record, isDiemcuoikyEditable: true}))
          }}>{record.diemcuoiky==null?"Chưa có điểm":record.diemcuoiky}</a>
        )
        else
        {
          return(
            <Input type="number" style={{width:'60px'}}
            value={valueEdit} onChange={(e)=>setValueEdit(Number(e.currentTarget.value))}
            onKeyUp={(e)=>{
              if(e.key=='Enter'){
                const token = localStorage.getItem('token');
                const rawResponse = fetch(process.env.REACT_APP_API_URL+'api/TestResult/'
                +record.masinhvien + '/' + record.malophocphan, {
            method: 'PUT',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`,
            },
            body: JSON.stringify({diemchuyencan: record.diemchuyencan, 
            diemgiuaky:record.diemgiuaky, diemcuoiky: valueEdit})
    }).then(response => {
      if(response.status==401){
        message.info("Phiên đăng nhập đã hết hạn!");
        localStorage.clear();
        window.location.reload();
    }
    return response.json()
    })
    .then((data)=>{
      dispatch(testResultSlice.actions.enableEditable({...record, isDiemcuoikyEditable: false,
      diemcuoiky: valueEdit
      }))
    })
    .catch(err => console.log(err));
              }
            }}
            />
          )
        }
      }
    }
  ];

  const columnsExport = [
    {
      title: 'Mã số sinh viên',
      dataIndex: 'masinhvien',
      key: 'masinhvien',
    },
    {
      title: 'Họ và tên',
      dataIndex: 'hovaten',
      key: 'hovaten',
    },
    {
      title: 'Ngày sinh',
      dataIndex: 'ngaysinh',
      key: 'ngaysinh',
    },
    {
      title: 'Email',
      dataIndex: 'email',
      key: 'email',
    },
    {
      title: 'Điểm chuyên cần',
      dataIndex: 'diemchuyencan',
      key: 'diemchuyencan'
    },
    {
      title: 'Điểm giữa kỳ',
      dataIndex: 'diemgiuaky',
      key: 'diemgiuaky'
    },
    {
      title: 'Điểm cuối kỳ',
      dataIndex: 'diemcuoiky',
      key: 'diemcuoiky'
    }
  ];
  const onChange = (key: string | string[]) => {
    console.log(key);
  };
  const expandedRowRender = (record:StudentResultTest) => {
    let myDiemdanh = myTestResults.filter(x=>x.malophocphan == record.malophocphan)[0]
    .studentResultTests.filter(k=>k.masinhvien == record.masinhvien)[0].diemdanhs;
    const columns: ColumnsType<any> = [
      { title: 'Stt', dataIndex: 'stt', key: 'stt',
        render: (key:any, record:any, index:number) => {
            return(
              <p>{index+1}</p>
            )
        }
      },
      { title: 'Ngày báo cáo', dataIndex: 'ngayhoc', key: 'ngayhoc'},
      { title: 'Nội dung báo cáo/Lý do không báo cáo', dataIndex: 'tomtat', key: 'tomtat'},
      { title: 'Link báo cáo', dataIndex: 'filebailam', key:'filebailam'},
      { title: 'Ghi chú', dataIndex: 'ghichu', key:'ghichu'},
      {
        title: 'Có xin phép',
        dataIndex: 'trangthai',
        key: 'trangthai',
        render: (text, record1, index) => {
          return (
            <>
              <Checkbox
                checked={record1.trangthai!=2?false:true}
                onChange={(e) => {
                    setTrangthai(e.target.checked?2:0);
                    setMalophocphan(record.malophocphan);
                    setMasosinhvien(record.masinhvien);
                    setNgayhoc(record1.ngayhoc);
                    setIsModalOpen(true);
                }}
              />
            </>
          )
        }
      },
      {
        title: 'Hành động',
        dataIndex: 'action',
        key: 'action',
        render: (text, record1, index) => {
          return (
            <>
              <a
                onClick={() => {
                    setMalophocphan(record.malophocphan);
                    setMasosinhvien(record.masinhvien);
                    setNgayhoc(record1.ngayhoc);
                    setIsModalDeleteOpen(true);
                }}
              >Xóa</a>
            </>
          )
        }
      }        
    ];
    return <Table columns={columns} dataSource={myDiemdanh} 
      rowClassName={(record:Diemdanhs)=>{
        let currentDate = new Date();
        currentDate.setHours(0,0,0,0);
      if(new Date(record.ngayhoc)<currentDate 
      && (record.trangthai == null || record.trangthai==0))
        return "row-notreport";
      else if(new Date(record.ngayhoc)<currentDate 
      && (record.trangthai == 2))
          return "row-withpermission";
      else
        return ""
    }} />
  }
  return (
    <>
    <Collapse onChange={onChange}>
    {myTestResults.map((item, index)=>{
        return(
        <Panel header={<>{item.tenlophocphan}&nbsp;&nbsp;<a href="#" onClick={()=> {
          const excel = new Excel();
          excel.addSheet("TestResult").addColumns(columnsExport).addDataSource(item.studentResultTests, {
        str2Percent: true
      })
      .saveAs("Result.xlsx");
          return false;
        }}>Xuất Excel</a></>} key={index}>
            <Table dataSource={item.studentResultTests} columns={columns} style={{overflowX:'scroll'}}
            expandable={{ expandedRowRender, defaultExpandedRowKeys: ['0'] }}
        // Use pagination prop to customize pagination
        pagination={{
          current: currentPage,
          pageSize: pageSize,
          total: item.studentResultTests.length,
          showSizeChanger: true, // Show rows per page selector
          onChange: handlePageChange,
          onShowSizeChange: handlePageSizeChange,
          showTotal: (total, range) => `${range[0]}-${range[1]} of ${total} items`,
          pageSizeOptions: ['10', '20', '50', '100'] // Customizable options for rows per page
        }}
            />
        </Panel>
        )
    })}
    </Collapse>
    <Modal title="Lý do không báo cáo" open={isModalOpen} onOk={handleOk} onCancel={handleCancel}>
        <Input value={tomtat} onChange={(e)=>{
          setTomtat(e.currentTarget.value);
        }} />
    </Modal>
    <Modal title="Xác nhận xóa ngày báo cáo" open={isModalDeleteOpen} onOk={handleOkDelete} onCancel={handleDeleteCancel}>
    <h3 style={{color:'#FF0000'}}>Bạn có chắc chắn xóa ngày báo cáo này khỏi hệ thống?</h3>
    </Modal>
    </>
  );
};
export default TestResults;