import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import { Dispatch } from 'redux';
import {message} from 'antd';
import { useAppDispatch } from '../../configs/hook';
import getState from 'redux-thunk';
import {MailPattern} from '../../interface';
import axios from 'axios';
import { stat } from 'fs';


export const MailPatternSlice = createSlice({
    name:'mailPattern',
    initialState:{} as MailPattern[] ,
    reducers: ({
        getMailPattern: (state, action: PayloadAction<MailPattern[]>) => {
            console.log(action.payload);
            return action.payload;
        },
    })
})
export function getMailPattern(){
    return async function getMailPatternThunk(dispatch: Dispatch, getState:any){
        const token = localStorage.getItem('token');
        const rawResponse = await fetch(process.env.REACT_APP_API_URL+'api/Tintuc/', {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`,
            },
    }).then(response => {
        if(response.status==401){
            message.info("Phiên đăng nhập đã hết hạn!");
            localStorage.clear();
            window.location.reload();
        }
        return response.json()
    })
    .then((data)=>{
            dispatch(
                MailPatternSlice.actions.getMailPattern(data)
              )
        console.log(data);    
    })
    .catch(err => console.log(err));
    }
}