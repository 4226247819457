import React, { useEffect, useState, ChangeEvent } from 'react';
import { Space, Table, Tag, Button, Modal, Form, Input, message } from 'antd';
import type { ColumnsType } from 'antd/es/table';
import { Excel } from "antd-table-saveas-excel";
import axios from 'axios';
const {Search} = Input
type SubjectType = {
    id: number;
    tenmonhoc: string;
    abbreation: string;
    isEditable: boolean;
}
const Subject: React.FC = () => {
    const [searchValue, setSearchValue] = useState('');
    const token = localStorage.getItem('token');
    const [deleteItem, setDeleteItem] = useState(0); 
    const [abbreation, setAbbreation] = useState('');
    const [fileList, setFileList] = useState<File>();
    const columns: ColumnsType<SubjectType> = [
        {
          title: 'Id',
          dataIndex: 'id',
          key: 'id',
        },
        {
          title: 'Lĩnh vực chuyên môn',
          dataIndex: 'tenmonhoc',
          key: 'tenmonhoc',
          render: (key, record:SubjectType, text)=>{
            return(
              <>
              { localStorage.getItem('emailData') != 'test@alta.com.vn' ? 
              record.isEditable?<input type="text" value={record.tenmonhoc} 
              onChange={(e)=>{
                let currentIndex = data.findIndex(c=>c.id==record.id);
                if(currentIndex!=-1){
                  let previous = data.slice(0,currentIndex);
                  let after = data.slice(currentIndex+1);
                  data[currentIndex].tenmonhoc = e.currentTarget.value;
                  setData([...previous, data[currentIndex], ...after]);
                }
              }}

              onKeyPress={(e)=>{
                if(e.key == "Enter"){
                  let index = data.findIndex(c=>c.id==record.id);
                //let myRecord = data.find(c=>c.matruong==record.matruong);
                fetch(process.env.REACT_APP_API_URL+'api/Subject/'+record.id, {
                    method: 'PUT',
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${token}`,
                    },
                    body: JSON.stringify({id: data[index].id, tenmonhoc: data[index].tenmonhoc,
                        abbreation:data[index].abbreation, taxCode:'admin@alta.com.vn',
                        editedUser: localStorage.getItem("emailData")
                    })
            }).then(response => {
              if(response.status==401){
                message.info("Phiên đăng nhập đã hết hạn!");
                localStorage.clear();
                window.location.reload();
            }
                message.info("Cập nhật thành công");
                getAllUniveristy();
            })
            .catch(err => console.log(err));

                }
              }}

              />
              : <a onClick={()=>{
                let currentIndex = data.findIndex(c=>c.id==record.id);
                if(currentIndex!=-1){
                  let previous = data.slice(0,currentIndex);
                  let after = data.slice(currentIndex+1);
                  data[currentIndex].isEditable = true;
                  setData([...previous, data[currentIndex], ...after]);
                }
              }}>{record.tenmonhoc}</a>
              :<p>{record.tenmonhoc}</p>
            }
            </>
            )
          }
        },
        {
          title: 'Từ viết tắt',
          dataIndex: 'abbreation',
          key: 'abbreation',
          render: (key, record:SubjectType, text)=>{
            return(
              <>
              {
              localStorage.getItem('emailData') != 'test@alta.com.vn' ? 
              record.isEditable?<input type="text" value={record.abbreation} 
              onChange={(e)=>{
                let currentIndex = data.findIndex(c=>c.id==record.id);
                if(currentIndex!=-1){
                  let previous = data.slice(0,currentIndex);
                  let after = data.slice(currentIndex+1);
                  data[currentIndex].abbreation = e.currentTarget.value;
                  setData([...previous, data[currentIndex], ...after]);
                }
              }}

              onKeyPress={(e)=>{
                if(e.key == "Enter"){
                  let index = data.findIndex(c=>c.id==record.id);
                  console.log(localStorage.getItem("emailData"));
                //let myRecord = data.find(c=>c.matruong==record.matruong);
                fetch(process.env.REACT_APP_API_URL+'api/Subject/'+record.id, {
                    method: 'PUT',
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${token}`,
                    },
                    body: JSON.stringify({id: data[index].id, tenmonhoc: data[index].tenmonhoc,
                        abbreation:data[index].abbreation, taxCode:'admin@alta.com.vn',
                        editedUser: localStorage.getItem("emailData")
                    })
            }).then(response => {
                console.log(response);
                message.info("Cập nhật thành công");
                getAllUniveristy();
            })
            .catch(err => console.log(err));

                }
              }}

              />
              : <a onClick={()=>{
                let currentIndex = data.findIndex(c=>c.id==record.id);
                if(currentIndex!=-1){
                  let previous = data.slice(0,currentIndex);
                  let after = data.slice(currentIndex+1);
                  data[currentIndex].isEditable = true;
                  setData([...previous, data[currentIndex], ...after]);
                }
              }}>{record.abbreation}</a>
            : <p>{record.abbreation}</p>
            }
            </>
            )
          }
        },
        {
          title: 'Hành động',
          dataIndex: 'delete',
          key: 'delete',
          render: (key, record:SubjectType, text) => {
            return(
            <Button type="primary" danger
            disabled = {localStorage.getItem('emailData') != 'test@alta.com.vn' ? 
            false : true }
            onClick = {()=>{
              setIsConfirmModalOpen(true);
              setDeleteItem(record.id);
            }}
            >Xóa</Button>
            ) 
          }
        },
        {
          title: 'Upload JD',
          dataIndex: 'upload',
          key: 'upload',
          render: (key, record:SubjectType, text) => {
            return(
            <Button type="primary"
            disabled = {localStorage.getItem('emailData') != 'test@alta.com.vn' ? 
            false : true } 
            onClick = {()=>{
              setIsModalUploadOpen(true);
              setDeleteItem(record.id);
            }}
            >Upload</Button>
            ) 
          }
        }
      ];
      const columnsExcel = [
        {
          title: 'Từ viết tắt',
          dataIndex: 'abbreation',
          key: 'abbreation'
        },
        {
          title: 'Lĩnh vực chuyên môn',
          dataIndex: 'tenmonhoc',
          key: 'tenmonhoc'
        }
      ];
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isModalUploadOpen, setIsModalUploadOpen] = useState(false);
    const [isConfirmModalOpen, setIsConfirmModalOpen] = useState(false);
    const [tenmonhoc, setTenmonhoc] = useState('');
    const handleConfirmOk = (item: number) => {
        fetch(process.env.REACT_APP_API_URL+'api/Subject/' + item, {
            method: 'DELETE',
            headers: {
                'Authorization': `Bearer ${token}`,
            },
    }).then(response => {
        setData(data.filter(c=>c.id!=Number(item)));
    })
    .catch(err => console.log(err));
    }
    const handleConfirmCancel = () => {
      setIsConfirmModalOpen(false);
    }
    const handleOk = () => {
        setIsModalOpen(false);
      };
      const handleUploadOk = () => {
        setIsModalUploadOpen(false);
      };
      const handleCancel = () => {
        setIsModalOpen(false);
      };
      const handleUploadCancel = () => {
        setIsModalUploadOpen(false);
      };
      const showModal = () => {
        setIsModalOpen(true);
      };

    const [data, setData] = useState<SubjectType[]>([]);
    const [dataSearch, setDataSearch] = useState<SubjectType[]>([]);
    const getAllUniveristy = () => {
        fetch(process.env.REACT_APP_API_URL+'api/Subject/', {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`,
            },
    }).then(response => response.json())
    .then((data)=>{      
        setData(
          data.map((item:any)=>{
            return(
              {id:item.id, tenmonhoc: item.tenmonhoc, abbreation:item.abbreation, isEditable: false}
            )
          })
        );
        setDataSearch(
          data.map((item:any)=>{
            return(
              {id:item.id, tenmonhoc: item.tenmonhoc, abbreation:item.abbreation, isEditable: false}
            )
          })
        );        
    })
    .catch(err => console.log(err));
    }
    useEffect(()=>{
        getAllUniveristy();
    },[]) 
    const onFinish = async (values: any) => {
      const token = localStorage.getItem('token');
        fetch(process.env.REACT_APP_API_URL+'api/Subject/', {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`,
            },
            body: JSON.stringify({tenmonhoc: tenmonhoc, abbreation: abbreation,
            taxCode: 'admin@alta.com.vn', requirement: '', createdUser:localStorage.getItem("emailData"),
            editedUser:localStorage.getItem("emailData")
            })
            })
    .then(response => {
        message.info("Thêm mới thành công");
        getAllUniveristy();
    })
    .catch(err => console.log(err));
      };
    return(
    <>
    <Button onClick={showModal}
    disabled = {localStorage.getItem('emailData') != 'test@alta.com.vn' ? 
    false : true }
    >Thêm lĩnh vực chuyên môn</Button>
    <a href="#" onClick={()=> {
          const excel = new Excel();
          excel.addSheet("Subjects").addColumns(columnsExcel).addDataSource(data, {
        str2Percent: true
      })
      .saveAs("Subjects.xlsx");
          return false;
        }}>&nbsp;&nbsp;Xuất Excel</a>
    <Search placeholder={"Nhập tên cơ sở đào tạo hoặc địa chỉ để tìm kiếm"} enterButton="Search" 
    style={{ maxWidth: '600px', margin: '0 0 10px 10px', padding: '0px', backgroundColor: '#f0f2f5', borderRadius: '8px' }}
    size="large"
    value={searchValue}
      onChange={(e)=>{
          setSearchValue(e.currentTarget.value);
          setDataSearch(data.filter(x=>x.tenmonhoc.toLowerCase().includes(e.currentTarget.value.toLowerCase())
          ));
      }} />
     <Table columns={columns} dataSource={dataSearch} />
    <Modal title="Thêm lĩnh vực chuyên môn" open={isModalOpen} onOk={handleOk} onCancel={handleCancel}>
        <Form labelCol={{ span: 4 }}
        wrapperCol={{ span: 20 }}
        layout="horizontal"
        onFinish={onFinish} >          
        <Form.Item label="Tên lĩnh vực:" 
        name="tenmonhoc"
        rules={[{ required: true, message: 'Khum được để trống nha!' }]}>
          <Input value={tenmonhoc}
          onChange={(e)=>{
              setTenmonhoc(e.currentTarget.value);
          }
        } />
        </Form.Item>
        <Form.Item label="Từ viết tắt:" 
        name="abbreation"
        rules={[{ required: true, message: 'Khum được để trống nhen!' }]}>
          <Input value={abbreation}
          onChange={(e)=>{
              setAbbreation(e.currentTarget.value);
          }
        } />
        </Form.Item>      
        <Form.Item label="" wrapperCol={{offset:4}}>
          <Button type="primary" htmlType="submit">Lưu dữ liệu</Button>
        </Form.Item>
        </Form>
    </Modal>
    <Modal title="Xóa dữ liệu" open={isConfirmModalOpen} onOk={()=>handleConfirmOk(deleteItem)} onCancel={handleConfirmCancel}>
        <h3 style={{color:'#FF0000'}}>Bạn có chắc chắn xóa chuyên môn {deleteItem} này khỏi hệ thống?</h3>
    </Modal>
    <Modal title="Upload JD" open={isModalUploadOpen} onCancel={handleUploadCancel}
      onOk={()=>{
        const token = localStorage.getItem('token');
        const formData = new FormData();
        if(fileList)
        {
            formData.append("Id", deleteItem.toString());
            formData.append("MyFile", fileList);
        }           
    const config = {     
      headers: { 'content-type': 'multipart/form-data', 'Authorization': `Bearer ${token}` }
    }
     axios.post(process.env.REACT_APP_API_URL+'api/Subject/uploadjd', formData, config)
      .then(response => {
        console.log(response);
        message.info(response.data)
      })
      .catch(error => {
          console.log(error);
      });
    setIsModalUploadOpen(false);      
  }} >
        Chọn file: <Input type="file" onChange={(e:ChangeEvent<HTMLInputElement>)=>{
  const temp = e.target.files;
  if(!temp) 
    return;
  else
    setFileList(temp[0])}} />
      </Modal>
    </>
    )
}

export default Subject;