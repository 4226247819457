// Navbar.tsx
import React, {useState} from 'react';
import { Menu, Dropdown, Button } from 'antd';
import { GlobalOutlined, DownOutlined } from '@ant-design/icons';
import { translate } from '../../helpers/translate';
import './header.css';
interface HeaderProps {
  handleSelectItem:(itemIndex:number) => void;
  handleSelectingLanguage: (lange: string) => void;
}
const Header = (props:HeaderProps) => {
  const [selectedItem, setSelectedItem] = useState(1);
  const [selectedLanguage, setSelectedLanguage] = useState<string>('Vie');
  const [menuOpen, setMenuOpen] = useState(false);
  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };
  const handleMenuClick = (e: any) => {
    // Update the selected language when a menu item is clicked
    if (e.key === '1') {
      setSelectedLanguage('Vie');
      props.handleSelectingLanguage('Vie');
    } else if (e.key === '2') {
      setSelectedLanguage('EN');
      props.handleSelectingLanguage('EN');
    }
    // Add more conditions for other languages if needed
  };
  const menu = (
    <Menu onClick={handleMenuClick}>
      <Menu.Item key="1">
      Vie - Vietnamese
      </Menu.Item>
      <Menu.Item key="2">
        EN - English
      </Menu.Item>
    </Menu>
  ); 
  return (
    <div className='navContainer'>
    <div className="navbar">
      <div className="logo-section">
        <img src="images/logo.png" alt="Logo 5" />
      </div>
      <div className="menu-icon">
            <img src="images/menu.png" style={{width:'52px', height:'52px'}} onClick={toggleMenu}
            alt="Menu Icon"/>
      </div>
    </div>
      <div className={menuOpen==true?'link-section.show':'link-section'}>
        <div className='menuItem'>
        <img style={{paddingLeft:'0px'}} src={selectedItem==1?"images/viewJdActive.png":"images/viewJd.png"} />&nbsp;&nbsp;<a style={selectedItem==1?{color:'#ff5a00'}:undefined} 
        onClick={()=>{props.handleSelectItem(1)
          setSelectedItem(1);  
      }}
        >{translate(selectedLanguage,'Xem JD yêu cầu tuyển dụng')}</a>
        </div>
        <div className='menuItem'>
        <img style={{paddingLeft:'0px'}} src={selectedItem==2?"images/onlineRegisterActive.png":"images/onlineRegister.png"} />&nbsp;&nbsp;<a style={selectedItem==2?{color:'#ff5a00'}:undefined}
        onClick={()=>{props.handleSelectItem(2)
          setSelectedItem(2);
        }}>{translate(selectedLanguage,'Cá nhân đăng ký')}</a>
        </div>
        <div className='menuItem'>
        <img style={{paddingLeft:'0px'}} src={selectedItem==3?"images/onlineRegisterActive.png":"images/onlineRegister.png"} />&nbsp;&nbsp;<a style={selectedItem==3?{color:'#ff5a00'}:undefined}
        onClick={()=>{props.handleSelectItem(3)
          setSelectedItem(3);
        }}>{translate(selectedLanguage,'Doanh nghiệp đăng ký')}</a>
        </div>
        <div className='menuItem'>
        <Dropdown overlay={menu} className="language-dropdown">
            <Button className="language-button"           
            >
                <GlobalOutlined />
                {selectedLanguage} <DownOutlined />
            </Button>
        </Dropdown>
        </div>
      </div>
    </div>
  );
};
export default Header;
