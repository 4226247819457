import React from 'react';
import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import { Dispatch } from 'redux';
import {message} from 'antd';
import { StudentTask } from '../../interface';
export const learningProcessSlice = createSlice({
    name: 'learningProcess',
    initialState: [] as StudentTask[],
    reducers: ({
        getTasks: (state:StudentTask[], action: PayloadAction<StudentTask[]>)=>{
            return action.payload;
        }
    })
})
export function getTasks(masosinhvien: string){
    return async function getTasksByThunk(dispatch:Dispatch, getState: any){
        const token = localStorage.getItem('token');
        const rawResponse = await fetch(process.env.REACT_APP_API_URL+'api/Task/'+masosinhvien, {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`,
            },
      //body: JSON.stringify(answerRequest)
    }).then(response => {
        if(response.status==401){
            message.info("Phiên đăng nhập đã hết hạn!");
            localStorage.clear();
            window.location.reload();
        }
        return response.json()
    })
    .then((data)=>{
        console.log(data);
            dispatch(
                learningProcessSlice.actions.getTasks(data)
              )
        //console.log(data);       
    })
    .catch(err => console.log(err));
    }
}