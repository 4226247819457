import React, {useState} from 'react';
import { Button, Checkbox, Form, Input, message } from 'antd';
import '../../layouts/login/styles.css';
const ChangePass: React.FC = () => {
  const onFinish = (values: any) => {
    console.log('Success:', values);
    if(values.newpassword != values.newpasswordConfirm){
        setMyMessage("Mật khẩu mới chưa khớp nhau");
    }
    else{
        setMyMessage("");
        const token = localStorage.getItem('token');
        const rawResponse = fetch(process.env.REACT_APP_API_URL+'api/User', {
              method: 'POST',
              headers: {
                  'Accept': 'application/json',
                  'Content-Type': 'application/json',
                  'Authorization': `Bearer ${token}`,
              },
          body: JSON.stringify({role:localStorage.getItem("roleData"), userName:localStorage.getItem("emailData"), oldPassword:"", 
          newPassword:values.newpassword, mustChangeAtFirstTime: false})
      }).then(response => {
        if(response.status==401){
          message.info("Phiên đăng nhập đã hết hạn!");
          localStorage.clear();
          window.location.reload();
        }
        return response.json()
      })
      .then((data)=>{
            localStorage.setItem("passwordStatus", 'true');
            window.location.reload();
      })
      .catch(err => console.log(err));
    }
  };
  const [mymessage, setMyMessage] = useState("");
  const onFinishFailed = (errorInfo: any) => {
    console.log('Failed:', errorInfo);
  };
  return (
    <Form
      name="basic"
      labelCol={{ span: 8 }}
      wrapperCol={{ span: 8 }}
      initialValues={{ remember: true }}
      onFinish={onFinish}
      onFinishFailed={onFinishFailed}
      autoComplete="off"
    >
      <Form.Item
        label="Mật khẩu mới:"
        name="newpassword"
        style={{fontWeight:600, fontFamily: 'Roboto',
    fontSize: '20px'}}
        rules={[{ required: true, message: 'Please input your password!' }]}
      >
        <Input.Password className='LoginTopInside1Input' style={{width:'300px'}} />
      </Form.Item>
      <Form.Item
        label="Nhập lại mật khẩu mới:"
        name="newpasswordConfirm"
        style={{fontWeight:600, fontFamily: 'Roboto',
    fontSize: '20px'}}
        rules={[{ required: true, message: 'Please input your password!' }]}
      >
        <Input.Password className='LoginTopInside1Input' style={{width:'300px'}} />
      </Form.Item>
      <Form.Item 
        style={{color: 'red'}}
        wrapperCol={{ offset: 8, span: 6 }}     
        >
        {mymessage!=''?
        <label>{mymessage}</label>:''}
        </Form.Item>
      <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
      <Button style={{ backgroundColor: 'transparent', border: 'none', paddingLeft:'0px', paddingTop:'0px' }} 
          htmlType="submit"><img src='Register.png' /></Button>
      </Form.Item>
    </Form>
  );
};

export default ChangePass;