import React, { useEffect, useState } from 'react';
import { Space, Table, Tag, Button, Modal, Form, Input,
  Select, message, List } from 'antd';
import type { ColumnsType } from 'antd/es/table';
import { useAppDispatch } from '../../configs/hook';
import { useSelector } from 'react-redux';
import { RootState } from '../../redux/store';
import {Teacher} from '../../interface';
import axios from 'axios';
import {getAdmins, updateAdmin, deleteAdmin} from '../admin/adminSlide';
import { updateTeachers } from './teacherSlice';
import {assignCourse} from '../student/studentSlice';
import { setConstantValue } from 'typescript';
import {getTeachers} from './teacherSlice';


const Teachers: React.FC = () => {
    const dispatch = useAppDispatch();
    useEffect(()=>{
      dispatch(getTeachers());
    },[])
    const columns: ColumnsType<Teacher> = [
        {
          title: 'Mã số giảng viên',
          dataIndex: 'magiangvien',
          key: 'magiangvien',
        },
        {
          title: 'Họ và tên',
          dataIndex: 'tengiangvien',
          key: 'tengiangvien'
        },
        {
          title: 'Số căn cước công dân',
          dataIndex: 'cmnd',
          key: 'cmnd',
          render: (key, record:Teacher, text) => {
              return(
              <>
              {
              localStorage.getItem('emailData') != 'test@alta.com.vn' ? 
              !record.isEditableEmail?
               <a onClick={()=>{
                  let index = data.findIndex(c=>c.magiangvien==record.magiangvien);
                  let prefix = data.slice(0, index);
                  let mySlice = {...data[index], isEditableEmail: true}
                  let suffix = data.slice(index+1);
                  setData([...prefix, mySlice, ...suffix])
               }}>{record.cmnd}</a>
               :<Input type="text" value={record.cmnd}
               onChange={(e)=>{
                let index = data.findIndex(c=>c.magiangvien==record.magiangvien);
                let prefix = data.slice(0, index);
                let mySlice = {...data[index], email: e.currentTarget.value}
                let suffix = data.slice(index+1);
                setData([...prefix, mySlice, ...suffix])
               }}
               onKeyPress={(e)=>{
                 if(e.key == "Enter"){
                  let myRecord = data.find(c=>c.magiangvien==record.magiangvien);
                  setData(data.map(item=>{
                    if(item.magiangvien==record.magiangvien){
                      return {...item, isEditableEmail: false}
                    }
                    else
                      return item
                  }))
                    dispatch(updateTeachers(record));
                 }
               }}
               />
              :<p>{record.cmnd}</p>
              }
              </>
              )
          }
        },
        {
          title: 'Điện thoại',
          dataIndex: 'dienthoai',
          key: 'dienthoai',
          render: (key, record:Teacher, text) => {
            return(
            <>
            { localStorage.getItem('emailData') != 'test@alta.com.vn' ? 
            !record.isEditableDienthoai?
             <a onClick={()=>{
                 let index = data.findIndex(c=>c.magiangvien==record.magiangvien);
                  let prefix = data.slice(0, index);
                  let mySlice = {...data[index], isEditableDienthoai: true}
                  let suffix = data.slice(index+1);
                  setData([...prefix, mySlice, ...suffix])
             }}>{record.dienthoai}</a>
             :<input type="text" value={record.dienthoai}
             onChange={(e)=>{
              let index = data.findIndex(c=>c.magiangvien==record.magiangvien);
              let prefix = data.slice(0, index);
              let mySlice = {...data[index], dienthoai: e.currentTarget.value}
              let suffix = data.slice(index+1);
              setData([...prefix, mySlice, ...suffix])
             }}
             onKeyPress={(e)=>{
               if(e.key == "Enter"){
                setData(data.map(item=>{
                  if(item.magiangvien==record.magiangvien)
                    return {...item, isEditableDienthoai: false};
                  else
                    return item;
                }))
                dispatch(updateTeachers(record));
               }
             }}
             />
            :<p>{record.dienthoai}</p>
            }
            </>
            )
        }
        },
        {
          title: 'Hành động',
          dataIndex: 'delete',
          key: 'delete',
          render: (key, record:Teacher, text) => {
            return(
            <>
            <Button type="primary" danger
            disabled = {localStorage.getItem('emailData') != 'test@alta.com.vn' ? 
            false : true } 
            onClick = {()=>{
              setManhansu(record.magiangvien);             
              setIsConfirmModalOpen(true);
            }}
            >Xóa</Button>
            <Button type="primary"
            disabled = {localStorage.getItem('emailData') != 'test@alta.com.vn' ? 
            false : true } 
            onClick = {()=>{
                setManhansu(record.magiangvien);
                setIsAssignModalOpen(true);
                let courseList = myCourse.filter(c=>c.magiangvien == record.magiangvien);
                let newList: string[] = []
                courseList.map(item=>{
                    newList.push(item.tenlophocphan);
                })
                setCourseData(newList);
                let remaining = myCourse.filter(c=>c.magiangvien != record.magiangvien);
                let newRemaining: OptionProps[] = []
                remaining.map(item=>{
                  newRemaining.push({value: item.malophocphan, label:item.tenlophocphan});
                })
                setOptions(newRemaining);
            }}
            >Phân công</Button>
            </>
            ) 
          }
        }
      ];
    const [courseData, setCourseData] = useState<string[]>([]);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [value, setValue] = useState('');
    const handleChange = (value: string) => {
      console.log(`selected ${value}`);
      setValue(value);
    };
    const [isConfirmModalOpen, setIsConfirmModalOpen] = useState(false);
    const handleConfirmOk = (item: string) => {
          dispatch(deleteAdmin(item));
          setData(data.filter(c=>c.magiangvien!=item));
    }
    const [isAssignModalOpen, setIsAssignModalOpen] = useState(false);
    const handleConfirmCancel = () => {
      setIsConfirmModalOpen(false);
    }
    const handleOk = () => {
        setIsModalOpen(false);
      };
      const handleCancel = () => {
        setIsModalOpen(false);
      };
      const showModal = () => {
        setIsModalOpen(true);
      };
    const myTeachers = useSelector((state:RootState)=>state.teachers);
    const myCourse = useSelector((state:RootState)=>state.student);
    const [manhansu, setManhansu] = useState("");
    const [data, setData] = useState(myTeachers);
    const [tengiangvien, setTengiangvien] = useState("");
    const [cmnd, setCmnd] = useState("");
    const [dienthoai, setDienthoai] = useState("");
    const onFinish = async (values: any) => {
      const token = localStorage.getItem('token');
      const formData = new FormData();
          formData.append("Magiangvien", values.manhansu);
          formData.append("Tengiangvien", values.hovaten);
          formData.append("Cmnd", values.email);
          formData.append("Password", "abc123!");
          formData.append("Dienthoai", values.dienthoai);
          const config = {     
            headers: { 'content-type': 'multipart/form-data', 'Authorization': `Bearer ${token}` }
          }
          axios.post(process.env.REACT_APP_API_URL+'api/Lecturer/',formData, config)
            .then(response => {
              if(response.status==401){
                message.info("Phiên đăng nhập đã hết hạn!");
                localStorage.clear();
                window.location.reload();
            }
                  message.info("Them moi thành công");
            })
            .catch(error => {
                console.log(error);
            });       
        dispatch(getAdmins());
        setData([...data, {
        "magiangvien": values.manhansu,
        "tengiangvien": values.hovaten,
        "cmnd": values.email,
        "dienthoai": values.dienthoai,
        isEditableDienthoai: false,
        isEditableEmail: false
        }])
      };
    type OptionProps = {
      value: string,
      label: string
    }
    const [options, setOptions] = useState<OptionProps[]>([]);
    return(
    <>
    <Button onClick={showModal}
    disabled = {localStorage.getItem('emailData') != 'test@alta.com.vn' ? 
    false : true } 
    >Thêm thành viên</Button>
    <Table columns={columns} dataSource={data} />
    <Modal title="Thêm thành viên" open={isModalOpen} onOk={handleOk} onCancel={handleCancel}>
        <Form labelCol={{ span: 4 }}
        wrapperCol={{ span: 20 }}
        layout="horizontal"
        onFinish={onFinish} >
            <Form.Item label="Mã giáo viên:" 
        name="manhansu"
        rules={[{ required: true, message: 'Không được để trống!' }]}>
          <Input value={manhansu}
          onChange={(e)=>{
              setManhansu(e.currentTarget.value);
          }
        } />
        </Form.Item>
        <Form.Item label="Họ và tên:" 
        name="hovaten"
        rules={[{ required: true, message: 'Không được để trống!' }]}>
          <Input value={tengiangvien}
          onChange={(e)=>{
              setTengiangvien(e.currentTarget.value);
          }
        } />
        </Form.Item>
        <Form.Item label="Căn cước công dân:" 
        name="email"
        rules={[{ required: true, message: 'Không được để trống!' }]}>
          <Input value={cmnd}
          onChange={(e)=>{
              setCmnd(e.currentTarget.value);
          }
        } />
        </Form.Item>
        <Form.Item label="Điện thoại:" 
        name="dienthoai"
        rules={[{ required: true, message: 'Không được để trống!' }]}>
          <Input value={dienthoai}
          onChange={(e)=>{
              setDienthoai(e.currentTarget.value);
          }
        } />
        </Form.Item>
        <Form.Item label="" wrapperCol={{offset:4}}>
          <Button type="primary" htmlType="submit">Lưu dữ liệu</Button>
        </Form.Item>
        </Form>
    </Modal>
    <Modal title="Xóa dữ liệu" open={isConfirmModalOpen} onOk={()=>handleConfirmOk(manhansu)} onCancel={handleConfirmCancel}>
        <h3 style={{color:'#FF0000'}}>Bạn có chắc chắn xóa giáo viên {manhansu} này khỏi hệ thống?</h3>
    </Modal>
    <Modal title="Phân công giảng dạy" open={isAssignModalOpen} onCancel={()=>setIsAssignModalOpen(false)}>
      <Select 
      style={{ width: 350 }}
      onChange={handleChange}
      options = {options}
      /><Button onClick={()=>{
        if(value!="")
        {
          dispatch(assignCourse({courseId: value, lecturerId: manhansu}));
          let index = myCourse.findIndex(c=>c.malophocphan==value)
          setCourseData([myCourse[index].tenlophocphan, ...courseData])
          let newOptions = options.filter(c=>c.value != value);
          setOptions(newOptions);
        }
      }}>Phân công</Button>
        <List
            header="Danh sách lớp được phân công"
            dataSource={courseData}
            pagination={{onChange:(page)=>{
                console.log(page);
            },
            pageSize: 3,
        }}
        renderItem={(item)=>{
          return(
            <List.Item>{item}</List.Item>
          )
        }}
            //Should use VirtualList from 'rc-virtual-list'
        />
    </Modal>
    </>
    )
}
export default Teachers;