import { Calendar, theme, Modal, Button, message } from 'antd';
import type { CalendarMode } from 'antd/es/calendar/generateCalendar';
import { Dayjs } from 'dayjs';
import dayjs from 'dayjs';
import React, {useState, useEffect} from 'react';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import './style.css';
import {getCurrentWeek, getDateString} from '../../helpers';
import { useSelector } from 'react-redux';
import { RootState } from '../../redux/store';
import { useAppDispatch } from '../../configs/hook';
import { setValue } from '../timeline/timelineSlice';
const onPanelChange = (value: Dayjs, mode: CalendarMode) => {
  console.log(value.format('YYYY-MM-DD'), mode);
};
type listWeekDayTaskType = {
  weekday: string, data: itemTypes[]
}[]
type itemTypes = {id:string, content: string}
export const TimeLine: React.FC = () => {
  const { token } = theme.useToken();
  const mytoken = localStorage.getItem('token');
  const myEmail = localStorage.getItem('userNameData');
  const myTimeLine = useSelector((state:RootState)=>state.timeline);
  const [columns, setColumns] = useState([
    {
      title: '',
      dataIndex: 'dayPart',
      key: 'dayPart',
    },
    {
      title: 'Thứ hai(' + getDateString(getCurrentWeek()[0]) + ')',
      dataIndex: 'monday',
      key: 'monday',
    },
    {
        title: 'Thứ ba(' + getDateString(getCurrentWeek()[1]) + ')',
        dataIndex: 'tuesday',
        key: 'tuesday',
      },
      {
        title: 'Thứ tư(' + getDateString(getCurrentWeek()[2]) + ')',
        dataIndex: 'wednesday',
        key: 'wednesday',
      },
      {
        title: 'Thứ năm(' + getDateString(getCurrentWeek()[3]) + ')',
        dataIndex: 'thursday',
        key: 'thursday',
      },
      {
        title: 'Thứ sáu(' + getDateString(getCurrentWeek()[4]) + ')',
        dataIndex: 'friday',
        key: 'friday',
      },
      {
        title: 'Thứ bảy(' + getDateString(getCurrentWeek()[5]) + ')',
        dataIndex: 'saturday',
        key: 'saturday',
      }
  ]);
  const dispatch = useAppDispatch();
  const [dataSource, setDataSource] = useState([
    {
      dayPart: 'Ca 1',
      monday: '',
      tuesday: '',
      wednesday:'',
      thursday:'',
      friday: '',
      saturday:''
    },
    {
      dayPart: 'Ca 2',
      monday: '',
      tuesday: '',
      wednesday:'',
      thursday:'',
      friday: '',
      saturday:''
    },
    {
      dayPart: 'Ca 3',
      monday: '',
      tuesday: '',
      wednesday:'',
      thursday:'',
      friday: '',
      saturday:''
    },
    {
      dayPart: 'Ca 4',
      monday: '',
      tuesday: '',
      wednesday:'',
      thursday:'',
      friday: '',
      saturday:''
    },
    {
      dayPart: 'Ca 5',
      monday: '',
      tuesday: '',
      wednesday:'',
      thursday:'',
      friday: '',
      saturday:''
    },
    {
      dayPart: 'Ca 6',
      monday: '',
      tuesday: '',
      wednesday:'',
      thursday:'',
      friday: '',
      saturday:''
    }
]);

const [myDateOfWeek, setMyDateOfWeek] = useState<Date[]>(getCurrentWeek);
const onChange = (value: Dayjs) => {  
  const token = localStorage.getItem('token');
        const myEmail = localStorage.getItem('userNameData');
        let myNewArrLst = [] as listWeekDayTaskType[];
        let stDate = getCurrentWeek(value)[0].getFullYear() + '-'
  + (getCurrentWeek(value)[0].getMonth()+1<10?'0'+ (getCurrentWeek(value)[0].getMonth()+1)
  :getCurrentWeek(value)[0].getMonth()+1)+'-'
  + (getCurrentWeek(value)[0].getDate()<10?'0'+getCurrentWeek(value)[0].getDate()
  :getCurrentWeek(value)[0].getDate());
  let edDate = getCurrentWeek(value)[5].getFullYear() + '-'
  + (getCurrentWeek(value)[5].getMonth()+1<10?'0'+ (getCurrentWeek(value)[5].getMonth()+1)
  :getCurrentWeek(value)[5].getMonth()+1)+'-'
  + (getCurrentWeek(value)[5].getDate()<10?'0'+getCurrentWeek(value)[5].getDate()
  :getCurrentWeek(value)[5].getDate());
        fetch(process.env.REACT_APP_API_URL+'api/ScheduleTask/'+stDate+'/'+edDate + '/' + myEmail, {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`,
            },
      }).then(response => response.json())
      .then((data)=>{
          myNewArrLst = [];
          for(let i=0; i<=5; i++){
            myNewArrLst.push(setValue(i, data));
          }
      }).then(()=>setListWeekDayTask(myNewArrLst));  
  setColumns([
      {
        title: '',
        dataIndex: 'dayPart',
        key: 'dayPart',
      },
      {
        title: 'Thứ hai(' + getDateString(getCurrentWeek(value)[0]) + ')',
        dataIndex: 'monday',
        key: 'monday',
      },
      {
          title: 'Thứ ba(' + getDateString(getCurrentWeek(value)[1]) + ')',
          dataIndex: 'tuesday',
          key: 'tuesday',
        },
        {
          title: 'Thứ tư(' + getDateString(getCurrentWeek(value)[2]) + ')',
          dataIndex: 'wednesday',
          key: 'wednesday',
        },
        {
          title: 'Thứ năm(' + getDateString(getCurrentWeek(value)[3]) + ')',
          dataIndex: 'thursday',
          key: 'thursday',
        },
        {
          title: 'Thứ sáu(' + getDateString(getCurrentWeek(value)[4]) + ')',
          dataIndex: 'friday',
          key: 'friday',
        },
        {
          title: 'Thứ bảy(' + getDateString(getCurrentWeek(value)[5]) + ')',
          dataIndex: 'saturday',
          key: 'saturday',
        }
    ]);
};
const items:itemTypes[]=[];
const weekdays = ['monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday'];
const [listWeekDayTask, setListWeekDayTask] = useState(myTimeLine);
const [item, setItem] = useState<itemTypes[]>(items);
  const wrapperStyle: React.CSSProperties = {
    width: 300,
    border: `1px solid ${token.colorBorderSecondary}`,
    borderRadius: token.borderRadiusLG,
  };
  const handleDragEnd = (result:any) => {
    if (!result.destination) return;
    let choice = result.destination.droppableId != "itemList" ?
    result.source.droppableId != "itemList" ? 1
    : 2
    : result.source.droppableId != "itemList" ? 3
    : 4
    switch(choice){
      case 1:
        let j = result.source.droppableId.substring(result.source.droppableId.lastIndexOf('-')+1);
        let mySourceDay = result.source.droppableId.substring(0,result.source.droppableId.lastIndexOf('-'));
        let myIndexSource = listWeekDayTask[j].findIndex(c=>c.weekday==mySourceDay);
        let i = result.destination.droppableId.substring(result.destination.droppableId.lastIndexOf('-')+1);
        let myDay = result.destination.droppableId.substring(0,result.destination.droppableId.lastIndexOf('-'));
        let myIndex = listWeekDayTask[i].findIndex(c=>c.weekday==myDay);
        const [removed] = listWeekDayTask[j][myIndexSource].data.splice(result.source.index, 1);
        listWeekDayTask[i][myIndex].data.push(removed);
      break;
      case 2: // Drag item from List and drop in Calendar
        let i1 = result.destination.droppableId.substring(result.destination.droppableId.lastIndexOf('-')+1);
        let myDay1 = result.destination.droppableId.substring(0,result.destination.droppableId.lastIndexOf('-'));
        let myIndex1 = listWeekDayTask[i1].findIndex(c=>c.weekday==myDay1);
        const [removed1] = item.splice(result.source.index, 1);
        let previous = listWeekDayTask.slice(0, i1);
        let next = listWeekDayTask.slice(i1+1);
        let innerCurrentPrevious = listWeekDayTask[i1].slice(0, myIndex1);
        let innerCurrentNexts = listWeekDayTask[i1].slice(myIndex1+1);
        let current = [...innerCurrentPrevious, 
          {...listWeekDayTask[i1][myIndex1], data:[...listWeekDayTask[i1][myIndex1].data, removed1]},
          ...innerCurrentNexts
        ]
        setListWeekDayTask([...previous, current, ...next]);
        let taskDate = '';
        switch(myDay1){
          case 'monday':
            taskDate = columns[1].title.substring(columns[1].title.indexOf('(')+1,columns[1].title.lastIndexOf(')'))
            break;
          case 'tuesday':
            taskDate = columns[2].title.substring(columns[2].title.indexOf('(')+1,columns[2].title.lastIndexOf(')'))
            break;
          case 'wednesday':
            taskDate = columns[3].title.substring(columns[3].title.indexOf('(')+1,columns[3].title.lastIndexOf(')'))
            break;
          case 'thursday':
            taskDate = columns[4].title.substring(columns[4].title.indexOf('(')+1,columns[4].title.lastIndexOf(')'))
            break;
          case 'friday':
            taskDate = columns[5].title.substring(columns[5].title.indexOf('(')+1,columns[5].title.lastIndexOf(')'))
            break;
          default:
            taskDate = columns[6].title.substring(columns[6].title.indexOf('(')+1,columns[6].title.lastIndexOf(')'))
            break;
        }
        fetch(process.env.REACT_APP_API_URL+'api/ScheduleTask/', {
          method: 'POST',
          headers: {
              'Accept': 'application/json',
              'Content-Type': 'application/json',
              'Authorization': `Bearer ${mytoken}`,
          },
          body: JSON.stringify({taskName: removed1.content, taskDate:taskDate, 
            dayPart: 'Ca ' + (Number(i1)+1), email: localStorage.getItem('userNameData')})
    }).then(response => response.json())
    .then(data=>{
      message.info(data);
    })
    .catch(err => console.log(err));

        break;
      case 3:
        let j1 = result.source.droppableId.substring(result.source.droppableId.lastIndexOf('-')+1);
        let mySourceDay1 = result.source.droppableId.substring(0,result.source.droppableId.lastIndexOf('-'));
        let myIndexSource1 = listWeekDayTask[j1].findIndex(c=>c.weekday==mySourceDay1);
        const [removed2] = listWeekDayTask[j1][myIndexSource1].data.splice(result.source.index, 1);
        setItem([...item, removed2]);

        fetch(process.env.REACT_APP_API_URL+'api/ScheduleTask/' + removed2.id, {
          method: 'DELETE',
          headers: {
              'Accept': 'application/json',
              'Content-Type': 'application/json',
              'Authorization': `Bearer ${mytoken}`,
          },
          
    }).then(response => response.json())
    .then(data=>{
      message.info(data);
    })
    .catch(err => console.log(err));

        break;
      defautl:
        break;
    }
  
}
const [isModalOpen, setIsModalOpen] = useState(false);
const [isModalOpen1, setIsModalOpen1] = useState(false);

  const showModal = () => {
    setIsModalOpen(true);
  };

  const showModal1 = () => {
    setIsModalOpen1(true);
  };
  const handleOk = () => {
    setIsModalOpen(false);
  };
  const handleOk1 = () => {
    setItem((previous:itemTypes[])=>{
      return(
        [...previous, {id:'item-'+ (previous.length + 1), content: task}]
      )
    })
    setIsModalOpen1(false);
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };
  const handleCancel1 = () => {
    setIsModalOpen1(false);
  };
  const [task, setTask] = useState('');
  return (
    <>
    <div>
      <Modal title="Lịch làm việc" open={isModalOpen} onOk={handleOk} onCancel={handleCancel}>
      <Calendar fullscreen={false} onPanelChange={onPanelChange}
      onChange={onChange}
      />
      </Modal>
      <Modal title="Thêm việc" open={isModalOpen1} onOk={handleOk1} onCancel={handleCancel1}>
          Công việc: <input type="text" style={{width:'300px'}}
          onChange={(e)=>setTask(e.currentTarget.value)} value={task} />
      </Modal>
      <DragDropContext onDragEnd={handleDragEnd}>
      <div style={{float:'right', width:'78%'}}>
      <table style={{border:'1px solid gray'}}>
        <thead style={{fontWeight:'bold'}}>
          <tr>
            {columns.map((item:any)=>(
              <td style={{border:'1px solid gray', 
              textAlign:'center', backgroundColor:'gray'}}>{item.title}</td>
            ))}
          </tr>
        </thead>
        <tbody>
          {dataSource.map((item:any, index:number)=>{
            let dropableIdArr = {id:index, weekdays: [
              "monday-"+index.toString(),
              "tuesday-"+index.toString(),
              "wednesday-"+index.toString(),
              "thursday-"+index.toString(),
              "friday-"+index.toString(),
              "saturday-"+index.toString()
            ]}
            return(
            <tr>
              <td style={{border:'1px solid gray', width:'50px'}}>{item.dayPart}</td>
              {weekdays.map(item=>{
                let myId = item + "-" + dropableIdArr.id;
                let k = (new Date()).getDay() == 1 ? 'monday'
                : (new Date()).getDay() == 2 ? 'tuesday'
                : (new Date()).getDay() == 3 ? 'wednesday'
                : (new Date()).getDay() == 4 ? 'thursday'
                : (new Date()).getDay() == 5 ? 'friday'
                :(new Date()).getDay() == 6 ? 'saturday' : 'sunday';
                let taskDate1 = columns[1].title.substring(columns[1].title.indexOf('(')+1,columns[1].title.lastIndexOf(')'))
                let d1 = new Date(taskDate1);
                d1.setDate(d1.getDate()-1);
                let taskDate2 = columns[6].title.substring(columns[6].title.indexOf('(')+1,columns[6].title.lastIndexOf(')'))
                let d2 = new Date(taskDate2);
                d2.setDate(d2.getDate()+1);
                let myStyle = item == k && (new Date()) >= d1  && (new Date()) <= d2
                ? {width:'200px', backgroundColor:'cyan', border:'1px solid gray'}
                : {width:'200px',border:'1px solid gray'};
                return(
                  <td style={myStyle}>
                  <Droppable droppableId={myId} >
            {provided => {
              let myIndex = -1
              if(listWeekDayTask[index] != undefined){
                myIndex =  listWeekDayTask[index].findIndex(c=>c.weekday==item)
              }
              return(
              <div style={{minHeight:'150px', padding:'2px 2px 2px 2px'}} {...provided.droppableProps} ref={provided.innerRef}>
              {
                myIndex==-1?"":
                listWeekDayTask[index][myIndex].data.map((item:any, index1:number)=>{
                  console.log(index + " " + myIndex + " " + item.content)
                  return(
                  <Draggable key={item.id.toString()} draggableId={item.id.toString()} index={index1}>
                  {provided => (
                  <p key={item.id}
                  {...provided.draggableProps}
                    {...provided.dragHandleProps}
                    ref={provided.innerRef}
                  >
                  {item.content}
                  </p>
                  )}
                  </Draggable>
                  )
                  })
              }                            
                {provided.placeholder}
              </div>
            )}}
          </Droppable>
                  </td>
              )})}            
            </tr>
          )})}
        </tbody>
      </table>
      </div>
      <Button type="primary" onClick={showModal} style={{marginLeft:'10px', width:'19%'}}>
        Chọn tuần
    </Button>
      <div style={{float:'left',width:'20%'}}>
      <Droppable droppableId="itemList">
        {provided => (
          <ul {...provided.droppableProps} ref={provided.innerRef} 
          style={{listStyle:'none', border:'1px solid blue', margin:'5px 0px 10px 10px',
          padding:'10px 10px 10px 10px'
          }}>
            {item.map(({ id, content }, index) => (
              <Draggable key={id} draggableId={id} index={index}>
                {provided => (
                  <li
                    {...provided.draggableProps}
                    {...provided.dragHandleProps}
                    ref={provided.innerRef}
                    // style={{border:'1px solid black', backgroundColor:'pink'}}
                  >
                    {content}
                  </li>
                )}
              </Draggable>
            ))}
            {provided.placeholder}
          </ul>
        )}
      </Droppable>
      </div>
      <Button type="primary" onClick={showModal1} style={{marginLeft:'10px', width:'19%'}}>
        Thêm công việc
    </Button>
      </DragDropContext>
    </div>
    </>
  );
};