import React, { useState, useEffect } from 'react';
import { Menu, FormItemProps,Dropdown } from 'antd';
import { HeartFilled, CheckOutlined } from '@ant-design/icons';
import './Dropdown.css';

interface Option {
    label: string;
    value: string;
}
interface DropdownProps extends FormItemProps {
    options: Option[];
    value?: string;
    onChange?: (value: string) => void;
    defaultOption?: string;
}
const CustomDropdown: React.FC<DropdownProps> = ({ options, value, onChange, defaultOption }) => {
    const [selectedOption, setSelectedOption] = useState<string>('1');
    const [open, setOpen] = useState<boolean>(false);
    const handleMenuClick = (e: any) => {
        const selected = e.key as string;
        setSelectedOption(selected);
        if (onChange) {
            onChange(selected);
        }
        setOpen(false); // Close dropdown after selection
    };
    const handleDropdownVisibleChange = (flag: boolean) => {
        setOpen(flag);
    };
    const selectedLabel = options.find(option => option.value === selectedOption)?.label || defaultOption;
    const menu = (
        <Menu onClick={handleMenuClick}>
            {options.map((option) => (
                <Menu.Item key={option.value} className={selectedOption === option.value ? 'dropdown-item active' : 'dropdown-item'}>
                    {option.label}
                    {selectedOption === option.value && <CheckOutlined className="icon" />}
                </Menu.Item>
            ))}
        </Menu>
    );

    return (
        // <Select
        //     value={value || defaultOption}
        //     onChange={onChange}
        //     style={{border:'none'}}
        //     placeholder="Chọn vai trò"
        //     onDropdownVisibleChange={handleDropdownVisibleChange}
        //     suffixIcon={<HeartFilled className={`dropdown-icon ${open ? 'dropdown-icon-rotate' : ''}`} />} // Custom icon
        //     options={[...options]}
        //     optionRender={(item:any)=>{
        //         return(
        //             <div className={`dropdown-item ${value === item.value ? 'active' : ''}`}>
        //                 {item.label}
        //                 {value === item.value && <CheckOutlined className="icon" />}
        //             </div>
        //         )
        //     }}  
        //     />
        <Dropdown overlay={menu} trigger={['click']} 
            onVisibleChange={handleDropdownVisibleChange}>
                <div className={`dropdown ${open ? 'dropdown-open' : ''}`}>
                    <span>{selectedLabel}</span>
                    <HeartFilled className={`dropdown-icon ${open ? 'dropdown-icon-rotate' : ''}`} />
                </div>
        </Dropdown>
    );
};

export default CustomDropdown;
