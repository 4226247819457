import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import { Dispatch } from 'redux';
import {Monhoc} from '../../interface';
import axios from 'axios';

export const subjectSlice = createSlice({
    name:'subject',
    initialState:[] as Monhoc[],
    reducers: ({
        getSubjects: (state, action: PayloadAction<Monhoc[]>) => {
            return action.payload;
        }
    })
})
export function getSubjects(){
    return async function getSubjectsThunk(dispatch: Dispatch, getState:any){
        const token = localStorage.getItem('token');
        const rawResponse = await fetch(process.env.REACT_APP_API_URL+'api/Subject', {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            }
    }).then(response => response.json())
    .then((data)=>{
            dispatch(subjectSlice.actions.getSubjects(data))
            console.log(getState())
    })
    .catch(err => console.log(err));
    }
}
