// JobSearchBar.tsx
import React, {useEffect, useState} from 'react';
import { Input, Select, Button } from 'antd';
import { SearchOutlined } from '@ant-design/icons';
import CustomDropdown from '../customDropdown';
import './searchFilters.css';
import { translate } from '../../helpers/translate';
import type { SelectProps } from 'antd';
const { Option } = Select;
interface SeachFilterProps {
  handleProvinceChange: (province:string) => void;
  handleCompanyChange: (company:string) => void;
  handleSearchText: (text:string)=>void;
  language: string;
}
interface Option {
  label: string;
  value: string;
}
const SearchFilters = (props:SeachFilterProps) => {
  const [optionsJDs, setOptionsJDs] = useState<Option[]|null>(null);
  const [searchText, setSearchText] = useState('___');
  const [compayList, setCompanyList] = useState<Option[]|null>(null);
  const [loading, setLoading] = useState(true);
  useEffect(()=>{
      const rawResponse2 = fetch(process.env.REACT_APP_API_URL+'api/Company', {
        method: 'GET',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
        },
  }).then(response => response.json())
  .then((data)=>{
    const myFetch = new Promise((resolve)=>{
      let newCompanyOption = [{value:'0', label:'Chọn công ty'}];
      data.map((item:any)=>{
        newCompanyOption.push({value:item.taxCode, label: item.companyName});
      });
      resolve(newCompanyOption);
    })      
    .then((data:any)=>setCompanyList(data));
  })
  .catch(err => console.log(err));
      const res = fetch('/api/provinces.json')
                      .then(response=>response.json())
                      .then(data=>{
                          let newOptions = [{value: '0', label:translate(props.language,'Chọn nơi làm việc')}]
                          data.map((row:any)=>{                              
                              newOptions.push({value: row.AreaCode, label: row.Provinces});                              
                          });
                          setOptionsJDs(newOptions);
                      })
  },[]);
  return (
    <>
    {optionsJDs!=null && compayList!=null?
    <div className="job-search-bar">
      <Input
        className="input-field"
        placeholder={translate(props.language,"Nhập vị trí muốn ứng tuyển")}
        prefix={<SearchOutlined />}
        value={searchText=='___'?'':searchText}
          onChange={(e)=>{
            if(e.currentTarget.value=='')
              setSearchText('___');
            else
              setSearchText(e.currentTarget.value)}}
          onKeyPress={(e)=>{
            if(e.key=='Enter'){
              props.handleSearchText(searchText);
            }
          }}
      />
      <CustomDropdown style={{flex:1, marginTop:'10px'}} options={compayList} defaultOption={translate(props.language,'Chọn công ty')} 
      onChange={(value:string)=>props.handleCompanyChange(value)}
      />
      <CustomDropdown style={{flex:1, marginTop:'10px'}} options={optionsJDs} defaultOption={translate(props.language,'Chọn nơi làm việc')} 
      onChange={(value:string)=>props.handleProvinceChange(value)}
      />
      <Button className="search-button" type="primary" icon={<SearchOutlined />}>
        Tìm việc
      </Button>
    </div>:"Loading..."}
    </>
  );
};
export default SearchFilters;
