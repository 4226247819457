import React, {useState, useEffect, ChangeEvent} from 'react';
import { Modal, Layout, Menu, theme, Select, SelectProps, Input, message } from 'antd';
import EnterpiseRequirement from '../../components/requirement';
import axios from 'axios';
import Giaovien from '../../components/giaovien';
import EnterpriseLayoutHeader from '../../components/Header/enterpriseHeader';
const { Header, Content, Footer } = Layout;

const EnterpriseLayout: React.FC = () => {
  const [selectedIndex, setSelectedIndex] = useState(1);
  const receiveIndex = (index:number) => {
    setSelectedIndex(index);
  }
  return (
    <>
      <EnterpriseLayoutHeader handleSelectItem={receiveIndex} />
      {selectedIndex==1?      
      <EnterpiseRequirement />:<Giaovien />}        
      <Footer style={{ textAlign: 'center' }}>Alta group - Trainning team ©2023</Footer>
    </>
  );
};
export default EnterpriseLayout;