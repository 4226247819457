import React, {useState, useEffect} from 'react';
import { Table, Select } from 'antd';
import type { ColumnsType, ColumnGroupType } from 'antd/es/table';
import type { SelectProps } from 'antd';
import { Excel } from "antd-table-saveas-excel";
interface DataType {
    key: React.Key;
    studentName: string;
    studentEmail: string;
    startDate: string;
    endDate: string;
    subjectName: string;
    processPoints: number;
    midTernPoints: number;
    finalTestPoint: number;
}
const columns = [
    {
      title: 'Họ và tên',
      dataIndex: 'studentName',
      key: 'studentName',
      //fixed:'left'
    },
    {
      title: 'Email',
      dataIndex: 'studentEmail',
      key: 'studentEmail'
    },
    {
      title: 'Ngày bắt đầu',
      dataIndex: 'startDate',
      key: 'startDate'
    },
    {
      title: 'Ngày kết thúc',
      dataIndex: 'endDate',
      key: 'endDate',
    },
    {
        title: 'Nhóm thực tập',
        dataIndex: 'subjectName',
        key: 'subjectName',
    },
    {
        title: 'Điểm thái độ',
        dataIndex: 'processPoints',
        key: 'processPoints',
        //align:'right'
    },
    {
        title: 'Điểm project 1',
        dataIndex: 'midTernPoints',
        key: 'midTernPoints',
        //align:'right'
    },
    {
        title: 'Điểm project 2',
        dataIndex: 'finalTestPoint',
        key: 'finalTestPoint',
        //align: 'right'
    }
  ];

export const ReportByUni = () => {
    const [data, setData] = useState([]);
    const [options, setOptions] = useState<SelectProps['options']>([]);
    const getReport = async (university: string, startDate: string, endDate: string) => {
        const token = localStorage.getItem('token');
            const rawResponse = await fetch(process.env.REACT_APP_API_URL+'api/Report/reportUniversity/' + university + '/' + startDate + "/" + endDate, {
                method: 'GET',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`,
                },
        }).then(response => response.json())
        .then((data)=>{
            setData(data);
        })
        .catch(err => console.log(err));
    }
    const getUniversity =  async () => {
        const token = localStorage.getItem('token');
            const rawResponse = await fetch(process.env.REACT_APP_API_URL+'api/University/', {
                method: 'GET',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`,
                },
        }).then(response => response.json())
        .then((data)=>{
            setOptions(data.map((item:any)=>{
                return {value: item.matruong, label:item.tentruong}
              }))
        })
        .catch(err => console.log(err));
    }   
    useEffect(()=>{
        getUniversity();
        getReport('UTH','2023-01-01', '2023-12-31');
    },[])
    const handleChange = (value: string) => {
        getReport(value,'2023-01-01', '2023-12-31');
    };
    return(
        <>
        <h3>Chọn trường đại học:&nbsp;&nbsp;<Select
        style={{ width: '30%', marginRight: '20px' }}
        onChange={handleChange}
        options={options}
        />
        <a href="#" onClick={()=> {
          const excel = new Excel();
          excel.addSheet("Result").addColumns(columns).addDataSource(data, {
        str2Percent: true
      })
      .saveAs("Result.xlsx");
          return false;
        }}>Xuất Excel</a>
        </h3>
        <Table 
        columns={columns} dataSource={data}
        bordered
        size="middle"
        scroll={{x:'calc(700px + 50%)', y:240}}
        />
        </>
    )
}