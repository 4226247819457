import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import { Dispatch } from 'redux';
import {message} from 'antd';
import {Teacher} from '../../interface';
import axios from 'axios';
import { stat } from 'fs';


export const teacherSlice = createSlice({
    name:'teachers',
    initialState:[] as Teacher[],
    reducers: ({
        getTeachers: (state, action: PayloadAction<Teacher[]>) => {
            state = [];
            return action.payload;
        },
        addTeachers:(state, action:PayloadAction<Teacher>) =>{
            return [...state, action.payload]
        },
        updateTeachers: (state, action:PayloadAction<Teacher>)=>{
            let index = state.findIndex(c=>c.magiangvien==action.payload.magiangvien);
            let previous = state.slice(0,index);
            let next = state.slice(index+1);
            return [...previous, action.payload, ...next];    
        },
        deleteTeachers: (state, action:PayloadAction<string>) => {
            return state.filter(c=>c.magiangvien!=action.payload);
        }
    })
})
export function getTeachers(){
    return async function getTeachersThunk(dispatch: Dispatch, getState:any){
        const token = localStorage.getItem('token');
        const rawResponse = await fetch(process.env.REACT_APP_API_URL+'api/Lecturer/', {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`,
            },
      //body: JSON.stringify(answerRequest)
    }).then(response => {
        if(response.status==401){
            message.info("Phiên đăng nhập đã hết hạn!");
            localStorage.clear();
            window.location.reload();
        }
        return response.json()
    })
    .then((data)=>{
        console.log(data);
        let newdata: Teacher[] = [];
        data.map((item:any)=>{
            newdata.push({magiangvien: item.magiangvien,
            tengiangvien:item.tengiangvien,
            cmnd:item.cmnd,
            dienthoai:item.dienthoai,
            imageDataUrl:item.imageDataUrl,
            isEditableDienthoai: false,
            isEditableEmail:false
            });
        })
            dispatch(
                teacherSlice.actions.getTeachers(newdata)
              )
        //console.log(data);       
    })
    .catch(err => console.log(err));
    }
}
export function updateTeachers(myadmin: Teacher){
    return async function updateTeachersThunk(dispatch: Dispatch, getState:any){
        const token = localStorage.getItem('token');
      const formData = new FormData();
          formData.append("Magiangvien", myadmin.magiangvien);
          formData.append("Tengiangvien", myadmin.tengiangvien);
          formData.append("Cmnd", myadmin.cmnd);
          formData.append("Password", "abc123!");
          formData.append("Dienthoai", myadmin.dienthoai);
          const config = {     
            headers: { 'content-type': 'multipart/form-data', 'Authorization': `Bearer ${token}` }
          }
          axios.put(process.env.REACT_APP_API_URL+'api/Lecturer/'+ myadmin.magiangvien, formData, config)
            .then(response => {
                 
            })
            .catch(error => {
                console.log(error);
            });
        dispatch(teacherSlice.actions.updateTeachers(myadmin));
    }
}
export function deleteTeachers(manhansu: string){
    return async function deleteTeachersThunk(dispatch: Dispatch, getState:any){
        const token = localStorage.getItem('token');
        const rawResponse = await fetch(process.env.REACT_APP_API_URL+'api/Lecturer/'+ manhansu, {
          method: 'DELETE',
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`, // notice the Bearer before your token
          },
        });
        const msg = await rawResponse.json();
        console.log(msg);
        dispatch(teacherSlice.actions.deleteTeachers(manhansu));
    }
}