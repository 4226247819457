import React, {useState} from "react";
import ContactList from "../../components/contactList";
import NvcmisChat from "../../components/chat/nvcmisChat";
import LoginLayout from "../login";
import styles from './chatlayout.module.css';
const ChatLayout = () => {
    const [groupId, setGroupId] = useState(0);
    const [updatedGroup, setUpdatedGroup] = useState(0);
  const [groupArr, setGroupArr] = useState<any[]>([]);
  const handleReceiveData = (data:number, groupArr:{userName: string, email:string, 
    userAvatar: string, isGroup: boolean}[]) => {
      setGroupId(data); setGroupArr(groupArr);
  }
  const handleReceiveGroupId = (groupId: string) => {
    setUpdatedGroup(Number(groupId));
  }
    return(
        <div>
        {localStorage.getItem('emailData')!=null?<div className={styles.ChatContainer}>
            <ContactList handleChangeGroupId={handleReceiveData} updatedGroupId={updatedGroup} />{groupId!=0?<NvcmisChat groupId={groupId} 
            groupArr={groupArr} handleUpdateGroupList={handleReceiveGroupId}
            />:null}</div>
            :<LoginLayout />}
        </div>
    )
}
export default ChatLayout;