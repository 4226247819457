// JobList.tsx
import React from 'react';
import JobCard from './JobCard';
import './jobList.css';

interface jobDataType {
  jobs:{
    title: string;
    company: string;
    requirements: string;
    email: string;
    phone: string;
    logo: string;
    id: number;
  }[]
}
const JobList = (props:jobDataType) => {
  return (
    <div className="job-list">
      {props.jobs.map((job, index) => (
        <JobCard
          key={index}
          title={job.title}
          company={job.company}
          requirements={job.requirements}
          email={job.email}
          phone={job.phone}
          logo={job.logo}
          id={job.id}
        />
      ))}
    </div>
  );
};

export default JobList;
