import React, { useEffect, useState, ChangeEvent } from 'react';
import { Space, Table, Tag, Button, Modal, Form, Input, message, Select } from 'antd';
import type { ColumnsType } from 'antd/es/table';
import axios from 'axios';
import { useSelector } from 'react-redux';
import {translate} from '../../helpers/translate';
import type { SelectProps } from 'antd';
import { RootState } from '../../redux/store';
import { InternType, Monhoc } from '../../interface';
import { isEditable } from '@testing-library/user-event/dist/utils';
const EnterpiseRequirement: React.FC = () => {
    const [expandedRowKey, setExpandedRowKey] = useState<any|null>(null);
    const [initialJDs, setInitialJDs] = useState<string[]>([]);
    const [internsForSubjectId, setInternsForSubjectId] = useState<InternType[]>([]);
    const [workingLocation, setWorkingLocation] = useState<string[]>([]);
    const [optionsJDs, setOptionsJDs] = useState<SelectProps['options']>([]);
    const [optionJobMajor, setOptionJobMajor] = useState<SelectProps['options']>([]);
    const [jdUrl, setJdUrl] = useState('');
    const [expandedRowKeys, setExpandedRowKeys] = useState<React.Key[]>([]);
    const universities = useSelector((state:RootState)=>state.university);
    const token = localStorage.getItem('token');
    const [deleteItem, setDeleteItem] = useState(0); 
    const [abbreation, setAbbreation] = useState('000');
    const [requirement, setRequirement] = useState('');
    const [fileList, setFileList] = useState<File>();
    const lang = localStorage.getItem("language")!=null?
                localStorage.getItem("language"):"en";
    const handleChange1 = (value: string[]) => {
      setWorkingLocation(value);   
    };
    const handleSubjectSelection = (value: string)=>{
      setTenmonhoc(value);
    }
    const handleChange2 = (value: string[]) => {
        setInitialJDs(value);
    };
    const getSubjectDataForEnterpise = () => {
      fetch(process.env.REACT_APP_API_URL+'api/Subject/enterprise/admin@alta.com.vn',{
          method: 'GET',
          headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          }
}).then(response => response.json())
.then((data)=>{
    setOptionJobMajor(data.map((item:any)=>{
      return {value: item.abbreation, label:item.tenmonhoc}
    }));
})
}
    const getInternsBySubjectId = async (id:number) => {
      await fetch(process.env.REACT_APP_API_URL+"api/ProvidedCv/" + localStorage.getItem('emailData') + "/" + id, {
        method: 'GET',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`,
        },
}).then(response => response.json())
.then(async(data)=>{
  console.log(data);
  let tempData: InternType[] = [];
  await data.map((item:any)=>{
    tempData.push({
      key: item.id,
      id: item.id,
      internName: item.internName,
      internMail:item.internMail,
      dateOfBirth:item.dateOfBirth,
      university: universities.filter(x=>x.matruong==item.university)[0]!=null?
      universities.filter(x=>x.matruong==item.university)[0].tentruong:'',
      fileUrl:item.fileUrl,
      internSpecialized:item.internSpecialized,
      telephoneNum: item.telephoneNum,
      internable: item.internable==1?'Thực tập':'Chính thức',
      fullTime: item.fullTime==1?'Toàn thời gian':'Bán thời gian',
      major: item.major,
      registeredDate: item.registeredDate,
      citizenIdentification: item.citizenIdentification,
      citizenIdentificationDate: item.citizenIdentificationDate,
      isEditableInternSpecialized:false,
      internStatus: item.internStatus,
      yearOfExperiences: 0,
      foreignLanguage: 'IELTS from 8.0',
      entranceTest: item.entranceTest,
      hiddenToEnterprise: item.hiddenToEnterprise
    })
  })      
  setInternsForSubjectId(tempData);            
})
.catch(err => console.log(err));
    }
    const columns: ColumnsType<any> = [
        {
          title: 'Id',
          dataIndex: 'id',
          key: 'id',
          render: (key, record:any, text)=>{
            return(
              <p style={{textDecoration:record.enabledStatus==false?'line-through':'none'}}>{record.id}</p>
            )
          }
        },
        {
          title: translate(lang!=null?lang:"en", "Lĩnh vực tuyển dụng"),
          dataIndex: 'tenmonhoc',
          key: 'tenmonhoc',
          render: (key, record:any, text)=>{
            return(
              <div>
              {!record.isJobFieldEditable?
              <a style={{textDecoration: record.enabledStatus==false?'line-through':'none'}} onClick={()=>{
                let index = data.findIndex(x=>x.id == record.id);
                if(index>-1){
                  let previous = data.slice(0, index);
                  let after = data.slice(index+1);
                  setData([...previous, {...data[index], isJobFieldEditable: true}, ...after])
                }
              }}>{record.tenmonhoc}</a>:
              <Select
              placeholder="Lĩnh vực chuyên môn"
              onChange={(value:string)=>{
                fetch(process.env.REACT_APP_API_URL+'api/Subject/' + record.id, {
                  method: 'PUT',
                  headers: {
                      'Accept': 'application/json',
                      'Content-Type': 'application/json',
                      'Authorization': `Bearer ${token}`,
                  },
                  body: JSON.stringify({tenmonhoc: value, abbreation: record.abbreation,
                  taxCode: localStorage.getItem("emailData"), createdUser:localStorage.getItem("emailData"),
                  editedUser:localStorage.getItem("emailData"), enabledStatus: record.enabledStatus
                  })
                  })
          .then(response => {
              message.info(translate(lang!=null?lang:'en',"Cập nhật thành công"));
              getAllUniveristy();
          })
          .catch(err => console.log(err));
              }}
              options={optionJobMajor}
            />
              }
              </div>
            )
          }
        },
        {
          title: translate(lang!=null?lang:"en", "Tên vị trí tuyển dụng"),
          dataIndex: 'title',
          key: 'title',
          render: (key, record:any, text)=>{
            return(
              <div>
              {!record.isTitleEditable?
              <a style={{textDecoration: record.enabledStatus==false?'line-through':'none'}}
              onClick={()=>{
                let index = data.findIndex(x=>x.id == record.id);
                if(index>-1){
                  let previous = data.slice(0, index);
                  let after = data.slice(index+1);
                  setData([...previous, {...data[index], isTitleEditable: true}, ...after])
                }
              }}
              >{record.title??"Chưa có dữ liệu"}</a>
              :<Input value={record.title} onChange={(e)=>{
                let index = data.findIndex(x=>x.id == record.id);
                if(index>-1){
                  let previous = data.slice(0, index);
                  let after = data.slice(index+1);
                  setData([...previous, {...data[index], title:e.currentTarget.value}, ...after])
                }
              }}
              onKeyPress={(e)=>{
                if(e.key=='Enter'){
                  fetch(process.env.REACT_APP_API_URL+'api/Subject/' + record.id, {
                    method: 'PUT',
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${token}`,
                    },
                    body: JSON.stringify({abbreation: record.abbreation, title: record.title,
                    taxCode: localStorage.getItem("emailData"), createdUser:localStorage.getItem("emailData"),
                    editedUser:localStorage.getItem("emailData"), enabledStatus: record.enabledStatus
                    })
                    })
            .then(response => {
                message.info(translate(lang!=null?lang:'en',"Cập nhật thành công"));
                getAllUniveristy();
            })
            .catch(err => console.log(err));
                }
              }}
             />
          }
              </div>
            )
          }
        },
        {
          title: translate(lang!=null?lang:'en', 'Nơi làm việc:'),
          dataIndex: 'requirement',
          key: 'requirement',
          render: (key, record:any, text)=>{
            return(
              <>
              {localStorage.getItem('emailData') != 'test@alta.com.vn' ? 
              record.isEditable?<div>
              <Select style={{width:'300px'}}
                  mode="multiple"
                  defaultValue={record.requirement}
                  onChange={handleChange2}
                  options={optionsJDs}
              />&nbsp;&nbsp;<a href='#' onClick={()=>{
                const token = localStorage.getItem('token');
                let workingLocations = '';
                initialJDs.map((a:string)=>{
                  workingLocations += a + '___';
                })
                  fetch(process.env.REACT_APP_API_URL+'api/Subject/' + record.id, {
                      method: 'PUT',
                      headers: {
                          'Accept': 'application/json',
                          'Content-Type': 'application/json',
                          'Authorization': `Bearer ${token}`,
                      },
                      body: JSON.stringify({abbreation: record.abbreation, taxCode: localStorage.getItem("emailData"), 
                      requirement: workingLocations, createdUser:localStorage.getItem("emailData"),
                      editedUser:localStorage.getItem("emailData"), enabledStatus: record.enabledStatus
                      })
                      })
              .then(response => {
                  message.info(translate(lang!=null?lang:'en',"Cập nhật thành công"));
                  getAllUniveristy();
              })
              .catch(err => console.log(err));
              let currentIndex = data.findIndex(c=>c.id==record.id);
              if(currentIndex!=-1){
                      let previous = data.slice(0,currentIndex);
                      let after = data.slice(currentIndex+1);
                      data[currentIndex].isEditable = false;
                      setData([...previous, data[currentIndex], ...after]);
              }
            }}>Lưu</a></div>
            :<div><Select style={{width:'300px'}}
            mode="multiple" disabled={true}
            value={record.requirement}
            onChange={handleChange2}
            options={optionsJDs}
        />&nbsp;&nbsp;<a href='#' onClick={()=>{
          let currentIndex = data.findIndex(c=>c.id==record.id);
          if(currentIndex!=-1){
                  let previous = data.slice(0,currentIndex);
                  let after = data.slice(currentIndex+1);
                  data[currentIndex].isEditable = true;
                  setData([...previous, data[currentIndex], ...after]);
          }
        }}>Cập nhật</a></div>
              :<p>{''}</p>}
            </>
            )
          }
        },
        {
          title: translate(lang!=null?lang:'en','Download JD'),
          dataIndex:'downloadJd',
          key: 'downloadJd',
          render:(key:number, record: any, index) => {
            return(
              <a href="#" onClick={()=>{
                fetch(process.env.REACT_APP_API_URL+'api/Subject/files/' + record.id.toString(), {
                    method: 'GET',
                    headers: {
                        'Authorization': `Bearer ${token}`,
                    },
            })
            .then(response => response.blob())
        .then(blob => {
              var url = window.URL.createObjectURL(blob);
                const a = document.createElement('a');
                a.href = url;
                a.target = '_blank';
                a.style.display = 'none';
                document.body.appendChild(a);
                a.click();
                document.body.removeChild(a);
                setTimeout(() => {
                  window.URL.revokeObjectURL(url);
              }, 2000);      
        })     
              }}>Download JD</a>
            )
          }
        },
        {
          title: translate(lang!=null?lang:'en','Hành động'),
          dataIndex: 'delete',
          key: 'delete',
          render: (key, record:any, text) => {
            return(
              <>
            <Button type="primary" danger
            disabled = {localStorage.getItem('emailData') != 'test@alta.com.vn' ? 
            false : true }
            onClick = {()=>{
              setIsConfirmModalOpen(true);
              setDeleteItem(record.id);
            }}
            >Xóa</Button>
            <Button type="primary" danger
            disabled = {localStorage.getItem('emailData') != 'test@alta.com.vn' ? 
            false : true }
            onClick = {()=>{
              let workingLocations = '';
              record.requirement.map((a:string)=>{
                  workingLocations += a + '___';
                })
              fetch(process.env.REACT_APP_API_URL+'api/Subject/' + record.id, {
                method: 'PUT',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`,
                },
                body: JSON.stringify({tenmonhoc: record.tenmonhoc, abbreation: record.abbreation,
                taxCode: localStorage.getItem("emailData"), requirement: workingLocations, createdUser:localStorage.getItem("emailData"),
                editedUser:localStorage.getItem("emailData"), enabledStatus: record.enabledStatus!=null?!record.enabledStatus:false
                })
                })
        .then(response => {
            message.info(translate(lang!=null?lang:'en',"Cập nhật thành công"));
            getAllUniveristy();
        })
        .catch(err => console.log(err));
            }}
            >{record.enabledStatus != false ? 'Vô hiệu' : 'Kích hoạt'}</Button>
            </>
            ) 
          }
        },
        {
          title: 'Upload JD',
          dataIndex: 'upload',
          key: 'upload',
          render: (key, record:any, text) => {
            return(
            <Button type="primary"
            disabled = {localStorage.getItem('emailData') != 'test@alta.com.vn' ? 
            false : true } 
            onClick = {()=>{
              setIsModalUploadOpen(true);
              setDeleteItem(record.id);
            }}
            >Upload</Button>
            ) 
          }
        }
      ];
    const [title, setTitle] = useState('');
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isModalUploadOpen, setIsModalUploadOpen] = useState(false);
    const [isConfirmModalOpen, setIsConfirmModalOpen] = useState(false);
    const [tenmonhoc, setTenmonhoc] = useState('');
    const handleConfirmOk = (item: number) => {
        fetch(process.env.REACT_APP_API_URL+'api/Subject/' + item, {
            method: 'DELETE',
            headers: {
                'Authorization': `Bearer ${token}`,
            },
    }).then(response => {
        setData(data.filter(c=>c.id!=Number(item)));
    })
    .catch(err => console.log(err));
    }
    const handleConfirmCancel = () => {
      setIsConfirmModalOpen(false);
    }
    const handleOk = () => {
        setIsModalOpen(false);
      };
      const handleUploadOk = () => {
        setIsModalUploadOpen(false);
      };
      const handleCancel = () => {
        setIsModalOpen(false);
      };
      const handleUploadCancel = () => {
        setIsModalUploadOpen(false);
      };
      const showModal = () => {
        setIsModalOpen(true);
      };

    const [data, setData] = useState<any[]>([]);
    const getAllUniveristy = () => {
        fetch(process.env.REACT_APP_API_URL+'api/Subject/enterprise/' + localStorage.getItem('taxCode'), {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`,
            },
    }).then(response => response.json())
    .then((data)=>{
      console.log(data)      
        setData(
          data.map((item:any)=>{
            let myItems = item.requirement.split("___");
            myItems.splice(myItems.findIndex((x:any)=>x==''), myItems.findIndex((x:any)=>x=='')+1);
            console.log(myItems);       
            return(
              {id:item.id, key: item.id, tenmonhoc: item.tenmonhoc, abbreation:item.abbreation, title: item.title, isTitleEditable:false,
              requirement:myItems, isEditable: false, isJobFieldEditable: false, enabledStatus: item.enabledStatus}
            )
          })
        );            
    })
    .catch(err => console.log(err));
    }
    useEffect(()=>{
        const res = fetch('/api/provinces.json')
                    .then(response=>response.json())
                    .then(data=>{
                        setOptionsJDs(data.map((row:any)=>{
                            return(
                              {value: row.AreaCode, label: row.Provinces}
                            )
                        }));
                    }).then(()=>{
                      getAllUniveristy();
                      getSubjectDataForEnterpise();
                    })
    },[]) 
    const onFinish = async (values: any) => {
      const token = localStorage.getItem('token');
      let workingLocations = '';
      workingLocation.map((a:string)=>{
        workingLocations += a + '___';
      })
        fetch(process.env.REACT_APP_API_URL+'api/Subject/', {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`,
            },
            body: JSON.stringify({tenmonhoc: tenmonhoc, abbreation: abbreation, title: title,
            taxCode: localStorage.getItem("taxCode"), requirement: workingLocations, createdUser:localStorage.getItem("emailData"),
            editedUser:localStorage.getItem("emailData")
            })
            })
    .then(response => {
        message.info(translate(lang!=null?lang:'en',"Thêm mới thành công"));
        getAllUniveristy();
    })
    .catch(err => console.log(err));
      };
      const expandedRowRender = (record:Monhoc) => {     
        const columns1: ColumnsType<InternType> = [
          {
            title: 'Id',
            dataIndex: 'id',
            hidden: true,
            key: 'id',
            render:(key:number, record:InternType, text:any)=>{
              return(
                <>
                  <p style={record.hiddenToEnterprise||null?{color:'gray',fontWeight:200}:undefined}>{record.id}</p>
                </>
              )
            }
          },
          {
            title: 'Tên thực tập sinh',
            dataIndex: 'internName',
            key: 'internName',
            render:(key:number, record:InternType, text:any)=>{
              return(
                <>
                  <p style={record.hiddenToEnterprise||null?{color:'gray',fontWeight:200}:undefined}>{record.internName}</p>
                </>
              )
            }
          },
          {
            title: 'Ngày sinh',
            dataIndex: 'dateOfBirth',
            key: 'dateOfBirth',
            render:(key:number, record:InternType, text:any)=>{
              return(
                <>
                  <p style={record.hiddenToEnterprise||null?{color:'gray',fontWeight:200}:undefined}>{record.dateOfBirth}</p>
                </>
              )
            }
          },
          {
            title: 'Ngày đăng ký',
            dataIndex: 'registeredDate',
            key: 'registeredDate',
            render:(key:number, record:InternType, text:any)=>{
              return(
                <>
                  <p style={record.hiddenToEnterprise||null?{color:'gray',fontWeight:200}:undefined}>{record.registeredDate}</p>
                </>
              )
            }
          },
          {
            title: 'Điện thoại',
            dataIndex: 'telephoneNum',
            key: 'telephoneNum',
            render:(key:number, record:InternType, text:any)=>{
              return(
                <>
                  <p style={record.hiddenToEnterprise||null?{color:'gray',fontWeight:200}:undefined}>{record.telephoneNum}</p>
                </>
              )
            }
          },
          {
            title: 'Trường đại học',
            dataIndex: 'university',
            key: 'university',
            render:(key:number, record:InternType, text:any)=>{
              return(
                <>
                  <p style={record.hiddenToEnterprise||null?{color:'gray',fontWeight:200}:undefined}>{record.university}</p>
                </>
              )
            }
          },
          {
            title:'Chuyên ngành',
            dataIndex:'major',
            key: 'major',
            render:(key:number, record:InternType, text:any)=>{
              return(
                <>
                  <p style={record.hiddenToEnterprise||null?{color:'gray',fontWeight:200}:undefined}>{record.major}</p>
                </>
              )
            }
          },
          {
            title: 'Địa chỉ Mail',
            dataIndex: 'internMail',
            key: 'internMail',
            render:(key:number, record:InternType, text:any)=>{
              return(
                <>
                  <p style={record.hiddenToEnterprise||null?{color:'gray',fontWeight:200}:undefined}>{record.internMail}</p>
                </>
              )
            }
          }, 
          {
            title:'Kiểm tra đầu vào',
            dataIndex:'entranceTest',
            key: 'entranceTest',
            render:(key:number, record:InternType, text:any)=>{
              return(
                <>
                  <p style={record.hiddenToEnterprise||null?{color:'gray',fontWeight:200}:undefined}>{record.entranceTest}</p>
                </>
              )
            }
          },      
          {
              title: 'Download CV',
              dataIndex: 'download',
              key: 'download',
              render: (key:number, record:InternType, text:any) => {
                return(
                <a href='#'
                onClick = {()=>{
                  console.log(record.fileUrl);
                  const token = localStorage.getItem('token');
                  fetch(process.env.REACT_APP_API_URL+'api/Register/files/' + record.fileUrl, {
                      method: 'GET',
                      headers: {
                          'Authorization': `Bearer ${token}`,
                      },
              })
              .then(response => response.blob())
          .then(blob => {
              var url = window.URL.createObjectURL(blob);
              var a = document.createElement('a');
              a.href = url;
              a.download = record.fileUrl;
              document.body.appendChild(a); // we need to append the element to the dom -> otherwise it will not work in firefox
              a.click();    
              a.remove();  //afterwards we remove the element again         
          });
              }}
                >Tải CV</a>
                ) 
              }
            }
        ].filter(item=>!item.hidden);
        return <Table columns={columns1} dataSource={internsForSubjectId}
        />
      }

    return(
    <div style={{padding: "30px 20px"}}>
    <Button onClick={showModal}
    disabled = {localStorage.getItem('emailData') != 'test@alta.com.vn'? 
    false : true }
    >{translate(lang!=null?lang:'en','Thêm vị trí tuyển dụng')}</Button>
     <Table columns={columns} dataSource={data} 
     expandable={{ expandedRowRender, expandedRowKeys: expandedRowKey ? [expandedRowKey] : [], 
      onExpand:(expanded:boolean, record:Monhoc)=>{
      getInternsBySubjectId(record.id);
      setExpandedRowKey(expanded ? record.key : null);
    }}} />
    <Modal title={translate(lang!=null?lang:'en',"Thêm vị trí tuyển dụng")} 
    open={isModalOpen} onOk={handleOk} onCancel={handleCancel}>
        <Form labelCol={{ span: 4 }}
        wrapperCol={{ span: 20 }}
        layout="horizontal"
        onFinish={onFinish} >          
        <Form.Item label={translate(lang!=null?lang:'en',"Tên vị trí tuyển dụng:")} 
        name="title"
        rules={[{ required: true, message: translate(lang!=null?lang:'en',"Không được để trống") }]}>
          <Input value={title} onChange={(e)=>setTitle(e.currentTarget.value)} />
        </Form.Item>
        <Form.Item name="tenmonhoc"
      label="Chọn lĩnh vực chuyên môn"
      rules={[{required:true, message:'Mục này không được để trống'}]}
      >
        <Select
          placeholder="Lĩnh vực chuyên môn"
          onChange={handleSubjectSelection}
          options={optionJobMajor}
        />
      </Form.Item>
        <Form.Item name="requirement"
      label="Nơi làm việc:"
      rules={[{required:true, message:'Mục này không được để trống'}]}
      >
        <Select
          mode="multiple"
          placeholder="Nơi làm việc"
          onChange={handleChange1}
          options={optionsJDs}
        />
      </Form.Item>      
        <Form.Item label="" wrapperCol={{offset:4}}>
          <Button type="primary" htmlType="submit">{translate(lang!=null?lang:'en',"Lưu dữ liệu")}</Button>
        </Form.Item>
        </Form>
    </Modal>
    <Modal title={translate(lang!=null?lang:'en',"Xóa dữ liệu")} open={isConfirmModalOpen} onOk={()=>handleConfirmOk(deleteItem)} onCancel={handleConfirmCancel}>
        <div style={{height:'110px', marginTop:'20px'}}>
          <div style={{float:'left', overflow:'hidden'}}>
            <img src={'recyclebin.png'} style={{width:'100px', height:'80px'}} />
          </div>
          <div style={{float:'left', fontSize:'24px', paddingLeft:'10px', color:'red'}}>
          {translate(lang!=null?lang:'en',"Bạn có chắc muốn xóa không?")}
          </div>
        </div>
      </Modal>
    <Modal title="Upload JD" open={isModalUploadOpen} onCancel={handleUploadCancel}
      onOk={()=>{
        const token = localStorage.getItem('token');
        const formData = new FormData();
        if(fileList)
        {
            formData.append("Id", deleteItem.toString());
            formData.append("MyFile", fileList);
        }           
    const config = {     
      headers: { 'content-type': 'multipart/form-data', 'Authorization': `Bearer ${token}` }
    }
     axios.post(process.env.REACT_APP_API_URL+'api/Subject/uploadjd', formData, config)
      .then(response => {
        console.log(response);
        message.info(response.data)
      })
      .catch(error => {
          console.log(error);
      });
    setIsModalUploadOpen(false);      
  }} >
        {translate(lang!=null?lang:'en',"Chọn tập tin:")} <Input type="file" onChange={(e:ChangeEvent<HTMLInputElement>)=>{
  const temp = e.target.files;
  if(!temp) 
    return;
  else
    setFileList(temp[0])}} />
      </Modal>
    </div>
    )
}

export default EnterpiseRequirement;