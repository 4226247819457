import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import { Dispatch } from 'redux';
import { getCurrentWeek } from '../../helpers';
import { stat } from 'fs';
import type { Dayjs } from 'dayjs';

type itemTypes = {id:string, content: string, status:boolean, style: string, result:string, salary:number}
type listWeekDayTaskType = {
    weekday: string, data: itemTypes[]
}[]
const listWeekDayTask = [
    [{weekday: 'monday', data: [] as itemTypes[]},
    {weekday: 'tuesday', data: [] as itemTypes[]},
    {weekday: 'wednesday', data: [] as itemTypes[]},
    {weekday: 'thursday', data: [] as itemTypes[]},
    {weekday: 'friday', data: [] as itemTypes[]},
    {weekday: 'saturday', data: [] as itemTypes[]}],
   
    [{weekday: 'monday', data: [] as itemTypes[]},
    {weekday: 'tuesday', data: [] as itemTypes[]},
    {weekday: 'wednesday', data: [] as itemTypes[]},
    {weekday: 'thursday', data: [] as itemTypes[]},
    {weekday: 'friday', data: [] as itemTypes[]},
    {weekday: 'saturday', data: [] as itemTypes[]}],
]
export const interviewSlice = createSlice({
    name:'interview',
    initialState:listWeekDayTask,
    reducers: ({
        getInterview: (state, action: PayloadAction<listWeekDayTaskType[]>) => {
            state = [];
            return action.payload;
        }
    })
})
export function getInterview(data:Dayjs){
    return async function getTimelineThunk(dispatch: Dispatch, getState:any){
        const token = localStorage.getItem('token');
        const myEmail = localStorage.getItem('userNameData');
        let myNewArrLst = [] as listWeekDayTaskType[];
        let stDate = await getCurrentWeek(data)[0].getFullYear() + '-'
  + (getCurrentWeek(data)[0].getMonth()+1<10?'0'+ (getCurrentWeek(data)[0].getMonth()+1)
  :getCurrentWeek(data)[0].getMonth()+1)+'-'
  + (getCurrentWeek(data)[0].getDate()<10?'0'+getCurrentWeek(data)[0].getDate()
  :getCurrentWeek(data)[0].getDate());
  let edDate = await getCurrentWeek(data)[5].getFullYear() + '-'
  + (getCurrentWeek(data)[5].getMonth()+1<10?'0'+ (getCurrentWeek(data)[5].getMonth()+1)
  :getCurrentWeek(data)[5].getMonth()+1)+'-'
  + (getCurrentWeek(data)[5].getDate()<10?'0'+getCurrentWeek(data)[5].getDate()
  :getCurrentWeek(data)[5].getDate());
        fetch(process.env.REACT_APP_API_URL+'api/Interview/'+stDate+'/'+edDate, {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`,
            },
      }).then(response => response.json())
      .then((data)=>{
          myNewArrLst = [];
          for(let i=0; i<=1; i++){
            myNewArrLst.push(setValue(i, data));
          }
      }).then(async ()=>{
        await dispatch(interviewSlice.actions.getInterview(myNewArrLst))}
      )
    }
    }
export const setValue = (i:number, data:any) => {
    const interviewer = localStorage.getItem("userNameData"); 
    let dayPart1 = i==0 ? data.filter((x:any)=>Number(x.giophongvan.substring(0,2))<12)
    : data.filter((x:any)=>Number(x.giophongvan.substring(0,2))>=12)
    let myNewList = listWeekDayTask[i];
    if(dayPart1 == null)
        myNewList = [{weekday: 'monday', data: [] as itemTypes[]},
      {weekday: 'tuesday', data: [] as itemTypes[]},
      {weekday: 'wednesday', data: [] as itemTypes[]},
      {weekday: 'thursday', data: [] as itemTypes[]},
      {weekday: 'friday', data: [] as itemTypes[]},
      {weekday: 'saturday', data: [] as itemTypes[]}]
    else{
      let mondayTasks = dayPart1.filter((x:any)=>(new Date(x.ngayphongvan)).getDay()==1)
      let tuesdayTasks = dayPart1.filter((x:any)=>(new Date(x.ngayphongvan)).getDay()==2)
      let wednesdayTasks = dayPart1.filter((x:any)=>(new Date(x.ngayphongvan)).getDay()==3)
      let thursdayTasks = dayPart1.filter((x:any)=>(new Date(x.ngayphongvan)).getDay()==4)
      let fridayTasks = dayPart1.filter((x:any)=>(new Date(x.ngayphongvan)).getDay()==5)
      let saturdayTasks = dayPart1.filter((x:any)=>(new Date(x.ngayphongvan)).getDay()==6)
      myNewList = [{weekday: 'monday', data: mondayTasks.map((item:any)=>{
          let myStatus = (item.manhansu == interviewer || 
            ['ALT0001', 'ALT0002', 'ALT0003'].includes(interviewer!=null?interviewer:'')) ? true : false;
          let myStyle = (item.mucluong != '-1'?'line-through':'None');
        return(
          {id:item.manhansu + '-' + item.internid + '_' + item.ngayphongvan + '_' + item.giophongvan, 
          content:item.internName + '-' + item.position + '- Người phỏng vấn: ' 
          + item.interviewer + ' - Lúc: ' + item.giophongvan, status: myStatus, style: myStyle,
          result:item.ketqua, salary:item.mucluong
        }
        )
      }) },
      {weekday: 'tuesday', data: tuesdayTasks.map((item:any)=>{
        let myStatus = (item.manhansu == interviewer || 
          ['ALT0001', 'ALT0002', 'ALT0003'].includes(interviewer!=null?interviewer:'')) ? true : false;
        let myStyle = (item.mucluong != '-1'?'line-through':'None');
        return(
          {id:item.manhansu + '-' + item.internid + '_' + item.ngayphongvan + '_' + item.giophongvan, 
          content:item.internName + '-' + item.position + '- Người phỏng vấn: ' 
          + item.interviewer + ' - Lúc: ' + item.giophongvan, status: myStatus, style: myStyle,
          result:item.ketqua, salary:item.mucluong
        }
        )
      })},
      {weekday: 'wednesday',data: wednesdayTasks.map((item:any)=>{
        let myStyle = (item.mucluong != '-1'?'line-through':'None');
        let myStatus = (item.manhansu == interviewer || 
          ['ALT0001', 'ALT0002', 'ALT0003'].includes(interviewer!=null?interviewer:'')) ? true : false;
        return(
          {id:item.manhansu + '-' + item.internid + '_' + item.ngayphongvan + '_' + item.giophongvan, 
          content:item.internName + '-' + item.position + '- Người phỏng vấn: ' 
          + item.interviewer + ' - Lúc: ' + item.giophongvan, status: myStatus, style: myStyle,
          result:item.ketqua, salary:item.mucluong
        }
        )
      })},
      {weekday: 'thursday', data: thursdayTasks.map((item:any)=>{
        let myStatus = (item.manhansu == interviewer || 
          ['ALT0001', 'ALT0002', 'ALT0003'].includes(interviewer!=null?interviewer:'')) ? true : false;
        let myStyle = (item.mucluong != '-1'?'line-through':'None');
        return(
          {id:item.manhansu + '-' + item.internid + '_' + item.ngayphongvan + '_' + item.giophongvan, 
          content:item.internName + '-' + item.position + '- Người phỏng vấn: ' 
          + item.interviewer + ' - Lúc: ' + item.giophongvan, status: myStatus, style: myStyle,
          result:item.ketqua, salary:item.mucluong
        }
        )
      })},
      {weekday: 'friday', data: fridayTasks.map((item:any)=>{
        let myStyle = (item.mucluong != '-1'?'line-through':'None');
        let myStatus = (item.manhansu == interviewer || 
          ['ALT0001', 'ALT0002', 'ALT0003'].includes(interviewer!=null?interviewer:'')) ? true : false;
        return(
          {id:item.manhansu + '-' + item.internid + '_' + item.ngayphongvan + '_' + item.giophongvan, 
          content:item.internName + '-' + item.position + '- Người phỏng vấn: ' 
          + item.interviewer + ' - Lúc: ' + item.giophongvan, status: myStatus, style: myStyle,
          result:item.ketqua, salary:item.mucluong
        }
        )
      })},
      {weekday: 'saturday', data: saturdayTasks.map((item:any)=>{
        let myStyle = (item.mucluong != '-1'?'line-through':'None');
        let myStatus = (item.manhansu == interviewer || 
          ['ALT0001', 'ALT0002', 'ALT0003'].includes(interviewer!=null?interviewer:'')) ? true : false;
        return(
          {id:item.manhansu + '-' + item.internid + '_' + item.ngayphongvan + '_' + item.giophongvan, 
          content:item.internName + '-' + item.position + '- Người phỏng vấn: ' 
          + item.interviewer + ' - Lúc: ' + item.giophongvan, status: myStatus, style: myStyle,
          result:item.ketqua, salary:item.mucluong
        }
        )
      })}]
    }
    return myNewList;
  }
