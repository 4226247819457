import React, { ChangeEvent, useEffect, useState } from 'react';
import { Select, Table, Checkbox, Radio, Space, Button, message } from 'antd';
import { useAppDispatch } from '../../configs/hook';
import type { SelectProps } from 'antd';
import type { ColumnsType } from 'antd/es/table';
import { useSelector } from 'react-redux';
import { RootState } from '../../redux/store';
import {StudentResultTest, ChangePassword} from '../../interface';
import {updateTestResult, disableTestResult, getTestResult} from '../testResults/testResultSlice';
const EnableTest: React.FC = () => {
  const [emailData, setEmailData] = useState(localStorage.getItem('emailData'));
  const [value, setValue] = useState("modegiuaky");
  const [courseCode, setCourseCode] = useState("");
  const columns: ColumnsType<StudentResultTest> = [
    {
      title: 'Mã số sinh viên',
      dataIndex: 'masinhvien',
      key: 'masinhvien'
    },
    {
      title: 'Họ và tên',
      dataIndex: 'hovaten',
      key: 'hovaten'
    },
    {
        title: 'Mở đề giữa kỳ',
        dataIndex: 'modegiuaky',
        key: 'modegiuaky',
        render:(text: string, record:StudentResultTest, index: any) => {
          return(
            <>
            <Checkbox checked={record.modegiuaky} onChange={(e)=>{
              dispatch(updateTestResult({option:"modegiuaky", studentCode:record.masinhvien, courseCode:courseCode}))
              let previous = testResultsByCourseCode.slice(0, index);
              let next = testResultsByCourseCode.slice(index+1);
              let current = {...testResultsByCourseCode[index], modegiuaky:!testResultsByCourseCode[index].modegiuaky, modecuoiky:testResultsByCourseCode[index].modegiuaky?false:testResultsByCourseCode[index].modecuoiky}
              setTestResultsByCourseCode([...previous, current, ...next])
            }} />
            </>
          )
        }
    },
    {
        title: 'Mở đề cuối kỳ',
        dataIndex: 'modecuoiky',
        key: 'modecuoiky',
        render:(text: string, record:StudentResultTest, index: number) => {
          return(
            <Checkbox checked={record.modecuoiky} onChange={(e)=>{
              dispatch(updateTestResult({option:"modecuoiky", studentCode:record.masinhvien, 
              courseCode:courseCode}))
              let previous = testResultsByCourseCode.slice(0, index);
              let next = testResultsByCourseCode.slice(index+1);
              let current = {...testResultsByCourseCode[index], modecuoiky:!testResultsByCourseCode[index].modecuoiky, modegiuaky:testResultsByCourseCode[index].modecuoiky?false:testResultsByCourseCode[index].modegiuaky}
              setTestResultsByCourseCode([...previous, current, ...next])
            }} />
          )
        }
    }
  ];
  const [options, setOptions] = useState<SelectProps['options']>([]);
  const dispatch = useAppDispatch();
  const myTestResults = useSelector((state:RootState)=>state.testResults);
  const [testResultsByCourseCode, setTestResultsByCourseCode] = useState<StudentResultTest[]>([])
  useEffect(()=>{
    setOptions(myTestResults.map(item=>{
      return {value: item.malophocphan, label:item.tenlophocphan}
    }))
  },[])
  const handleChange = (value: string) => {
    let index = myTestResults.findIndex(c=>c.malophocphan==value);
    setTestResultsByCourseCode(myTestResults[index].studentResultTests);
    setCourseCode(value);
  };
  console.log(testResultsByCourseCode);
  const [selectedRowKeys, setSelectedRowKeys] = useState(testResultsByCourseCode
    .filter(item=>value=="modegiuaky"?item.modegiuaky:item.modecuoiky).map(item=>item.key))
  const [selectedRows, setSelectedRows] = useState<StudentResultTest[]>([]);
  useEffect(()=>{
    setSelectedRowKeys(testResultsByCourseCode
      .filter(item=>value=="modegiuaky"?item.modegiuaky:item.modecuoiky).map(item=>item.key))
  },[testResultsByCourseCode, value])
  const rowSelection = {
    selectedRowKeys,
    onChange: (selectedRowKeys: React.Key[], selectedRows:StudentResultTest[]) => {
      console.log(`selectedRowKeys: ${selectedRowKeys}`, 'selectedRows: ', selectedRows);
      setSelectedRowKeys(selectedRowKeys);
      setSelectedRows(selectedRows);
    },
    getCheckboxProps: (record:StudentResultTest) => ({
      disabled: record.masinhvien === 'Disabled User', // Column configuration not to be checked
      name: record.masinhvien,
    }),
  };
  console.log(selectedRowKeys);
  return (
    <>
        <Select
        style={{ width: '40%', marginRight: '20px' }}
        onChange={handleChange}
        options={options}
        />
        <Radio.Group onChange={(e)=>setValue(e.target.value)} value={value}>
            <Space direction="horizontal">
                 <Radio value="modegiuaky" key="modegiuaky">Mở đề giữa kỳ</Radio>
                 <Radio value="modecuoiky" key="modecuoiky">Mở đề cuối kỳ</Radio>                
            </Space>
        </Radio.Group>
        <Button onClick={async ()=>{
          await selectedRows.map((item:StudentResultTest)=>{
            dispatch(updateTestResult({option:value, studentCode:item.masinhvien, 
            courseCode:courseCode}));
          let unSelectedRows = testResultsByCourseCode.filter(c=>!selectedRows.includes(c));
          unSelectedRows.map(async (item:StudentResultTest)=>{
          await dispatch(disableTestResult({option:value, studentCode:item.masinhvien, 
            courseCode:courseCode}));
           })
          dispatch(getTestResult(emailData!=null?emailData:''));
          })       
        }}>Mở đề thi</Button>
        <Button onClick={async()=>{
          const token = localStorage.getItem('token');
          await selectedRows.map(async (item)=>{
            const rawResponse = await fetch(process.env.REACT_APP_API_URL+'api/User', {
              method: 'POST',
              headers: {
                  'Accept': 'application/json',
                  'Content-Type': 'application/json',
                  'Authorization': `Bearer ${token}`,
              },
          body: JSON.stringify({role:"1", userName:item.email, oldPassword:"", newPassword:"123456",
          mustChangeAtFirstTime:true})
      }).then(response => response.json())
      .then((data)=>{
      })
      .catch(err => console.log(err));
          })         
          message.info("Mật khẩu đã được reset!!!");
        }}>Reset mật khẩu</Button>
        <Table
        rowSelection={{
          type: 'checkbox',
          ...rowSelection,
        }}
        dataSource={testResultsByCourseCode} columns={columns} />
    </>
  );
};
export default EnableTest;