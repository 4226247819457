import React, {useState,useEffect} from 'react';
import { Select, Button, message } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import { useSelector } from 'react-redux';
import { RootState } from '../../redux/store';
import { AnswerRequest, Subject } from '../../interface';
import { useAppDispatch } from '../../configs/hook';
import { getDapan, getDethi, submitBailam } from '../thitracnghiem/tracnghiemSlice';
import './styles.css';

const { Option } = Select;
type ExaminerInfoProps = {
  handleCreateTest:(data:number, course:Subject, hours:number, minutes:number, seconds: number)=>void
}
const ExaminerInfo = (props:ExaminerInfoProps) => {
  const myDetails = useSelector((state:RootState)=>state.login);
  const [mySubjects, setMySubjects] = useState<any[]>([]);
  const dispatch = useAppDispatch();
  const [subjectId, setSubjectId] = useState<Subject>({} as Subject);
  const [configApp, setConfigApp] = useState<any>();
  const [selectedSubject, setSelectedSubject] = useState<{id: string, name: string}|null>(null)
  const handleCreateTest = async () => {
    await dispatch(getDethi(subjectId));
  }
  async function getSubjects(){
    const rawResponse = await fetch(process.env.REACT_APP_API_URL+'api/Subject/enterprise/cuongnv112@fpt.edu.vn', {
        method: 'GET',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
        }
}).then(response => {
  if(response.status==401){
    message.info("Phiên đăng nhập đã hết hạn!");
    localStorage.clear();
    window.location.reload();
}
return response.json()
})
.then((data)=>{
  setSelectedSubject({id: data[0].id, 
    name:data[0].title})
    setMySubjects(data);
})
.catch(err => console.log(err));
}
useEffect(()=>{
  if(localStorage.getItem('role') != null){
    const token = localStorage.getItem('token');
    fetch(process.env.REACT_APP_API_URL+'api/Admin/testconfig', {
      method: 'GET',
      headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`,
      },
}).then(response => {
  if(response.status==401){
    message.info("Phiên đăng nhập đã hết hạn!");
    localStorage.clear();
    window.location.reload();
}
return response.json()
})
.then((data)=>{
  console.log(data);
  setConfigApp(data);
})
}
else{
  getSubjects();
}
  },[])
  return (
    <div className="containerExamInfo">
      <div className="greeting">
        Xin chào <span>{localStorage.getItem('userFullName')}</span>
      </div>
      <div className="form-container">
        {localStorage.getItem('role')==null?mySubjects.length>0?
        <Select defaultValue="Chọn môn thi" className="select-subject" onChange={(value)=>{
          setSelectedSubject({id: value, 
            name: mySubjects.filter(x=>x.id == value)[0].title})
        }}>
                {mySubjects.map((item)=>{
                    return(
                        <Option value={item.id}>{item.title}</Option>
                    );
                })}
        </Select>:<p>Loading...</p>
      :myDetails!=null?<Select defaultValue="Chọn môn thi" className="select-subject" onChange={(value)=>{
        let index = myDetails.findIndex(c=>c.subjectId==Number(value));
        index = index > -1 ? index : 0; 
        setSubjectId(myDetails[index]);
        }}>
        {myDetails.map((item:Subject)=>{
        return(
                <Option value={item.subjectId}>{item.subjectName}</Option>)
        })}
      </Select>:<p>Loading...</p>}
        <Button type="primary" className="create-button" icon={<PlusOutlined />}
          onClick={async()=>{
            if(localStorage.getItem('role')!=null){
            await dispatch(getDethi(subjectId));
            if(localStorage.getItem('message')==''){
              let hours=0; let minutues=0; let seconds = 0;
              hours = Math.floor(subjectId.numberOfQuestions*Number(configApp.numberOfSecondForQuestion)/3600);
              minutues = Math.floor((subjectId.numberOfQuestions*Number(configApp.numberOfSecondForQuestion)-hours*3600)/60);
              seconds = 59;
              props.handleCreateTest(2, subjectId, hours, minutues, seconds)
            }
            else{
                message.info(localStorage.getItem('message'));
            }
            }
            else{
              props.handleCreateTest(2, subjectId, 0, 0, Number(selectedSubject?.id))
            }
          }}
        >
          Tạo đề thi
        </Button>
      </div>
    </div>
  );
};

export default ExaminerInfo;
