import React, { useState, useEffect } from 'react';
import { Table, Button, Modal, Form, Input, Checkbox, message } from 'antd';
import type { ColumnsType } from 'antd/es/table';
import { useAppDispatch } from '../../configs/hook';
import {Admin} from '../../interface';
import axios from 'axios';
import {getAdmins, updateAdmin, deleteAdmin} from '../admin/adminSlide';
import { isDisabled } from '@testing-library/user-event/dist/utils';
import Item from 'antd/es/list/Item';
import { isFulfilled } from '@reduxjs/toolkit';
const Giaovien: React.FC = () => {
    const dispatch = useAppDispatch();
    const [manhansu, setManhansu] = useState("");
    const [adminData, setAdminData] = useState<Admin[]>([]);
    const updateAdmin = async (item:Admin) => {
      const token = localStorage.getItem('token');
      const formData = new FormData();
          formData.append("Manhansu", item.manhansu);
          formData.append("Hovaten", item.hovaten);
          formData.append("Email", item.email);
          formData.append("Password", "abc123!");
          formData.append("Dienthoai", item.dienthoai);
          const config = {     
            headers: { 'content-type': 'multipart/form-data', 'Authorization': `Bearer ${token}` }
          }
          axios.put(process.env.REACT_APP_API_URL+'api/Admin/'+ item.manhansu,formData, config)
            .then(response => {
                if(response.status==401){
                    message.info("Phiên đăng nhập đã hết hạn!");
                    localStorage.clear();
                    window.location.reload();
                }
            })
            .catch(error => {
                console.log(error);
            });
    }
      const columns: ColumnsType<Admin> = [
        {
          title: 'Mã nhân sự',
          dataIndex: 'manhansu',
          key: 'manhansu',
        },
        {
          title: 'Họ và tên',
          dataIndex: 'hovaten',
          key: 'hovaten',
          render: (key, record:Admin, text)=>{
            return(
              <a>{record.hovaten}</a>
            )
          }
        },
        {
          title: 'Email',
          dataIndex: 'email',
          key: 'email',
          render: (key, record:Admin, text) => {
              return(
              <>
              {localStorage.getItem('emailData') != 'test@alta.com.vn' ?
              localStorage.getItem('taxCode') != record.email ?
              !record.isEditableEmail?
               <a onClick={()=>{
                  let index = adminData.findIndex(c=>c.manhansu==record.manhansu);
                  let prefix = adminData.slice(0, index);
                  let mySlice = {...adminData[index], isEditableEmail: true}
                  let suffix = adminData.slice(index+1);
                  setAdminData([...prefix, mySlice, ...suffix])
               }}>{record.email}</a>
               :<Input type="text" value={record.email}
               onChange={(e)=>{
                let index = adminData.findIndex(c=>c.manhansu==record.manhansu);
                let prefix = adminData.slice(0, index);
                let mySlice = {...adminData[index], email: e.currentTarget.value}
                let suffix = adminData.slice(index+1);
                setAdminData([...prefix, mySlice, ...suffix])
               }}
               onKeyPress={(e)=>{
                 if(e.key == "Enter"){
                  let myRecord = adminData.find(c=>c.manhansu==record.manhansu);
                  setAdminData(adminData.map(item=>{
                    if(item.manhansu==record.manhansu){
                      return {...item, isEditableEmail: false}
                    }
                    else
                      return item
                  }))
                    updateAdmin(record);
                 }
               }}
               />
              : <p>
                {record.email}
              </p>:<p>
                {record.email}
              </p>
              }
              </>
              )
          }
        },
        {
          title: 'Điện thoại',
          dataIndex: 'dienthoai',
          key: 'dienthoai',
          render: (key, record:Admin, text) => {
            return(
            <>
            {localStorage.getItem('emailData') != 'test@alta.com.vn'
            ?
            !record.isEditableDienthoai?
             <a onClick={()=>{
                 let index = adminData.findIndex(c=>c.manhansu==record.manhansu);
                  let prefix = adminData.slice(0, index);
                  let mySlice = {...adminData[index], isEditableDienthoai: true}
                  let suffix = adminData.slice(index+1);
                  setAdminData([...prefix, mySlice, ...suffix])
             }}>{record.dienthoai}</a>
             :<input type="text" value={record.dienthoai}
             onChange={(e)=>{
              let index = adminData.findIndex(c=>c.manhansu==record.manhansu);
              let prefix = adminData.slice(0, index);
              let mySlice = {...adminData[index], dienthoai: e.currentTarget.value}
              let suffix = adminData.slice(index+1);
              setAdminData([...prefix, mySlice, ...suffix])
             }}
             onKeyPress={(e)=>{
               if(e.key == "Enter"){
                setAdminData(adminData.map(item=>{
                  if(item.manhansu==record.manhansu)
                    return {...item, isEditableDienthoai: false};
                  else
                    return item;
                }))
                updateAdmin(record)
          }
             }}
             />
            : <p>{record.dienthoai}</p>
            }
            </>
            )
        }
        },
        {
          title: 'Hành động',
          dataIndex: 'delete',
          key: 'delete',
          render: (key, record:Admin, text) => {
            return(
          <div>
            <Button type="primary" danger
            disabled = {localStorage.getItem('emailData') == 'test@alta.com.vn' ||
            localStorage.getItem('taxCode') == record.email ? 
            true : false } 
            onClick = {()=>{
              setManhansu(record.manhansu);             
              setIsConfirmModalOpen(true);
            }}
            >Xóa</Button>
            <Button type="primary"
            disabled = {localStorage.getItem('emailData') != 'test@alta.com.vn' ? 
            false : true } 
            onClick = {()=>{
              const token = localStorage.getItem('token');
              const rawResponse = fetch(process.env.REACT_APP_API_URL+'api/User', {
                    method: 'POST',
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${token}`,
                    },
                body: JSON.stringify({role:localStorage.getItem("roleData"), userName:record.email, oldPassword:"", 
                newPassword:'abc123!', mustChangeAtFirstTime: true})
            }).then(response => response.json())
            .then((data)=>{
                  message.info("Mật khẩu đã được reset");
            })
            .catch(err => console.log(err));
            }}
            >Reset Mật khẩu</Button>
          </div>
            ) 
          }
        }
      ];
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isConfirmModalOpen, setIsConfirmModalOpen] = useState(false);
    const handleConfirmOk = async (item: string) => {
      const token = localStorage.getItem('token');
      const rawResponse = await fetch(process.env.REACT_APP_API_URL+'api/Admin/'+ manhansu, {
        method: 'DELETE',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`, // notice the Bearer before your token
        },
      });
      const msg = await rawResponse.json();
      console.log(msg);
      setIsConfirmModalOpen(false);
    }
    const handleConfirmCancel = () => {
      setIsConfirmModalOpen(false);
    }
    const handleOk = () => {
        setIsModalOpen(false);
      };
      const handleCancel = () => {
        setIsModalOpen(false);
      };
      const showModal = () => {
        setIsModalOpen(true);
      };
    const [hovaten, setHovaten] = useState("");
    const [email, setEmail] = useState("");
    const [dienthoai, setDienthoai] = useState("");
    const onFinish = async (values: any) => {
      const token = localStorage.getItem('token');
      const urlApi = localStorage.getItem('roleData') == '3' ? 'api/Admin/' : 'api/Company/companyuser'
      const formData = new FormData();
          formData.append("Manhansu", localStorage.getItem('roleData') == '3' ? values.manhansu : '00000');
          formData.append("Hovaten", values.hovaten);
          formData.append("Email", values.email);
          formData.append("Password", "abc123!");
          formData.append("Dienthoai", values.dienthoai);
          if(localStorage.getItem('roleData')=='3')
            formData.append("AdminRole", values.adminRole?'0':'1');
          else
            formData.append("TaxCode", localStorage.getItem('taxCode')??'');
          const config = {     
            headers: { 'content-type': 'multipart/form-data', 'Authorization': `Bearer ${token}` }
          }
          axios.post(process.env.REACT_APP_API_URL+urlApi,formData, config)
            .then(response => {
                  message.info("Them moi thành công");
                  getAdminData();
                  setIsModalOpen(false);
            })
            .catch(error => {
                console.log(error);
            });
      };
    const getAdminData = async () => {
      const token = localStorage.getItem('token');
      const roleData = localStorage.getItem('roleData');
      const taxCode = localStorage.getItem('taxCode')??'admin@alta.com.vn';
      const rawResponse = await fetch(process.env.REACT_APP_API_URL+'api/Admin/'+roleData+'/'+taxCode, {
          method: 'GET',
          headers: {
              'Accept': 'application/json',
              'Content-Type': 'application/json',
              'Authorization': `Bearer ${token}`,
          }
  }).then(response => {
      if(response.status==401){
          message.info("Phiên đăng nhập đã hết hạn!");
          localStorage.clear();
          window.location.reload();
      }
      return response.json()
  })
  .then((data)=>{
      setAdminData(data.map((item:any)=>{
          return {manhansu: item.manhansu,
          hovaten:item.hovaten,
          email:item.email,
          dienthoai:item.dienthoai,
          imageDataUrl:item.imageDataUrl,
          isEditableDienthoai: false,
          isEditableEmail:false
          };
      }))
  })
  .catch(err => console.log(err));
    }
    useEffect(()=>{
      getAdminData();
    },[])
    return(
    <>
    <Button onClick={showModal}
    >Thêm thành viên</Button>
     <Table columns={columns} dataSource={adminData} />
    <Modal title="Thêm thành viên" open={isModalOpen} onOk={handleOk} onCancel={handleCancel}>
        <Form labelCol={{ span: 4 }}
        wrapperCol={{ span: 20 }}
        layout="horizontal"
        onFinish={onFinish} >
          {localStorage.getItem('roleData')=='3'?
            <Form.Item label="Mã nhân sự:" 
        name="manhansu"
        rules={[{ required: true, message: 'Không được để trống!' }]}>
          <Input value={manhansu}
          onChange={(e)=>{
              setManhansu(e.currentTarget.value);
          }
        } />
        </Form.Item>:null}
        <Form.Item label="Họ và tên:" 
        name="hovaten"
        rules={[{ required: true, message: 'Không được để trống!' }]}>
          <Input value={hovaten}
          onChange={(e)=>{
              setHovaten(e.currentTarget.value);
          }
        } />
        </Form.Item>
        <Form.Item label="Email:" 
        name="email"
        rules={[{ required: true, message: 'Không được để trống!' }]}>
          <Input value={email}
          onChange={(e)=>{
              setEmail(e.currentTarget.value);
          }
        } />
        </Form.Item>
        <Form.Item label="Điện thoại:" 
        name="dienthoai"
        rules={[{ required: true, message: 'Không được để trống!' }]}>
          <Input value={email}
          onChange={(e)=>{
              setDienthoai(e.currentTarget.value);
          }
        } />
        </Form.Item>
        {localStorage.getItem('roleData')=='3'?
        <Form.Item label="Vai trò:"
        name="adminRole" valuePropName='checked'>
          <Checkbox>Supper Admin</Checkbox>
        </Form.Item>:null}
        <Form.Item label="" wrapperCol={{offset:4}}>
          <Button type="primary" htmlType="submit">Lưu dữ liệu</Button>
        </Form.Item>
        </Form>
    </Modal>
    <Modal title="Xóa dữ liệu" open={isConfirmModalOpen} onOk={()=>handleConfirmOk(manhansu)} onCancel={handleConfirmCancel}>
        <h3 style={{color:'#FF0000'}}>Bạn có chắc chắn xóa admin {manhansu} này khỏi hệ thống?</h3>
    </Modal>
    </>
    )
}

export default Giaovien;