import React, {useState, useEffect, ChangeEvent} from 'react';
import { Avatar, Modal, Input, message } from 'antd';
import { UserOutlined, LogoutOutlined } from '@ant-design/icons';
import axios from 'axios';
import './header.css';

interface HeaderProps {
    handleSelectItem:(itemIndex:number) => void;
  }
  
const EnterpriseLayoutHeader = (props:HeaderProps) => {
const [companyData, setCompanyData] = useState<any|null>(null);
const [isModalUploadOpen, setIsModalUploadOpen] = useState(false);
const [selectedItem, setSelectedItem] = useState(1);
const [menuOpen, setMenuOpen] = useState(false);
const [fileList, setFileList] = useState<File>();
const toggleMenu = () => {
  setMenuOpen(!menuOpen);
};
useEffect(()=>{
    localStorage.setItem("language", 'vie');
    const mytoken = localStorage.getItem('token');
    const config = {     
      headers: { 'content-type': 'application/json', 'Authorization': `Bearer ${mytoken}` }
    }
     axios.get(process.env.REACT_APP_API_URL+'api/Company/'+localStorage.getItem('taxCode'),config)
      .then(response => {
        console.log(response);
        setCompanyData(response.data);
      })
      .catch(error => {
          console.log(error);
      });
  },[]);  
  return (
    <div className='navContainer' style={{backgroundColor:'#f5f5f5'}}>
    <div className="navbar" style={{backgroundColor:'#f5f5f5'}}>
      <div className="logo-section">
        <img src={companyData==null?"images/logo.png":companyData.logoImage}
        style={{width:'52px', height:'52px'}}
        onClick={()=>setIsModalUploadOpen(true)}
        alt="Logo 5" />
      </div>
      <div className="logo-section" style={{fontSize:22, color:'#ff7e1a', fontWeight:500, marginLeft:'20px'}}>
        {companyData!=null?companyData.companyName:null}
      </div>
      <div className="menu-icon">
            <img src="images/menu.png" style={{width:'52px', height:'52px'}} onClick={toggleMenu}
            alt="Menu Icon"/>
      </div>
    </div>
    <div className={menuOpen==true?'link-section.show':'link-section'}>
      <div className='menuItem'>
      <img src={selectedItem==1?"images/viewJdActive.png":"images/viewJd.png"} />&nbsp;&nbsp;
      <a style={selectedItem==1?{color:'#ff5a00'}:undefined} onClick={()=>{props.handleSelectItem(1)
          setSelectedItem(1);  
      }}
        >{"Vị trí tuyển dụng"}</a>
      </div>
      <div className='menuItem'>
      <img src={selectedItem==2?"images/viewJdActive.png":"images/viewJd.png"} />&nbsp;&nbsp;
      <a style={selectedItem==2?{color:'#ff5a00'}:undefined} onClick={()=>{props.handleSelectItem(2)
          setSelectedItem(2);  
      }}
        >{"Tài khoản người dùng"}</a>
      </div>
        {localStorage.getItem('userFullName')!=null?<div className='menuItem'>
        <LogoutOutlined />&nbsp;&nbsp;<a onClick={()=>{
          localStorage.clear();
          window.location.reload();
        }}>Đăng xuất</a></div>:null}
        <div className="user-profile">
          <Avatar size="large" icon={<UserOutlined />} src="/path/to/user/avatar.png" />
          <span style={{color:'#ffffff', fontWeight:'600'}}>&nbsp;&nbsp;{localStorage.getItem('userFullName')??"Anonymous user"}</span>
        </div>
      </div>
      <Modal title="Thay đổi logo" open={isModalUploadOpen} onCancel={()=>setIsModalUploadOpen(false)}
      onOk={()=>{
        const token = localStorage.getItem('token');
        const formData = new FormData();
        if(fileList)
        {
            formData.append("file", fileList);
        }           
    const config = {     
      headers: { 'content-type': 'multipart/form-data', 'Authorization': `Bearer ${token}` }
    }
     axios.put(process.env.REACT_APP_API_URL+'api/Company/'+localStorage.getItem('emailData'), formData, config)
      .then(response => {
        console.log(response);
        message.info(response.data)
      })
      .catch(error => {
          console.log(error);
      });
    setIsModalUploadOpen(false);      
  }} >
        Chọn tập tin: <Input type="file" onChange={(e:ChangeEvent<HTMLInputElement>)=>{
  const temp = e.target.files;
  if(!temp) 
    return;
  else
    setFileList(temp[0])}} />
      </Modal>
    </div>
  );
};

export default EnterpriseLayoutHeader;
