import React, {useState, useEffect, useRef} from 'react';
import { Input, Avatar } from 'antd';
import { AudioOutlined, PhoneOutlined, VideoCameraOutlined, InfoCircleOutlined, SmileOutlined, PictureOutlined, SendOutlined } from '@ant-design/icons';
import './chat.css';
import useVisibility from './useVisibility';
import { json } from 'stream/consumers';
import { RootState} from '../../redux/store';
import { useSelector } from 'react-redux';
import Message from './message';
import { resolve } from 'path';
const { TextArea } = Input;
const signalR = require('@microsoft/signalr');
const initialMsg = [
  { id: 0, email: '', sender: '', avatar: '', content: '', time: '', sent: false,
    url: '', fileName: '', status:'', groupId: 0
   },
];
type NvcmisChatProp = {
  groupId: number,
  groupArr: {userName: string,
  email: string,
  userAvatar: string,
  isGroup: boolean
  }[],
  handleUpdateGroupList: (groupId:string) => void
}
const NvcmisChat = (props: NvcmisChatProp) => {
  const containerRef = useRef<HTMLDivElement | null>(null);
const [file, setFile] = useState<File|null>(null);
const [connection, setConnection] = useState<any>(null);
const [messages, setMessages] = useState(initialMsg);
const [totalPages, setTotalPages] = useState(1);
const [page, setPage] = useState(1);
const usersSelected = useSelector((state:RootState)=>state.groupUsers);
const [message, setMessage] = useState<string>('');
const [group, setGroup] = useState(props.groupId.toString());
 // const wsService = useRef<{ sendMessage: (msg: string) => void; sendFile: (file:File)=>void; socket: WebSocket } | null>(null);
    const getTotalMessages = async (id: number): Promise<number> => {
      return new Promise((resolve)=>{
        fetch(process.env.REACT_APP_API_URL_FOR_CHAT+'api/Message/totalmessages/' + id + '/' + localStorage.getItem('emailData') + '/10', {
          method: 'GET',
          headers: {
              'Accept': 'application/json',
              'Content-Type': 'application/json'
          }
        }).then(response=>response.json())
        .then((data:any)=>{
            resolve(data);
        })
      })
             
    }
    const simulateApiCall = async (page: number): Promise<any[]> => {
      return new Promise((resolve)=>{
        fetch(process.env.REACT_APP_API_URL_FOR_CHAT+'api/Message/messages/' + group + '/' + localStorage.getItem('emailData') 
        + '/' + page + '/10', {
          method: 'GET',
          headers: {
              'Accept': 'application/json',
              'Content-Type': 'application/json'
          }
        }).then(response=>response.json())
        .then((data:any)=>{
          console.log(data);
          let newMessages:any = [];
          data.map((item:any)=>{
            const myLocalDate = new Date(item.sentDate);
            const fileName = item.id + item.messageContent.substring(item.messageContent.lastIndexOf('.'));
            newMessages.push({
                groupId: item.groupId,
                id: item.id,
                email: item.email,
                sender: item.fullName, /*avatar: props.groupArr.filter(x=>x.email == item.email)[0].userAvatar,*/
                avatar: item.avatar, 
                content: item.messageContent, 
                url: item.fileData != ""?item.fileData:fileName,
                fileName: fileName,
                time: myLocalDate.toLocaleString(), sent: item.sent,
                status: item.seenUsers.length > 0 ? "Đã xem" : "Đã gởi"
            })
          })
          resolve(newMessages);
        });
      })    
    }; 
      const handleSendMessage = async () => {
        if (connection && message.trim() !== '') {
            try {
                await connection.invoke("SendMessageToGroup", group, message, localStorage.getItem('emailData'));
                setMessage('');
            } catch (err:any) {
                console.error("Send message failed: ", err.toString());
            }
        }
    };
      const handleSendFile = async () => {
        if(file){
          const form = new FormData();
          form.append('MessageContent', file.name);
          form.append('FileAttact', file);
            fetch(process.env.REACT_APP_API_URL_FOR_CHAT+'api/Message/sendfile/' + group + '/' + localStorage.getItem('emailData'),{
              method:'POST',
              body: form
            })
            .then(response=>response.json())
            .then(data=>{
              console.log(data);
              if (connection) {
                try {
                    connection.invoke("SendFileToGroup", data, group, localStorage.getItem('emailData'));
                    setMessage('');
                } catch (err:any) {
                    console.error("Send message failed: ", err.toString());
                }
            }
            })
        };     
      }     
    const handleDownloadFile = (url: string, name: string) => {
      const a = document.createElement('a');
      a.href = url;
      a.download = name;
      a.click();
      URL.revokeObjectURL(url);
    };    
    useEffect(() => {
      const newConnection = new signalR.HubConnectionBuilder()
        .withUrl(process.env.REACT_APP_API_URL_FOR_CHAT + "userActivityHub", {
          skipNegotiation: true, // Ensure this if you only want to use WebSockets
          transport: signalR.HttpTransportType.WebSockets
        })
        .withAutomaticReconnect()
        .configureLogging(signalR.LogLevel.Information)
        .build();
      setConnection(newConnection);
      newConnection.start()
            .then(() => {
                console.log("Connected to SignalR hub");

                // Join the group
                newConnection.invoke("JoinGroup", group)
                    .catch((err:any) => console.error("Join group failed: ", err.toString()));
            })
            .catch((err:any) => console.error("Connection failed: ", err.toString()));

        // Register the event handler for receiving messages
        newConnection.on("ReceiveMessage", (msg: string, email:string, messageId: number, groupId:string) => {
          console.log(groupId);
          props.handleUpdateGroupList(groupId);
          setMessages((prevMessages) => {
            let fullName = ''; let imageData = '';
            let index = usersSelected.findIndex(x=>x.email == email);
            if(index > -1){
                fullName = usersSelected[index].fullName;
                imageData = usersSelected[index].imageData;
            }         
            const content = msg;
            const newMessage = {
                groupId: Number(groupId),
                id: messageId,
                email: email,
                sender: fullName,
                avatar: imageData,  // Provide a default value if Avatar is null
                content: content,
                time: 'just now',
                sent: true,
                url: '',
                fileName: '',
                status: "Đã gởi"
            };  
            if (prevMessages.length > 0) {
                const lastMessage = prevMessages[prevMessages.length - 1];
                if (lastMessage) {
                    newMessage.sent = lastMessage.email !== email ? !lastMessage.sent : lastMessage.sent;
                }
            } 
            return [...prevMessages, newMessage];
        });
        });
        newConnection.on("ReceiveSeenStatus", (msg: number, email:string) => {
          setMessages(prevList => {
            const index = prevList.findIndex(x=>x.id == msg && x.email != email);
            if (index > -1) {
              const updatedMsg = { ...prevList[index], status: "Đã xem" };
              return [
                ...prevList.slice(0, index),
                updatedMsg,
                ...prevList.slice(index + 1)
              ];
            }
            return prevList;
          });
        });
        newConnection.on("ReceiveFile", (msg: string, email:string, url:string, id:number, groupId: string) => {
          setMessages((prevMessages) => {
            let fullName = ''; let imageData = '';
            let index = usersSelected.findIndex(x=>x.email == email);
            if(index > -1){
                fullName = usersSelected[index].fullName;
                imageData = usersSelected[index].imageData;
            }         
            const content = msg;
            const newMessage = {
                groupId:Number(groupId),
                id: id,
                email: email,
                sender: fullName,
                avatar: imageData,  // Provide a default value if Avatar is null
                content: content,
                time: 'just now',
                sent: true,
                url: url,
                fileName: content,
                status: "Đã gửi"
            };  
            if (prevMessages.length > 0) {
                const lastMessage = prevMessages[prevMessages.length - 1];
                if (lastMessage) {
                    newMessage.sent = lastMessage.email !== email ? !lastMessage.sent : lastMessage.sent;
                }
            } 
            return [...prevMessages, newMessage];
        });
        });
        // Cleanup function to leave the group and stop the connection
        // return () => {
        //     newConnection.invoke("LeaveGroup", group)
        //         .catch((err:any) => console.error("Leave group failed: ", err.toString()));
        //     newConnection.stop().catch((err:any) => console.error("Stop connection failed: ", err.toString()));
        // };
        const myPromis = new Promise<number>((resolve)=>{
          getTotalMessages(props.groupId)
          .then((data:number)=>{
            resolve(data);
          })
        })
        .then((data)=>{
            fetchInitialItems(data);
        })
    }, [group]);
    const fetchInitialItems = async (data:number) => {
      const newItems = await simulateApiCall(data);
      setMessages(newItems);
      setPage(data-1); 
      // Scroll to the bottom of the div after the initial items are loaded
      setTimeout(() => {
        if (containerRef.current) {
          containerRef.current.scrollTop = containerRef.current.scrollHeight;
        }
      }, 0);
    };  
    // Fetch more items when scrolling up
    const fetchMoreItems = async () => {
      if (page < 1) return; // Stop fetching if page is greater than 10
      const newItems = await simulateApiCall(page);
  
      // Keep track of the current scroll position before appending new items
      const container = containerRef.current;
      const previousScrollHeight = container?.scrollHeight ?? 0;  
      setMessages((prevItems) => [...newItems, ...prevItems]);
      setPage((prevPage) => prevPage - 1);
  
      // Maintain the user's scroll position after new items are added
      setTimeout(() => {
        if (container) {
          container.scrollTop = container.scrollHeight - previousScrollHeight;
        }
      }, 0);
    };
  
    const handleScroll = () => {
      const container = containerRef.current;
      if (container) {
        if (container.scrollTop === 0 && page >= 1) {
          fetchMoreItems();
        }
      }
    };
  useEffect(()=>{
    if(containerRef.current){
      const container = containerRef.current;
      if(container.scrollHeight <= container.clientHeight){
        fetchMoreItems();
      }
    }
  },[messages])
    useEffect(()=>{
      setMessages(initialMsg);
      setGroup(props.groupId.toString());       
    },[props.groupId]);
  return (
    <div className="chat-container">
      <div className="chat-header">
        <Avatar src={props.groupArr[0].userAvatar} />
        <div className="chat-header-info">
          <h3>{props.groupArr[0].userName}</h3>
          <span>Active 10m ago</span>
        </div>
        <div className="chat-header-actions">
          <PhoneOutlined />
          <VideoCameraOutlined />
          <AudioOutlined />
          <InfoCircleOutlined />
        </div>
      </div>
      <div ref={containerRef} onScroll={handleScroll} className="chat-messages">
        {
        messages.map((msg, index) => {           
          return  (
            <Message messageDetail={msg} groupId={Number(group)} connnection={connection} />
        )})
      }
      </div>
      <div className="chat-input">
        <input style={{width:'30%'}} type='file' onChange={(e) => {
          if (e.target.files && e.target.files.length > 0) {
            setFile(e.target.files[0]);
          }
        }} /><button style={{width:'145px'}} onClick={handleSendFile}>Send file</button>
        <Input value={message}
        onChange={(e)=>setMessage(e.currentTarget.value)}
        onKeyDown={(e)=>{
            if(e.key == 'Enter'){
                handleSendMessage();
            }
        }} />
      </div>
    </div>
  );
};
export default NvcmisChat;
