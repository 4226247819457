import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import { Dispatch } from 'redux';
import {message} from 'antd';
export const groupUserSlice = createSlice({
    name:'groupUsers',
    initialState:[] as {email:string, fullName: string,
        phoneNumber: string, imageData: string
      }[],
    reducers: ({
        getGroupUsers: (state, action: PayloadAction<{email:string, fullName: string,
            phoneNumber: string, imageData: string
          }[]>) => {
            state = [];
            return action.payload;
        },
    })
})
export function getGroupUsers(groupId: number){
    return async function getGroupUsersThunk(dispatch: Dispatch, getState:any){
        const token = localStorage.getItem('token');
        fetch(process.env.REACT_APP_API_URL_FOR_CHAT+'/api/User/' + groupId, {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            }
          }).then(response => {
        if(response.status==401){
            message.info("Phiên đăng nhập đã hết hạn!");
            localStorage.clear();
            window.location.reload();
        }
        return response.json()
    })
    .then((data)=>{
        dispatch(
            groupUserSlice.actions.getGroupUsers(data)
        )
        console.log(getState());
    })
    .catch(err => console.log(err));
    }
}

