import React, {useState, ChangeEvent, useEffect} from "react";
import { useAppDispatch } from '../../configs/hook';
import { useSelector } from 'react-redux';
import { RootState } from '../../redux/store';
import './style.css';
import { Modal, Input } from "antd";
import axios from "axios";
import { getAdmins } from "../admin/adminSlide";
import ChangePass from "../changePass";

export const PersonalInfo = () => {
    const dispatch = useAppDispatch();
    const myAdmins = useSelector((state:RootState)=>state.admins);
    const [fileList, setFileList] = useState<File>();
    const [emailData, setEmailData] = useState(localStorage.getItem('emailData'));
    const [myadmin, setMyadmin] = useState(myAdmins.filter(c=>c.email==emailData)[0]);
    const [isModalOpen, setIsModalOpen] = useState(false);
    useEffect(()=>{
        setMyadmin(myAdmins.filter(c=>c.email==emailData)[0]);
    },myAdmins);
    return(
        <>
            <img src={myadmin.imageDataUrl} 
            style={{width:'300px', height:'400px'}}
            onClick={()=>{
                setIsModalOpen(true);
            }}
            />
            {localStorage.getItem('emailData') != 'test@alta.com.vn' ? 
            <div style={{float:'right', paddingLeft:'20px',
            border:'2px solid gray', paddingTop:'10px'}}>
            <ChangePass /> 
            </div>
            : ""}
            <Modal title="Thay đổi ảnh đại diện" 
            open={isModalOpen} onOk={()=>{
                const token = localStorage.getItem('token');
                const formData = new FormData();
                if(fileList)
                {
                    formData.append("ImageData", fileList);
                    formData.append("Manhansu", myadmin.manhansu);
                    formData.append("Hovaten", myadmin.hovaten);
                    formData.append("Email", myadmin.email);
                    formData.append("Dienthoai", myadmin.dienthoai);
                    
            const config = {     
              headers: { 'content-type': 'multipart/form-data', 'Authorization': `Bearer ${token}` }
            }
             axios.put(process.env.REACT_APP_API_URL+'api/Admin/'+ myadmin.manhansu, formData, config)
              .then(response => {
                dispatch(getAdmins());
                console.log(response);
              })
              .catch(error => {
                  console.log(error);
              });
            setIsModalOpen(false);      
          }}}
            onCancel={()=>setIsModalOpen(false)} >
                Chọn ảnh: <Input type="file" onChange={(e:ChangeEvent<HTMLInputElement>)=>{
          const temp = e.target.files;
          if(!temp) 
            return;
          else
            setFileList(temp[0])}} />
            </Modal>
        </>
    )
}