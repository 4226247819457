import { Input } from 'antd';
import { Layout, Form, Radio, Button, Space, Checkbox } from 'antd';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import React, {useState, useEffect} from 'react';
import { useAppDispatch } from '../../configs/hook';
import {addQuestion} from '../listQuestion/questionSlice';
import type { RadioChangeEvent } from 'antd';
import './TextEditor.css'; // Import your CSS file
type SizeType = Parameters<typeof Form>[0]['size'];

type ChoiceType = {
  phatbieu: string,
  dapan: boolean
}
type AddQuestionProps = {
  handleFinish: (status: boolean) => void
}
export const AddQuestion = (props:AddQuestionProps)=>{
const [componentSize, setComponentSize] = useState<SizeType | 'default'>('default');
const [content, setContent] = useState('');
const dispatch = useAppDispatch();
const onFormLayoutChange = ({ size }: { size: SizeType }) => {
    setComponentSize(size);
};
const [value, setValue] = useState(0);
const [valueDoKho, setValueDoKho] = useState(0);
const onChangeOption = (e:RadioChangeEvent) => {
  setValue(e.target.value);
};
const [luachon, setLuachon] = useState("");
const [choice, setChoice] = useState<ChoiceType[]>([]);
const onChange = (e: RadioChangeEvent) => {
  console.log('radio checked', e.target.value);
  setLuachon(e.target.value);
  setChoice(choice.map(item=>{
    if(item.phatbieu === e.target.value){
      return({...item, dapan: true})
    }
    else{
      return ({...item, dapan: false})
    }
  }));
};

const onFinishFailed = (errorInfo: any) => {
  console.log('Failed:', errorInfo);
};
const onChangeDoKho = (e: RadioChangeEvent) => {
  console.log('radio checked', e.target.value);
  setValueDoKho(e.target.value);
};

const onFinish = async (values: any) => {
    const token = localStorage.getItem('token');
    const rawResponse = await fetch(process.env.REACT_APP_API_URL+'api/cauhoi', {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`, // notice the Bearer before your token
      },
      body: JSON.stringify({
        "phatbieu": values.phatbieu,
        "hinhanh": "",
        "nhieuluachon": values.nhieuluachon==1?true:false,
        "userName": localStorage.getItem("emailData"),
        "loaicauhoi": Number(localStorage.getItem("monhocid")),
        "dokho": valueDoKho,
        "luachonRequests": choice
      })
    });
    if(rawResponse.status == 401){
      localStorage.clear();
      window.location.reload();
    }
    const msg = await rawResponse.json();
    props.handleFinish(false);
  };
    return(
        <Form
        labelCol={{ span: 4 }}
        wrapperCol={{ span: 20 }}
        layout="horizontal"
        initialValues={{ size: componentSize }}
        onFinish={onFinish}
        onValuesChange={onFormLayoutChange}
        size={componentSize as SizeType}
      >
        <Form.Item label="Độ khó:" name="size">
        <Radio.Group onChange={onChangeDoKho} value={value}>
            <Radio value={0}>Lý thuyết</Radio>
            <Radio value={1}>Có vận dụng và suy luận</Radio>
            <Radio value={2}>Có code và suy luận</Radio>
            <Radio value={3}>Nâng cao</Radio>
        </Radio.Group>
        </Form.Item>
        <Form.Item label="Nội dung:" 
        name="phatbieu"
        rules={[{ required: true, message: 'Không được để trống!' }]}>
          <ReactQuill
        value={content}
        onChange={(value)=>{
          setContent(value)
        }}
        modules={{
          toolbar: [
            [{ 'header': '1' }, { 'header': '2' }, { 'font': [] }],
            [{size: []}],
            ['bold', 'italic', 'underline', 'strike', 'blockquote'],
            [{'list': 'ordered'}, {'list': 'bullet'}, 
             {'indent': '-1'}, {'indent': '+1'}],
            ['clean']
          ],
        }}
        formats={[
          'header', 'font', 'size',
          'bold', 'italic', 'underline', 'strike', 'blockquote',
          'list', 'bullet', 'indent'
        ]}
      />
        </Form.Item>
        <Form.Item label="" wrapperCol={{offset:4}} name="nhieuluachon">
          <Radio.Group onChange={onChangeOption} value={value}>
            <Radio value={0}>Một lựa chọn</Radio>
            <Radio value={1}> Nhiều lựa chọn </Radio>
          </Radio.Group>
        </Form.Item>
        <div>
        <Form.Item label="Button">
          <Button onClick={()=>{
            setChoice([...choice, {phatbieu:"", dapan:false}])
          }}>Thêm lựa chọn</Button>
        </Form.Item>
        <Form.Item label="   "
        name="chonlua"
        wrapperCol={{ offset: 4, span: 20 }}
        rules={[{ required: true, message: 'Phải chọn một đáp án!' }]}
        >
          {!value?
          <Radio.Group style={{width:'500px'}} onChange={onChange} value={luachon}>
            <Space direction='vertical' >
            {choice.map((item, index)=>{
              return(
                <Radio value={item.phatbieu}> 
                <Space direction='horizontal'>  
                <Input key={index} value={item.phatbieu}
                onChange={(e)=>{
                  let newChoice = choice.map((a, indx)=>{
                    if(indx==index){
                      return {...a, phatbieu: e.currentTarget.value};
                    }
                    else
                      return a;
                  })
                  setChoice(newChoice);
                }}
                /><Button onClick={async ()=>{
                  let myChoice = await choice.filter(c=>c.phatbieu != item.phatbieu);
                  setChoice(myChoice);
                }}>Xóa</Button>
                </Space>
                </Radio>             
              )
            })}
            </Space>
          </Radio.Group>
          :
          <Space direction='vertical'>
           {choice.map((item, index)=>{
            return(
              <Checkbox onChange={(e)=>{
                setChoice(choice.map(a=>{
                  if(a.phatbieu===item.phatbieu){
                  return(
                  {...a, dapan: !item.dapan}
                )
                  }
                  else
                    return a;
                  }))
              }}>
              <Space direction='horizontal'>
              <Input value={item.phatbieu} 
              onChange={(e)=>{
                let newChoice = choice.map((a, indx)=>{
                  if(indx==index){
                    return {...a, phatbieu: e.currentTarget.value};
                  }
                  else
                    return a;
                })
                setChoice(newChoice);
              }}
              /><Button onClick={async ()=>{
                let myChoice = await choice.filter(c=>c.phatbieu != item.phatbieu);
                setChoice(myChoice);
              }}>Xóa</Button>
              </Space>
              </Checkbox>
            )
          })}
          </Space>}
        </Form.Item>
        </div>
        <Form.Item label="" wrapperCol={{offset:4}}>
          <Button type="primary" htmlType="submit">Lưu dữ liệu</Button>
        </Form.Item>
      </Form>
    )
}

