import React, {useEffect, useState} from 'react';
import { Form, Input, Button, Select, Typography, message } from 'antd';
import { useSelector } from 'react-redux';
import { RootState } from '../../redux/store';
import { useAppDispatch } from '../../configs/hook';
import {getTasks} from '../learningProcess/learningProcessSlice';
import { StudentTask } from '../../interface';
import axios from "axios";
import './internReport.css';
const { Title, Text } = Typography;
const { Option } = Select;
const { TextArea } = Input;

const InternReport: React.FC = () => {
    const dispatch = useAppDispatch();
    const myTasks = useSelector((state:RootState)=>state.learningProcess);
    const masinhvien = localStorage.getItem("userNameData")?.toString();
    const [tomtat, setTomtat] = useState("");
    useEffect(()=>{
        dispatch(getTasks(masinhvien??''));
        console.log(myTasks);
    },[])
  return (
    <div style={styles.container}>
      <Title level={4}>
        Xin chào <Text style={styles.highlight}>{localStorage.getItem('userFullName')}</Text>
      </Title>
      <Form layout="vertical" style={styles.form}
            onFinish={(values:any)=>{
                const token = localStorage.getItem('token');
                      const formData = new FormData();
                if(values.malophocphan!="")
                {
                    formData.append("Filebailam", values.fileList);
                    formData.append("Masinhvien", masinhvien==null?"":masinhvien);
                    formData.append("Malophocphan", values.malophocphan);
                    formData.append("Cahoc", "1");
                    formData.append("Tomtat", values.Tomtat);
                    formData.append("Ngayhoc", myTasks.filter(x=>x.malophocphan == values.malophocphan 
                            && x.masinhvien == masinhvien)[0].ngayhoc.toString());
                  const config = {     
                    headers: { "Content-Type": "multipart/form-data", 'Authorization': `Bearer ${token}` }
                  }
                   axios.put(process.env.REACT_APP_API_URL+'api/Task', formData, config)
                    .then(response => {
                      if(response.status==401){
                        message.info("Phiên đăng nhập đã hết hạn!");
                        localStorage.clear();
                        window.location.reload();
                    }
                      message.info("Dữ liệu đã được cập nhật thành công");
                    })
                    .catch(error => {
                        console.log(error);
                    });
                }
                else
                message.info("File upload và nhóm thực tập không được để trống");
              }}
      >
        <Form.Item label="Chọn lớp / Chọn nhóm thực tập" name="malophocphan">
          <Select>
          {myTasks.map((item:StudentTask)=>item.malophocphan).filter((value, index, self) => 
      self.indexOf(value)===index).map(row=>{
        return(
          <Option value={row}>{row}</Option>
        )
      })}
          </Select>
        </Form.Item>
        <Form.Item label="Link file" name="fileList">
          <Input placeholder="Nhập đường dẫn đến file hoặc link github" />
        </Form.Item>
        <Form.Item label="Nội dung báo cáo" name="Tomtat">
          <TextArea rows={4} placeholder="Nhập nội dung báo cáo" />
        </Form.Item>
        <Form.Item>
          <Button type="primary" htmlType="submit" style={styles.button}>
            Gửi
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
};

const styles = {
  container: {
    maxWidth: '500px',
    margin: '0 auto',
    padding: '20px',
    backgroundColor: '#f5f7fa',
    borderRadius: '8px',
  },
  form: {
    marginTop: '20px',
  },
  highlight: {
    color: '#f57c00',
  },
  button: {
    backgroundColor: '#f57c00',
    borderColor: '#f57c00',
  },
};

export default InternReport;
