// JobSearchHeader.tsx
import React from 'react';
import { translate } from '../../helpers/translate';
import './JobSearchHeader.css';
type JobSearchHeaderProps = {
  lange: string
}
const JobSearchHeader = (props: JobSearchHeaderProps) => {
  return (
    <div className="job-search-header">
      <h1>
        {translate(props.lange,'TÌM')} <span className="highlight">{translate(props.lange,'CÔNG VIỆC MƠ ƯỚC')} &nbsp;</span>
        {props.lange=='Vie'?translate(props.lange,'CỦA BẠN'):''}
        <br />
        {translate(props.lange,'TẠI NGÔI NHÀ MỚI')}
      </h1>
      <p>
       {props.lange=='EN'?'':'Khi bạn đang tìm kiếm một công việc, có một số điều bạn có thể làm để tận dụng tối đa tìm kiếm của bạn'}
      </p>
    </div>
  );
}

export default JobSearchHeader;
