import { configureStore } from "@reduxjs/toolkit";
import {questionSlice, questionCountSlice} from '../components/listQuestion/questionSlice';
import tracnghiemSlice, {dapanSlice, answerSlice} from "../components/thitracnghiem/tracnghiemSlice";
import { loginSlice } from "../components/login/loginSlice";
import { studentSlice } from "../components/student/studentSlice";
import {testResultSlice} from "../components/testResults/testResultSlice";
import { adminSlice } from "../components/admin/adminSlide";
import { teacherSlice } from "../components/teacher/teacherSlice";
import { learningProcessSlice } from "../components/learningProcess/learningProcessSlice";
import { MailPatternSlice } from "../components/internsRegistered/internSlice";
import {timelineSlice} from "../components/timeline/timelineSlice";
import {interviewSlice} from "../components/timeline/interviewSlice";
import { internSlice } from "../layouts/guessLogined/internSlice";
import { subjectSlice } from "../layouts/registers/subjectSlice";
import { universitySlice } from "../layouts/registers/universitySlice";
import { provinceSlice } from "../components/admin/provinceSlice";
import { groupUserSlice } from "./groupUserReducer";
export const store = configureStore({
    reducer: {
        tracnghiem: tracnghiemSlice.reducer,
        answer: answerSlice.reducer,
        questions: questionSlice.reducer,
        questionCount: questionCountSlice.reducer,
        login: loginSlice.reducer,
        student:studentSlice.reducer,
        testResults:testResultSlice.reducer,
        admins: adminSlice.reducer,
        teachers:teacherSlice.reducer,
        learningProcess: learningProcessSlice.reducer,
        mailPattern: MailPatternSlice.reducer,
        timeline: timelineSlice.reducer,
        interview:interviewSlice.reducer,
        intern: internSlice.reducer,
        subject: subjectSlice.reducer,
        university: universitySlice.reducer,
        mydapan: dapanSlice.reducer,
        provinces: provinceSlice.reducer,
        groupUsers: groupUserSlice.reducer
    }
})
export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;