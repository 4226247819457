import React, {useEffect, useState} from 'react';
import { List, Avatar, Input, Badge } from 'antd';
import { EllipsisOutlined, SearchOutlined } from '@ant-design/icons';
import styles from './ContactList.module.css';
import { HubConnectionState } from '@microsoft/signalr';
import { useAppDispatch } from '../../configs/hook';
import {getGroupUsers} from '../../redux/groupUserReducer';
import {debounce} from 'lodash';
const signalR = require('@microsoft/signalr');
const contacts = [
  {groupId: 0, email: 'abc@gmail.com', name: 'Tuan Vu', avatar: 'link_to_avatar', online: true, isGroup:false, unReadCount:0 },
  {groupId: 0, email: 'abc@gmail.com', name: 'Nguyễn Văn Huy', avatar: 'link_to_avatar', online: true, isGroup:false, unReadCount:0 },
  {groupId: 0, email: 'abc@gmail.com', name: 'Lao Hac', avatar: 'link_to_avatar', online: true, isGroup:false, unReadCount:0 },
  {groupId: 0, email: 'abc@gmail.com', name: 'Lý Thanh Thanh', avatar: 'link_to_avatar', online: true, isGroup:false, unReadCount:0 },
];


interface ContactListProp {
    handleChangeGroupId: (id: number, groupArr: {userName: string, email: string, userAvatar: string, isGroup:boolean}[]
    ) => void,
    updatedGroupId: number
}
const ContactList = (props: ContactListProp) => {
  const dispatch = useAppDispatch();
  const userId = localStorage.getItem('emailData');
  const [isConnected, setIsConnected] = useState(false);
    const [listUser, setListUser] = useState(contacts);
    const [connection, setConnection] = useState<any>(null);

    useEffect(() => {
      const newConnection = new signalR.HubConnectionBuilder()
        .withUrl(process.env.REACT_APP_API_URL_FOR_CHAT + "userActivityHub", {
          skipNegotiation: true, // Ensure this if you only want to use WebSockets
          transport: signalR.HttpTransportType.WebSockets
        })
        .withAutomaticReconnect()
        .configureLogging(signalR.LogLevel.Information)
        .build();
        newConnection.on("ReceiveSignal", (groupId:string)=>{
          setListUser(prevList => {
            const index = prevList.findIndex(x => x.groupId === Number(groupId));
            if (index > -1) {
              const updatedUser = { ...prevList[index], unReadCount: prevList[index].unReadCount + 1 };
              return [
                ...prevList.slice(0, index),
                updatedUser,
                ...prevList.slice(index + 1)
              ];
            }
            return prevList;
          });
        })
        newConnection.on("ReceiveSeenStatus", (messageId: number, email:string, groupId:string)=>{
          setListUser(prevList => {
            const index = prevList.findIndex(x => x.groupId === Number(groupId));
            if (index > -1 && email == localStorage.getItem('emailData')) {
              const updatedUser = { ...prevList[index], 
                unReadCount: prevList[index].unReadCount - 1 < 0 ? 0 : prevList[index].unReadCount - 1 };
              return [
                ...prevList.slice(0, index),
                updatedUser,
                ...prevList.slice(index + 1)
              ];
            }
            return prevList;
          });
        })
      setConnection(newConnection);
    }, []);
useEffect(()=>{
   // Replace with actual user ID
  if(connection)
    connection.start().then(()=>{
      setIsConnected(true);
      const sendActivity = debounce(() => {
        connection.invoke('UserActivity', userId);
      },1000);
    
      const activityEvents = ['mousemove', 'keydown', 'click'];
    
      activityEvents.forEach(event => {
        window.addEventListener(event, sendActivity);
      });
      return () => {
        activityEvents.forEach(event => {
          window.removeEventListener(event, sendActivity);
        });
      };    
    })
},[connection])
useEffect(() => {
  if (connection && isConnected) {
    const intervalId = setInterval(() => {
      connection.on("ReceivedOnline", (userId:string, status:boolean) => {
        setListUser(prevList => {
          const index = prevList.findIndex(x => x.email === userId);
          if (index > -1) {
            const updatedUser = { ...prevList[index], online: status };
            return [
              ...prevList.slice(0, index),
              updatedUser,
              ...prevList.slice(index + 1)
            ];
          }
          return prevList;
        });
      });

      connection.on("ReceivedOffline", (userId:string, status:boolean) => {
        setListUser(prevList => {
          const index = prevList.findIndex(x => x.email === userId);
          if (index > -1) {
            const updatedUser = { ...prevList[index], online: status };
            return [
              ...prevList.slice(0, index),
              updatedUser,
              ...prevList.slice(index + 1)
            ];
          }
          return prevList;
        });
      });     
    }, 3000);

    // Cleanup on component unmount or when connection state changes
    return () => clearInterval(intervalId);
  }
}, [connection, isConnected]);
  const getUser = () => {
    const myOption = localStorage.getItem('roleData')=='3'? true:false;
        fetch(process.env.REACT_APP_API_URL_FOR_CHAT+'api/User/' + localStorage.getItem('emailData') + '/' + myOption, {
          method: 'GET',
          headers: {
              'Accept': 'application/json',
              'Content-Type': 'application/json'
          }
        }).then(response=>response.json())
        .then((data:any)=>{
            setListUser(data.map((item:any)=>{
                return {groupId: item.groupId, email: item.email, name: item.fullName, avatar: item.imageData, 
                  online: item.isActive, isGroup: item.isGroup, unReadCount: item.unReadCount}
            }))
        });
    }
    useEffect(()=>{
        getUser();
    },[])
    const handleUserSelect = (email:string, userSelected:string, userAvatar: string, isGroup:boolean) => {     
      fetch(process.env.REACT_APP_API_URL_FOR_CHAT+'api/GroupUser/' + userId + "/" + email, {
        method: 'GET',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
        }
      }).then(response=>response.json())
      .then(data=>{
        let sessionUser = listUser.filter((x:any)=>x.email == localStorage.getItem('emailData'))[0]?.name ?? '';
        let sessionEmail = listUser.filter((x:any)=>x.email == localStorage.getItem('emailData'))[0]?.email??'';
        let sessionAvatar = listUser.filter((x:any)=>x.email == localStorage.getItem('emailData'))[0]?.avatar??'';
        console.log(userSelected);
        console.log(data);
        dispatch(getGroupUsers(Number(data)));
        props.handleChangeGroupId(Number(data), 
        [{userName:userSelected, email:email, userAvatar:userAvatar, isGroup:isGroup}, 
          {userName:sessionUser, email:sessionEmail, userAvatar:sessionAvatar, isGroup: false}])
        })   
    }
  return (
    <div className={styles.contactlist}>
      <div className={styles.header}>
        <button onClick={async ()=>{
          if(connection.state == HubConnectionState.Connected){
            await connection.invoke("UserDisconnected", localStorage.getItem('emailData'));
            connection.stop();
          }
          localStorage.clear();
          window.location.reload();
        }}>Log out</button>
        <div className={styles.headeractions}>
          <Input placeholder="Search" prefix={<SearchOutlined />} />
          <EllipsisOutlined className={styles.ellipsisicon} />
        </div>
      </div>
      <div className={styles.fullHeightList}>
      <List
        itemLayout="horizontal"
        dataSource={listUser}
        renderItem={user => (
          <List.Item>
            <List.Item.Meta
              avatar={<Avatar src={user.avatar} />}
              title={<a onClick={()=>{
                handleUserSelect(user.email, user.name, user.avatar, user.isGroup)
                }}>
              {user.name}&nbsp;&nbsp;{user.online == true ? <span className={styles.onlinestatus}></span>
            :user.online == null ? <span className={styles.idlestatus}></span>:null}</a>}
            />
            <div className="extra-info">
              <Badge count={user.unReadCount} showZero={false} className="unread-count" />
            </div>            
          </List.Item>
        )}
      />
      </div>
    </div>
  );
};
export default ContactList;
