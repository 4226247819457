// Footer.tsx
import React from 'react';
import './footer.css';

const Footer: React.FC = () => {
  return (
    <div className="footer">
      <p>Alta Software - Training Team - 04/2023</p>
    </div>
  );
};

export default Footer;
