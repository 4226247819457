import { Calendar, theme, Modal, Button, message, 
  Select, Form, DatePicker, TimePicker, Input } from 'antd';
import type { CalendarMode } from 'antd/es/calendar/generateCalendar';
import { Dayjs } from 'dayjs';
import dayjs from 'dayjs';
import React, {useState, useEffect} from 'react';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import './style.css';
import {getCurrentWeek, getDateString} from '../../helpers';
import FormItem from 'antd/es/form/FormItem';
import { useSelector } from 'react-redux';
import { RootState } from '../../redux/store';
import { useAppDispatch } from '../../configs/hook';
import type { SelectProps } from 'antd';
import { setValue } from '../timeline/interviewSlice';
import axios from 'axios';
const onPanelChange = (value: Dayjs, mode: CalendarMode) => {
  console.log(value.format('YYYY-MM-DD'), mode);
};
type listWeekDayTaskType = {
  weekday: string, data: itemTypes[]
}[]

type itemTypes = {id:string, content: string, status: boolean, style: string, result:string, salary: number}

export const TimeLineInterviews: React.FC = () => {
  const { token } = theme.useToken();
  const mytoken = localStorage.getItem('token');
  const myEmail = localStorage.getItem('userNameData');
  const myTimeLine = useSelector((state:RootState)=>state.interview);
  const myAdmins = useSelector((state:RootState)=>state.admins);
  const [columns, setColumns] = useState([
    {
      title: '',
      dataIndex: 'dayPart',
      key: 'dayPart',
    },
    {
      title: 'Thứ hai(' + getDateString(getCurrentWeek()[0]) + ')',
      dataIndex: 'monday',
      key: 'monday',
    },
    {
        title: 'Thứ ba(' + getDateString(getCurrentWeek()[1]) + ')',
        dataIndex: 'tuesday',
        key: 'tuesday',
      },
      {
        title: 'Thứ tư(' + getDateString(getCurrentWeek()[2]) + ')',
        dataIndex: 'wednesday',
        key: 'wednesday',
      },
      {
        title: 'Thứ năm(' + getDateString(getCurrentWeek()[3]) + ')',
        dataIndex: 'thursday',
        key: 'thursday',
      },
      {
        title: 'Thứ sáu(' + getDateString(getCurrentWeek()[4]) + ')',
        dataIndex: 'friday',
        key: 'friday',
      },
      {
        title: 'Thứ bảy(' + getDateString(getCurrentWeek()[5]) + ')',
        dataIndex: 'saturday',
        key: 'saturday',
      }
  ]);
  const dispatch = useAppDispatch();
  const [dataSource, setDataSource] = useState([
    {
      dayPart: 'Buổi sáng',
      monday: '',
      tuesday: '',
      wednesday:'',
      thursday:'',
      friday: '',
      saturday:''
    },
    {
      dayPart: 'Buổi chiều',
      monday: '',
      tuesday: '',
      wednesday:'',
      thursday:'',
      friday: '',
      saturday:''
    }
]);

const [myDateOfWeek, setMyDateOfWeek] = useState<Date[]>(getCurrentWeek);
const [currentDate, setCurrentDate] = useState(dayjs(new Date()));
const handleChangeDate = (value: Dayjs) => {
  const token = localStorage.getItem('token');
  let myNewArrLst = [] as listWeekDayTaskType[];
  let stDate = getCurrentWeek(value)[0].getFullYear() + '-'
+ (getCurrentWeek(value)[0].getMonth()+1<10?'0'+ (getCurrentWeek(value)[0].getMonth()+1)
:getCurrentWeek(value)[0].getMonth()+1)+'-'
+ (getCurrentWeek(value)[0].getDate()<10?'0'+getCurrentWeek(value)[0].getDate()
:getCurrentWeek(value)[0].getDate());
let edDate = getCurrentWeek(value)[5].getFullYear() + '-'
+ (getCurrentWeek(value)[5].getMonth()+1<10?'0'+ (getCurrentWeek(value)[5].getMonth()+1)
:getCurrentWeek(value)[5].getMonth()+1)+'-'
+ (getCurrentWeek(value)[5].getDate()<10?'0'+getCurrentWeek(value)[5].getDate()
:getCurrentWeek(value)[5].getDate());
  fetch(process.env.REACT_APP_API_URL+'api/Interview/'+stDate+'/'+edDate, {
      method: 'GET',
      headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`,
      },
}).then(response => response.json())
.then((data)=>{
    myNewArrLst = [];
    for(let i=0; i<=1; i++){
      myNewArrLst.push(setValue(i, data));
    }
}).then(()=>setListWeekDayTask(myNewArrLst));  
setColumns([
{
  title: '',
  dataIndex: 'dayPart',
  key: 'dayPart',
},
{
  title: 'Thứ hai(' + getDateString(getCurrentWeek(value)[0]) + ')',
  dataIndex: 'monday',
  key: 'monday',
},
{
    title: 'Thứ ba(' + getDateString(getCurrentWeek(value)[1]) + ')',
    dataIndex: 'tuesday',
    key: 'tuesday',
  },
  {
    title: 'Thứ tư(' + getDateString(getCurrentWeek(value)[2]) + ')',
    dataIndex: 'wednesday',
    key: 'wednesday',
  },
  {
    title: 'Thứ năm(' + getDateString(getCurrentWeek(value)[3]) + ')',
    dataIndex: 'thursday',
    key: 'thursday',
  },
  {
    title: 'Thứ sáu(' + getDateString(getCurrentWeek(value)[4]) + ')',
    dataIndex: 'friday',
    key: 'friday',
  },
  {
    title: 'Thứ bảy(' + getDateString(getCurrentWeek(value)[5]) + ')',
    dataIndex: 'saturday',
    key: 'saturday',
  }
]);
}
const onChange = (value: Dayjs) => {
  setCurrentDate(value);
  handleChangeDate(value);
};
const items:itemTypes[]=[];
const weekdays = ['monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday'];
const [listWeekDayTask, setListWeekDayTask] = useState(myTimeLine);
const [item, setItem] = useState<itemTypes[]>(items);
const [options, setOptions] = useState<SelectProps['options']>(
  myAdmins.map(item=>{
    return {value: item.manhansu, label:item.hovaten}
  })
);
const [optionsIntern, setOptionsIntern] = useState<SelectProps['options']>([]);
const handleChange =()=>{

}
const handleChange1 =()=>{

}

  const wrapperStyle: React.CSSProperties = {
    width: 300,
    border: `1px solid ${token.colorBorderSecondary}`,
    borderRadius: token.borderRadiusLG,
  };
useEffect(()=>{
    getData();
    handleChangeDate(dayjs(new Date()));
},[])
const [isModalOpen, setIsModalOpen] = useState(false);
const [isModalOpen1, setIsModalOpen1] = useState(false);
const [isModalOpen2, setIsModalOpen2] = useState(false);
const [ngayphongvan, setNgayphongvan] = useState(dayjs(new Date()));
const [giophongvan, setGiophongvan] = useState(dayjs(new Date()));
  const showModal = () => {
    setIsModalOpen(true);
  };
  const showModal2 = () => {
    setIsModalOpen2(true);
  };
  const showModal1 = () => {
    setIsEditableKetqua(false);

    form.setFieldsValue({
      Manhansu: null,
      InternId: null,
      Ngayphongvan:null,
      Giophongvan:null
    })

    setIsModalOpen1(true);
  };
  const handleOk = () => {
    setIsModalOpen(false);
  };

  const handleOk2 = () => {
    const token1 = localStorage.getItem('token');
                      const formData = new FormData();
                      let mns = manhansu;
                      let id = internId;
                      formData.append("Ngayphongvan", ngayphongvan1);
                      console.log(ngayphongvan1);
                      formData.append("Giophongvan", giophongvan1);
                      const config = {     
                          headers: { "Content-Type": "multipart/form-data", 'Authorization': `Bearer ${token1}` }
                      }
                      axios.put(process.env.REACT_APP_API_URL+'api/Interview/delete/'+ mns + '/'+ id, formData, config)
                      .then(response => {
                            message.success(response.data);
                            handleChangeDate(currentDate);
                      })
                      .catch(error => {
                          message.info("Đã có lỗi trong quá trình xóa");
                          console.log(error);
                      });
    setIsModalOpen2(false);
  };
  const [manhansu, setManhansu] = useState('');
  const [internId, setInternId] = useState(-1);
  const getData = () => {
    const token1 = localStorage.getItem('token');
    fetch(process.env.REACT_APP_API_URL+'api/Register/internbystatus/Đợi xếp lịch phỏng vấn', {
      method: 'GET',
      headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token1}`,
      },
}).then(response => response.json())
.then(async(data)=>{
  console.log(data);
let tempData:any[] = [];
await data.map((item:any)=>{
  tempData.push({
    value: item.id,
    label: item.internName + ' - ' + item.internSpecialized + 
    ' - ' + (item.internable==1?'Thực tập':'Chính thức') + ' - ' + item.telephoneNum,
  })
})      
setOptionsIntern(tempData);
console.log(optionsIntern);            
})
.catch(err => console.log(err))
};
const [form] = Form.useForm();
  const handleOk1 = () => {
    setIsModalOpen1(false);
  };

  const onFinish = (values:any) =>{
    const token1 = localStorage.getItem('token');
    const formData = new FormData();
    if(!isEditabelKetqua){
    formData.append("Ngayphongvan", values.Ngayphongvan);
    formData.append("Giophongvan", (new Date(values.Giophongvan)).getHours()+':'
    +(new Date(values.Giophongvan)).getMinutes()+':'+(new Date(values.Giophongvan)).getSeconds());
    formData.append("Manhansu", values.Manhansu);
    formData.append("InternId", values.InternId);
    formData.append("Ketqua", 'false');
    formData.append("Mucluong", '-1');
    const config = {     
      headers: { "Content-Type": "multipart/form-data", 'Authorization': `Bearer ${token1}` }
    }
    axios.post(process.env.REACT_APP_API_URL+'api/Interview', formData, config)
      .then(response => {
            message.success(response.data);
      })
      .catch(error => {
          message.info("Đã có lỗi trong quá trình đăng ký");
          console.log(error);
      });
    }
    else{
    formData.append("Ketqua", values.Ketqua);
    formData.append("Mucluong", values.Mucluong);
    formData.append("Ngayphongvan", ngayphongvan1);
    formData.append("Giophongvan", giophongvan1);
    const config = {     
      headers: { "Content-Type": "multipart/form-data", 'Authorization': `Bearer ${token1}` }
    }
    axios.put(process.env.REACT_APP_API_URL+'api/Interview/'+manhansu + '/'
    + internId, formData, config)
      .then(response => {
            message.success(response.data);
      })
      .catch(error => {
          message.info("Đã có lỗi trong quá trình đăng ký");
          console.log(error);
      });
    }
  }
  const handleCancel = () => {  
    setIsModalOpen(false);
  };
  const handleCancel1 = () => {
    setIsModalOpen1(false);
  };
  const handleCancel2 = () => {
    setIsModalOpen2(false);
  };
  const employeeCode = localStorage.getItem('userNameData');
  const [isEditabelKetqua, setIsEditableKetqua] = useState(false); 
  const [ngayphongvan1, setNgayphongvan1] = useState('');
  const [giophongvan1, setGiophongvan1] = useState('');
  return (
    <>
    <div style={{paddingTop:'0px'}}>
    <Modal title="Xóa lịch phỏng vấn" open={isModalOpen2} onOk={handleOk2} onCancel={handleCancel2}>
      <p>Bạn có chắc chắn xóa lịch phỏng vấn này không?</p>
    </Modal>
      <Modal title="Lịch làm việc" open={isModalOpen} onOk={handleOk} onCancel={handleCancel}>
      <Calendar fullscreen={false} onPanelChange={onPanelChange}
      onChange={onChange}
      />
      </Modal>
      <Modal title="Xếp lịch phỏng vấn" open={isModalOpen1}
      
      onOk={handleOk1} onCancel={handleCancel1}>
      <Form onFinish={onFinish} form={form} style={{border: '1px solid gray', marginTop: 20, paddingTop:20}}
    layout="horizontal"
    labelCol={{ span: 8 }} wrapperCol={{ span: 12 }}
    >
      {['ALT0001', 'ALT0002', 'ALT0003'].includes(employeeCode!=null?employeeCode:'')?
      <>
      <FormItem name="Ngayphongvan" label="Ngày phỏng vấn:"
      rules={[{required:true, message:'Phải chọn ngày'}]}
      >
      <DatePicker
      />
      </FormItem>
      <FormItem name="Giophongvan" label="Giờ phỏng vấn"
      rules={[{required:true, message:'Phải chọn giờ'}]}
      >
        <TimePicker size="large" />
      </FormItem>
      <Form.Item name="Manhansu"
      label='Chọn người phỏng vấn:'
      rules={[{required:true, message:'Phải chọn người phỏng vấn'}]}
      >
        <Select 
        onChange={handleChange}
        options={options}
        />
      </Form.Item>
      <Form.Item name="InternId"
      label='Chọn ứng viên:'
      rules={[{required:true, message:'Phải chọn ứng viên'}]}
      >
        <Select
        onChange={handleChange1}
        options={optionsIntern}
        />
      </Form.Item>
      </>:null
      }
      {isEditabelKetqua?
        <>
        <Form.Item name="Ketqua"
      label='Kết quả: '
      >
        <Select
        options={[{value:'true', label:'Đạt'}, {value:'false', label:'Không đạt'}]}
        />
      </Form.Item>
      <Form.Item name="Mucluong"
      label='Mức lương: '
      >
      <Input type="number" />
      </Form.Item>
        </>:null
      }
      <Form.Item label="   ">
          <Button type="primary" htmlType="submit"
          disabled = {localStorage.getItem('emailData') != 'test@alta.com.vn' ? 
          false : true }
          >Lưu dữ liệu</Button>
      </Form.Item>
    </Form>
    </Modal>
      <div style={{float:'right', width:'100%'}}>
      <Button type="primary" onClick={showModal} style={{marginLeft:'0px', marginBottom:'5px', width:'19%'}}>
        Chọn tuần
    </Button>
    {['ALT0001', 'ALT0002', 'ALT0003'].includes(employeeCode!=null?employeeCode:'')?
          <Button type="primary" onClick={showModal1} 
          style={{marginLeft:'5px', marginBottom:'5px', width:'19%'}}
          disabled = {localStorage.getItem('emailData') != 'test@alta.com.vn' ? 
            false : true } 
          >
            Xếp lịch phỏng vấn
          </Button>:null
    }
      <table style={{border:'1px solid gray'}}>
        <thead style={{fontWeight:'bold'}}>
          <tr>
            {columns.map((item:any)=>(
              <td style={{border:'1px solid gray', 
              textAlign:'center', backgroundColor:'gray'}}>{item.title}</td>
            ))}
          </tr>
        </thead>
        <tbody>
          {dataSource.map((item:any, index:number)=>{
            let dropableIdArr = {id:index, weekdays: [
              "monday-"+index.toString(),
              "tuesday-"+index.toString(),
              "wednesday-"+index.toString(),
              "thursday-"+index.toString(),
              "friday-"+index.toString(),
              "saturday-"+index.toString()
            ]}
            return(
            <tr>
              <td style={{border:'1px solid gray', width:'50px'}}>{item.dayPart}</td>
              {weekdays.map(item=>{
                let k = (new Date()).getDay() == 1 ? 'monday'
                : (new Date()).getDay() == 2 ? 'tuesday'
                : (new Date()).getDay() == 3 ? 'wednesday'
                : (new Date()).getDay() == 4 ? 'thursday'
                : (new Date()).getDay() == 5 ? 'friday'
                :(new Date()).getDay() == 6 ? 'saturday' : 'sunday';
                let taskDate1 = columns[1].title.substring(columns[1].title.indexOf('(')+1,columns[1].title.lastIndexOf(')'))
                let d1 = new Date(taskDate1);
                d1.setDate(d1.getDate()-1);
                let taskDate2 = columns[6].title.substring(columns[6].title.indexOf('(')+1,columns[6].title.lastIndexOf(')'))
                let d2 = new Date(taskDate2);
                d2.setDate(d2.getDate()+1);
                let myStyle = (item == k && (new Date()) >= d1  && (new Date()) <= d2)
                ? {width:'16%', backgroundColor:'cyan', border:'1px solid gray'}
                : {width:'16%', border:'1px solid gray'};
                let myIndex =  listWeekDayTask[index].findIndex(c=>c.weekday==item)
                return(
                  <td style={myStyle}>
              <div style={{minHeight:'200px', padding:'2px 2px 2px 2px'}}>
              {listWeekDayTask[index][myIndex].data.map((item:any, index1:number)=>{
                if(!item.status)
                  return(
                  <p key={item.id} style={{textDecoration:item.style}}>
                  {item.content}
                  &nbsp; {['ALT0001', 'ALT0002', 'ALT0003'].includes(employeeCode!=null?employeeCode:'')?
                    <Button danger disabled = {localStorage.getItem('emailData') != 'test@alta.com.vn' ? 
                    false : true } 
                    onClick={()=>{
                      setManhansu(item.id.substring(0,item.id.indexOf('-')));
                      setInternId(Number(item.id.substring(item.id.indexOf('-')+1, item.id.indexOf('_'))));
                      setNgayphongvan1(item.id.substring(item.id.indexOf('_')+1, item.id.lastIndexOf('_')));
                      setGiophongvan1((new Date(dayjs(item.id.substring(item.id.lastIndexOf('_')+1),'HH:mm:ss').toString())).getHours()+':'
                       +(new Date(dayjs(item.id.substring(item.id.lastIndexOf('_')+1),'HH:mm:ss').toString())).getMinutes()+':'+(new Date(dayjs(item.id.substring(item.id.lastIndexOf('_')+1),'HH:mm:ss').toString())).getSeconds());
                       showModal2(); 
                      }}>Xóa</Button> : null
                  }
                  </p>)
                else
                return(
                  <p>
                  <a key={item.id} 
                  onClick={()=>{
                      form.setFieldsValue({
                        Manhansu: item.id.substring(0,item.id.indexOf('-')),
                        InternId: Number(item.id.substring(item.id.indexOf('-')+1, item.id.indexOf('_'))),
                        Ngayphongvan:dayjs(item.id.substring(item.id.indexOf('_')+1, item.id.lastIndexOf('_'))),
                        Giophongvan:dayjs(item.id.substring(item.id.lastIndexOf('_')+1),'HH:mm:ss'),
                        Ketqua:item.result?'true':'false',
                        Mucluong: item.salary
                      })
                      setManhansu(item.id.substring(0,item.id.indexOf('-')));
                      setInternId(Number(item.id.substring(item.id.indexOf('-')+1, item.id.indexOf('_'))));
                      setNgayphongvan1(item.id.substring(item.id.indexOf('_')+1, item.id.lastIndexOf('_')));
                      setGiophongvan1((new Date(dayjs(item.id.substring(item.id.lastIndexOf('_')+1),'HH:mm:ss').toString())).getHours()+':'
                       +(new Date(dayjs(item.id.substring(item.id.lastIndexOf('_')+1),'HH:mm:ss').toString())).getMinutes()+':'+(new Date(dayjs(item.id.substring(item.id.lastIndexOf('_')+1),'HH:mm:ss').toString())).getSeconds());
                      
                       setIsModalOpen1(true);
                      if(item.id.substring(0,item.id.indexOf('-'))==localStorage.getItem("userNameData")
                      || ['ALT0001', 'ALT0002', 'ALT0003'].includes(employeeCode!=null?employeeCode:'')
                      )
                          setIsEditableKetqua(true);
                  }} style={{textDecoration:item.style}}>
                  {item.content}
                  </a> &nbsp; {['ALT0001', 'ALT0002', 'ALT0003'].includes(employeeCode!=null?employeeCode:'')?
                    <Button danger
                    disabled = {localStorage.getItem('emailData') != 'test@alta.com.vn' ? 
            false : true }  
                    onClick={()=>{
                      setManhansu(item.id.substring(0,item.id.indexOf('-')));
                      setInternId(Number(item.id.substring(item.id.indexOf('-')+1, item.id.indexOf('_'))));
                      setNgayphongvan1(item.id.substring(item.id.indexOf('_')+1, item.id.lastIndexOf('_')));
                      setGiophongvan1((new Date(dayjs(item.id.substring(item.id.lastIndexOf('_')+1),'HH:mm:ss').toString())).getHours()+':'
                       +(new Date(dayjs(item.id.substring(item.id.lastIndexOf('_')+1),'HH:mm:ss').toString())).getMinutes()+':'+(new Date(dayjs(item.id.substring(item.id.lastIndexOf('_')+1),'HH:mm:ss').toString())).getSeconds());
                       showModal2();
                    }
                    }>Xóa</Button> : null
                  }
                  </p>
                  )
                })
              }                            
              </div>
              </td>)  
              })}        
            </tr>)
          })}
        </tbody>
      </table>
      </div>
    </div>
    </>
  );
}