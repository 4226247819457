import React, { useEffect, useState } from 'react';
import { Checkbox, Form, message, Input,Button, Radio, RadioChangeEvent, Space, Modal } from 'antd';
import type { CheckboxChangeEvent } from 'antd/es/checkbox';
import axios from "axios";
import { useSelector } from 'react-redux';
import { RootState } from '../../redux/store';
import { useAppDispatch } from '../../configs/hook';
import {getMailPattern} from '../internsRegistered/internSlice';
const { TextArea } = Input;
const EnableRegister: React.FC = () =>{
    const [form] = Form.useForm();
    const [myCheck, setMyCheck] = useState(localStorage.getItem("enableRegister")=='false'?false:true);
    const dispatch = useAppDispatch();
    const myMailPattern = useSelector((state:RootState)=>state.mailPattern);
    const [content, setContent] = useState(myMailPattern.length>0?myMailPattern[0].content:'');
    const [title, setTitle] = useState(myMailPattern.length>0?myMailPattern[0].title:'');
    const [subject, setSubject] = useState(myMailPattern.length>0?myMailPattern[0].subject:'');
    const [greeting, setGreeting] = useState(myMailPattern.length>0?myMailPattern[0].greeting:'');
    const [footer, setFooter] = useState(myMailPattern.length>0?myMailPattern[0].footer:'');
    const [value, setValue] = useState(1);
    const [templateName, setTemplateName] = useState('');
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [configApp, setConfigApp] = useState<any>();
  const onChange1 = (e: RadioChangeEvent) => {
    console.log('radio checked', e.target.value);
    setValue(e.target.value);
    setContent(myMailPattern[1].content);
    setTitle(myMailPattern[1].title);
    setSubject(myMailPattern[1].subject);
    setGreeting(myMailPattern[1].greeting);
    setFooter(myMailPattern[1].footer);
    form.setFieldsValue({
      Title: myMailPattern.filter(x=>x.id==e.target.value)[0].title,
      Subject: myMailPattern.filter(x=>x.id==e.target.value)[0].subject,
      Content: myMailPattern.filter(x=>x.id==e.target.value)[0].content,
      Footer: myMailPattern.filter(x=>x.id==e.target.value)[0].footer,
      Anhminhhoa: myMailPattern.filter(x=>x.id==e.target.value)[0].anhminhhoa
    })

  };
    const onChange = async (e: CheckboxChangeEvent) => {
      const token = localStorage.getItem('token');
        const rawResponse = await fetch(process.env.REACT_APP_API_URL+'api/admin/enableregister', {
          method: 'POST',
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`
          },
          body: JSON.stringify(e.target.checked ? "true" : "false")
        }).then(response => {
          if(response.status==401){
            message.info("Phiên đăng nhập đã hết hạn!");
            localStorage.clear();
            window.location.reload();
        }
        return response.json()
        })
        .then(data=>{
            console.log(data);
            setMyCheck(e.target.checked);
        });
      };
    useEffect(()=>{
      const token = localStorage.getItem('token');
      dispatch(getMailPattern());
      fetch(process.env.REACT_APP_API_URL+'api/Admin/testconfig', {
        method: 'GET',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`,
        },
  //body: JSON.stringify(answerRequest)
}).then(response => response.json())
.then((data)=>{
    console.log(data);
    setConfigApp(data);
})},[])
    return(
      <>
    <div style={{height:'800px', float: 'left', width:'20%'}}>
      <Button type='primary' style={{marginBottom:'10px'}}
      onClick={async()=>{
        setIsModalOpen(true);
      }}
      >Thêm biển mẫu</Button>
    <Radio.Group onChange={onChange1} value={value}>
      <Space direction="vertical">
        {myMailPattern.map((item:any)=>{
          return(
            <Radio value={item.id}>{item.anhminhhoa}</Radio>
          )
        })}
      </Space>
    </Radio.Group>
    </div>
        <Checkbox onChange={onChange} checked={myCheck}
        disabled = {localStorage.getItem('emailData') != 'test@alta.com.vn' ? 
        false : true } 
        >Mở đăng ký</Checkbox>&nbsp;&nbsp;
        {configApp!=null?
        <div>
        <Checkbox checked={configApp.enabledTest=='true'?true:false}
        disabled = {localStorage.getItem('emailData') != 'test@alta.com.vn' ? 
        false : true } 
        onChange={(e: CheckboxChangeEvent)=>{
          setConfigApp({...configApp, enabledTest:e.target.checked?'true':'false'})
        }}
        >Mở đề thi đầu vào</Checkbox>&nbsp;&nbsp;
        Số lượng câu hỏi cho đề thi: &nbsp; 
        <Input type="number" style={{width:'60px'}} value={configApp.numberOfQuestion} 
        onChange={(e)=>{
          setConfigApp({...configApp, numberOfQuestion:e.currentTarget.value})
        }}
        />&nbsp;&nbsp;
        Thời gian cho 1 câu hỏi: &nbsp; 
        <Input type="number" style={{width:'60px'}} value={configApp.numberOfSecondForQuestion} 
        onChange={(e)=>{
          setConfigApp({...configApp, numberOfSecondForQuestion:e.currentTarget.value})
        }}
        />
        &nbsp;(giây)&nbsp;&nbsp;
        <Button
        onClick={async()=>{
          const token = localStorage.getItem('token');
          const rawResponse = await fetch(process.env.REACT_APP_API_URL+'api/admin/testconfig', {
            method: 'POST',
            headers: {
              'Accept': 'application/json',
              'Content-Type': 'application/json',
              'Authorization': `Bearer ${token}`
            },
            body: JSON.stringify(configApp)
          }).then(response => response.json())
          .then(data=>{
              message.success("Cập nhật cấu hình thành công!!!");
              setConfigApp(data);
          });
        }}
        >Lưu thay đổi</Button>
        </div>:null}
        <hr />
        <Form
        disabled = {localStorage.getItem('emailData') != 'test@alta.com.vn' ? 
        false : true } 
        labelCol={{ span: 4 }}
        wrapperCol={{ span: 14 }}
        layout="horizontal" form={form}
        onFinish={(values:any)=>{
          const token = localStorage.getItem('token');
                const formData = new FormData();
                    formData.append("Title", title);
                    formData.append("Subject", subject);
                    formData.append("Greeting", greeting);
                    formData.append("Content", content);
                    formData.append("Footer", footer);
                    formData.append("Anhminhhoa", values.Anhminhhoa);
            const config = {     
              headers: { "Content-Type": "multipart/form-data", 'Authorization': `Bearer ${token}` }
            }
             axios.put(process.env.REACT_APP_API_URL+'api/Tintuc/'+ value, formData, config)
              .then(response => {
                message.info("Dữ liệu đã được cập nhật thành công");
                dispatch(getMailPattern());
              })
              .catch(error => {
                  console.log(error);
              });
            }}
      >
        <Form.Item label="Tên biểu mẫu" name="Anhminhhoa">
        <Input type="text" />
        </Form.Item>
        <Form.Item label="Phân tiêu đề" name="Title">
        <Input type="text" defaultValue={title} onChange={(e:any)=>{
            setTitle(e.currentTarget.value)}} />
        </Form.Item>
        <Form.Item label="Phân chủ đề" name="Subject">
        <Input type="text" defaultValue={subject} onChange={(e:any)=>{
            setSubject(e.currentTarget.value)}} />
        </Form.Item>
        <Form.Item label="Phân chào hỏi" name="Greeting">
        <Input type="text" defaultValue={greeting} onChange={(e:any)=>{
            setGreeting(e.currentTarget.value)}} />
        </Form.Item>
        
        <Form.Item label="Phân nội dung" name="Content">
          <TextArea rows={4} defaultValue={content} onChange={(e:any)=>{
            setContent(e.currentTarget.value);
          }} />
        </Form.Item>
        <Form.Item label="Phân cuối" name="Footer">
        <Input type="text" defaultValue={footer} onChange={(e:any)=>{
            setFooter(e.currentTarget.value)}} />
        </Form.Item>
        <Form.Item label="">
          <Button type="primary" htmlType="submit">Lưu thông tin</Button>
        </Form.Item>
      </Form>
      <Modal title="Thêm biểu mẫu" open={isModalOpen} onOk={()=>{
          setIsModalOpen(false);
      }} onCancel={()=>{setIsModalOpen(false);}}>
        <Input type="text" value={templateName} onChange={(e)=>setTemplateName(e.currentTarget.value)} 
          onKeyPress={async(e)=>{
              if(e.key=='Enter'){
                const token = localStorage.getItem('token');
                const rawResponse = await fetch(process.env.REACT_APP_API_URL+'api/Tintuc/add', {
                    method: 'POST',
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${token}`,
                    },
                    body: JSON.stringify(templateName)
            }).then(response => response.json())
            .then((data)=>{
                    console.log(data);
                    if(data=="added"){
                      dispatch(getMailPattern());
                    }
                    else
                      message.info("Đã có lỗi xảy ra");
            })
            .catch(err => console.log(err));
              }
          }}
        />
      </Modal>
      </>
    )
} 
export default EnableRegister;