import React, { useEffect, useState } from 'react';
import {Table,Select, SelectProps, Button, message} from 'antd';
import type { ColumnsType } from 'antd/es/table';
import { InternType } from '../../interface';
import {getDateString} from '../../helpers';
type CandidatType = {
  id: number,
  taxCode: string,
  parentId: number
}
export const FindCandidate = (props: CandidatType) => {
    const token = localStorage.getItem('token');
    const [subjectId, setSubjectId] = useState(-1);
    const [status, setStatus] = useState<string>("Tất cả");
    const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);
    const [selectedRows, setSelectedRows] = useState<InternType[]>([]);
    const rowSelection = {
      selectedRowKeys,
      onChange: (selectedRowKeys: React.Key[], selectedRows:InternType[]) => {
        console.log(`selectedRowKeys: ${selectedRowKeys}`, 'selectedRows: ', selectedRows);
        setSelectedRowKeys(selectedRowKeys);
        setSelectedRows(selectedRows);
      },
    };
    const getData = () => {
      let mystatus = 'Giới thiệu doanh nghiệp'; let myvalue =1;
      fetch(process.env.REACT_APP_API_URL+'api/Register/internbystatus/' + mystatus + "/" + myvalue + "/" + props.parentId, {
        method: 'GET',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`,
        },
}).then(response => response.json())
.then(async(data)=>{
  console.log(data);
  let tempData: InternType[] = [];
  await data.map((item:any)=>{
    tempData.push({
      key: item.id,
      id: item.id,
      internName: item.internName,
      internMail:item.internMail,
      dateOfBirth:item.dateOfBirth,
      university:item.university,
      fileUrl:item.fileUrl,
      registeredDate: item.registeredDate,
      internSpecialized:item.internSpecialized,
      telephoneNum: item.telephoneNum,
      internable: item.internable==1?'Thực tập':'Chính thức',
      fullTime: item.fullTime==1?'Toàn thời gian':'Bán thời gian',
      major: item.major,
      citizenIdentification: item.citizenIdentification,
      citizenIdentificationDate: item.citizenIdentificationDate,
      isEditableInternSpecialized:false,
      internStatus: item.internStatus,
      yearOfExperiences: 0,
      foreignLanguage: 'IELTS from 8.0',
      entranceTest:item.entranceTest
    })
  })      
  setData(tempData);            
})
.catch(err => console.log(err));
    }
    
    const [options, setOptions] = useState<SelectProps['options']>([]);
    
    useEffect(()=>{
      console.log(props.parentId);
      getData();
    },[props.id])
    const columns: ColumnsType<InternType> = [
        {
          title: 'Id',
          dataIndex: 'id',
          hidden: true,
          key: 'id',
        },
        {
          title: 'Tên thực tập sinh',
          dataIndex: 'internName',
          key: 'internName',
          render:(key:number, record:InternType, text:any)=>{
            return(
              <>
                {record.internName}
                <p style={{color:'blue'}}>{record.internStatus}</p>
              </>
            )
          }
        },
        {
          title: 'Trường đại học',
          dataIndex: 'university',
          key: 'university'
        },
        {
          title:'Chuyên ngành',
          dataIndex:'major',
          key: 'major'
        },
        {
          title:'Vị trí',
          dataIndex:'internSpecialized',
          key:'internSpecialized'
        },
        {
          title:'Chính thức/Thực tập',
          dataIndex: 'internable',
          key: 'internable'
        },
        {
          title: 'Ngày sinh',
          dataIndex: 'dateOfBirth',
          key: 'dateOfBirth'
        },       
        {
            title: 'Download CV',
            dataIndex: 'download',
            key: 'download',
            render: (key:number, record:InternType, text:any) => {
              return(
              <a href='#'
              onClick = {()=>{
                console.log(record.fileUrl);
                const token = localStorage.getItem('token');
                fetch(process.env.REACT_APP_API_URL+'api/Register/files/' + record.fileUrl, {
                    method: 'GET',
                    headers: {
                        'Authorization': `Bearer ${token}`,
                    },
            })
            .then(response => response.blob())
        .then(blob => {
            var url = window.URL.createObjectURL(blob);
            var a = document.createElement('a');
            a.href = url;
            a.download = record.fileUrl;
            document.body.appendChild(a); // we need to append the element to the dom -> otherwise it will not work in firefox
            a.click();    
            a.remove();  //afterwards we remove the element again
            fetch(process.env.REACT_APP_API_URL+'api/Register/seencv/'+record.id, {
              method: 'PUT',
              headers: {
                  'Accept': 'application/json',
                  'Content-Type': 'application/json',
                  'Authorization': `Bearer ${token}`,
              },
              body: JSON.stringify({id:record.id})
      }).then(response => {
          getData();
      })
      .catch(err => console.log(err));         
        });
            }}
              >Tải CV</a>
              ) 
            }
          }
    ].filter(item=>!item.hidden);  
    const [data, setData] = useState<InternType[]>([]);
    const handleProvideCV = () => {
      const token = localStorage.getItem('token');
      console.log(props.taxCode +"___" + props.id);
      selectedRowKeys.map(row=>{
        fetch(process.env.REACT_APP_API_URL+'api/ProvidedCv/', {
          method: 'POST',
          headers: {
              'Accept': 'application/json',
              'Content-Type': 'application/json',
              'Authorization': `Bearer ${token}`,
          },
          body: JSON.stringify({cvid: row, taxCode: props.taxCode,
          providedDate: getDateString(new Date()), monhocId:props.id,
          providedStatus:'Đã chuyển'
          })
          })
  .then(response => {
      //message.info("Thêm mới thành công");
  })
  .catch(err => console.log(err))
      })
      message.info("Đã giới thiệu CV");     
    }     
    return(
    <>
    <Button onClick={handleProvideCV}>Giới thiệu hồ sơ</Button>
     <Table columns={columns} dataSource={data} scroll={{ x: 1800, y: 500 }}
     rowSelection={{
      type: 'checkbox', 
      ...rowSelection,
    }}
     style={{marginTop:10,width:'160%'}} />
    </>
    )
}