import React, { useEffect, useState, ChangeEvent } from 'react';
import { Space, Table, Tag, Button, Modal, Form, Input, Popconfirm, message } from 'antd';
import type { ColumnsType } from 'antd/es/table';
import { useAppDispatch } from '../../configs/hook';
import { Excel } from "antd-table-saveas-excel";
import axios from 'axios';

type FbUserType = {
    id: string;
    name: string;
    picture: string;
}
const FbUser: React.FC = () => {
    const dispatch = useAppDispatch();
    const token = localStorage.getItem('token');
    const columns: ColumnsType<FbUserType> = [
        {
            title: 'Avatar',
            dataIndex: 'picture',
            key: 'picture',
            render: (key, record:FbUserType, text)=>{
                return(
                <>
                  <img src={record.picture} style={{width:'50px', height:'50px', borderRadius:'100%'}} />
                </>
                )
              }
        },
        {
          title: 'Facebook User Id',
          dataIndex: 'id',
          key: 'id',
        },
        {
          title: 'Tên người dùng facebook',
          dataIndex: 'name',
          key: 'name'
        }
      ];
    const [data, setData] = useState<FbUserType[]>([]);
    const getAllUniveristy = () => {
        fetch(process.env.REACT_APP_API_URL+'api/FbUser/', {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`,
            },
    }).then(response => {
      if(response.status==401){
        message.info("Phiên đăng nhập đã hết hạn!");
        localStorage.clear();
        window.location.reload();
    }
    return response.json()
    })
    .then((data)=>{      
        setData(data);            
    })
    .catch(err => console.log(err));
    }
    useEffect(()=>{
        getAllUniveristy();
    },[]) 
    return(
        <Table columns={columns} dataSource={data} />
    )
}
export default FbUser;