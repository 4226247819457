import { BehaviorSubject } from 'rxjs';
import axios from 'axios';
//import { history } from '../_helpers';
import { store } from '../redux/store';
import {getLoginInfo} from '../components/login/loginSlice';
const appId = `${process.env.REACT_APP_FACEBOOK_APP_ID}`;
export const accountService = {
    login,
    apiAuthenticate,
    logout
};
async function login() {
    // login with facebook then authenticate with the API to get a JWT auth token
    const { authResponse } = await new Promise(window.FB.login);
    if (!authResponse) return;
    console.log(authResponse);
    //await makePost(authResponse.accessToken);
    await apiAuthenticate(authResponse.accessToken);
    window.location.reload();
    // get return url from location state or default to home page
    // const { from } = history.location.state || { from: { pathname: "/" } };
    // history.push(from);
}

async function getLongLiveAccessToken(shortLivedAccessToken) {
    try {
      // Exchange short-lived access token for a long-lived one
      const response = await axios.get('https://graph.facebook.com/v12.0/oauth/access_token', {
        params: {
          grant_type: 'fb_exchange_token',
          client_id: appId,
          client_secret: '0fda41f886d463851f993ed95534a79c',
          fb_exchange_token: shortLivedAccessToken
        }
      });
  
      const longLivedAccessToken = response.data.access_token;
  
      // Use long-lived access token to fetch user information
    //   const userInfoResponse = await axios.get('https://graph.facebook.com/v12.0/me', {
    //     params: {
    //       fields: 'id',
    //       access_token: longLivedAccessToken
    //     }
    //   });
  
    //   const globalUserId = userInfoResponse.data.id;
      return longLivedAccessToken;
    } catch (error) {
      console.error('Error:', error.response.data);
      return null;
    }
  }
async function apiAuthenticate(accessToken) {
  const { data } = await axios({
     url:"https://graph.facebook.com/v12.0/me?fields=id,name,picture,link,photos,email"
     +"&access_token=" + accessToken,
     method: "get"
  });
      console.log(data);
      localStorage.setItem("emailData", data.id);
      localStorage.setItem('userNameData', data.id);
      localStorage.setItem("userFullName", data.name);
      localStorage.setItem("userAvatar", data.picture.data.url);
      localStorage.setItem("roleData","6");
      localStorage.setItem("passwordStatus", true);
      await fetch(process.env.REACT_APP_API_URL+'api/FbUser/', {
        method: 'POST',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({id: data.id, name: data.name, picture: data.picture.data.url})
}).then(response => response.json())
.then(data=>{
    store.dispatch(getLoginInfo({data: data.details}));
    localStorage.setItem('token', data.token);
})
.catch(err => console.log(err))
}
async function makePost(accessToken){
    try {
        const response = await axios.post(
          'https://graph.facebook.com/me/feed',
          {
            message: 'Test for posting to fb from React',
            access_token: accessToken
          }
        );
        console.log('Post successful:', response.data);
      } catch (error) {
        console.error('Error posting to Facebook:', error);
      }
}
function logout() {
    // revoke app permissions to logout completely because FB.logout() doesn't remove FB cookie
    window.FB.logout();
    //stopAuthenticateTimer();
    //accountSubject.next(null);
}
